<template>
  <div style="word-break: normal !important;">
    <div class="legal_parent_div">
      <h1>
        <strong>Shifl
          Capital Account Agreement</strong>
      </h1>
      <p>Effective September 1, 2021</p>
      <p>
        The following terms and conditions constitute an agreement (Agreement) between you and Evolve Bank &amp; Trust
        governing the use of your Shifl Capital account. This Agreement also refers to and includes other disclosures we
        may provide to you, which are incorporated by reference. As used in this Agreement, Customer, you and your mean
        the owner of the Shifl Capital Account, any person responsible for paying back all amounts you owe us under this
        Agreement, and any other person you may authorize to use and access your Shifl Capital Account (Authorized
        User). The terms we, us, our, Bank and Evolve mean Evolve Bank &amp; Trust. By opening or continuing to use a
        Shifl Capital Account, you agree to this Agreement. Please keep a copy of this Agreement for your records.
      </p>
      <p>
        PLEASE READ THIS AGREEMENT CAREFULLY, AS IT CONTAINS AN ARBITRATION AGREEMENT IN SECTION V.C.23 AND OTHER
        IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. UNLESS YOU ACT PROMPTLY TO REJECT
        THE ARBITRATION AGREEMENT, IT REQUIRES THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL
        ARBITRATION, AND FURTHER (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST US ON AN INDIVIDUAL BASIS, NOT
        AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, AND (2) YOU WILL ONLY BE
        PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS.
        IMPORTANTLY, THE ARBITRATION AGREEMENT INCLUDES OPT OUT PROVISIONS.
      </p>
      <p><br></p>
      <h3><strong>I.IMPORTANT INFORMATION ABOUT YOUR SHIFL CAPITAL ACCOUNT</strong></h3>
      <ul>
        <li>
          Your Shifl Capital Account consists of a Shifl Capital deposit account (Deposit Account) and a Shifl Capital
          Visa credit card (Shifl Capital Card) provided by Evolve. Evolve also issues cards or other devices to
          access your Account.
        </li>
        <li>
          Within your Shifl Capital Account, you may set up multiple Authorized Users to manage their use of your
          Shifl Capital Account. Each Authorized User can also be issued a Shifl Capital Card, which is associated
          with Shifl Capital Account.
        </li>
        <li>
          You and your Authorized User(s) may never spend more than the amount you have in your Deposit Account with
          your Shifl Capital Card or with transactions on your Deposit Account.
        </li>
        <li>
          You are responsible for all activity conducted on your Shifl Capital Account by an Authorized User, and all
          transactions Authorized Users make will be treated as if you had made the transaction yourself.
        </li>
        <li>
          For detailed terms and information on your Shifl Capital Card please refer to the Shifl Capital Card
          Agreement
        </li>
      </ul>
      <h4><strong>SECURITY AGREEMENT</strong></h4>
      <ol>
        <li>
          Our Security Interest in Your Deposit Account. You hereby grant to us, as of the date you enter into this
          Agreement, a security interest in all right, title and interest in any Deposit Account you have with us, and
          all funds deposited therein, now owned or hereinafter acquired (collectively, the Collateral), to secure
          your performance under this Agreement, including your duty to pay us for all obligations you owe us under
          this Agreement, present or hereinafter occurring, when payments are due, for every transaction made with
          your Shifl Capital Account by you, an Authorized User, or any other person that you or an Authorized User
          permits to use your Shifl Capital Account (Security Interest).
        </li>
        <li>
          Our Right to Exercise the Security Interest. You irrevocably and unconditionally relinquish possession and
          control over the Collateral, and you pledge and assign as security to us all of your right, title, and
          interest in it. You must take any action we request to perfect or protect our first lien position Security
          Interest in the Collateral. You waive the benefit of any homestead or other exemptions in the Collateral.
          The Security Interest will be governed by Uniform Commercial Code - Article 9 (as adopted by the applicable
          state law) whether Article 9 applies by its terms or not. We do not have to give you any prior notice to
          apply the funds in your Deposit Account or other Collateral or its proceeds to satisfy your obligations. You
          expressly agree that our rights under this Security Agreement extend to any electronically deposited federal
          or state benefit payments (including Social Security benefits) to the extent permitted by law. If you do not
          want your benefits applied in this way, you may change your direct deposit instructions at any time with the
          person or organization paying the benefits. In addition, you grant us a right of setoff to your Deposit
          Account to secure all amounts you owe us under this Agreement. This right of setoff does not apply to your
          Deposit Account if prohibited by law. You agree to hold us harmless from any claim arising as a result of
          our exercise of our right of setoff or Security Interest.
        </li>
        <li>
          YOU MAY LOSE FUNDS IN YOUR DEPOSIT ACCOUNT. If you are in default of any obligation under this Agreement, or
          your Shifl Capital Account is closed for any reason, you authorize us to withdraw funds from your Deposit
          Account and apply such amounts to the balance you owe us without sending you notice or demand for payment.
          We may do this in addition to the other rights we have under law or this Agreement. The application of your
          funds to the balance you owe under this Agreement will not affect your obligation to pay us in full. You are
          responsible for the repayment of all amounts you owe us under this Agreement.
        </li>
        <li>
          Legal Proceedings. You represent that (a) there are no current lawsuits or bankruptcy proceedings that might
          affect our interest in your Deposit Account; and (b) you have not and will not attempt to transfer any
          interest in your Deposit Account to any other person or offer your Deposit Account as collateral or security
          for any other obligation. If any person seeks to attach your Deposit Account, for example by legal
          garnishment, you agree that we may deem all amounts you owe us, immediately payable and apply the funds in
          your Deposit Account as payment of those amounts. If we must hire an attorney to defend or enforce our
          rights under the Security Agreement, you will pay our reasonable attorneys' fees and court costs, unless
          prohibited by law.
        </li>
      </ol>
      <h4><strong>TRUTH IN SAVINGS DISCLOSURES</strong></h4>
      <p><em>IMPORTANT INFORMATION ABOUT YOUR DEPOSIT ACCOUNT</em></p>
      <ul>
        <li>
          RATE INFORMATION. The interest rate on the balance in your Deposit Account that is eligible to earn interest
          is 0.0% with an annual percentage yield (APY) of 0.0%. Your interest rate and APY may change.
        </li>
        <li>Determination of Rate. At our discretion, we may change the interest rate on your Deposit Account.</li>
        <li>Frequency of Rate Changes. We may change the interest rate on your Deposit Account at any time.</li>
        <li>Limitations on Rate Changes. There are no maximum or minimum interest rate limits.</li>
        <li>
          COMPOUNDING AND CREDITING. Interest will be compounded daily and will be credited to your Deposit Account
          monthly. If you close your Deposit Account before interest is credited, you will not receive the accrued
          interest.
        </li>
        <li>MINIMUM BALANCE REQUIREMENTS. No minimum balance requirements apply to your Deposit Account.</li>
        <li>
          BALANCE COMPUTATION METHOD. We use the average daily balance method (less to calculate interest on your
          Deposit Account. This method applies a periodic rate to the average daily balance in your Deposit Account
          for the Cycle. The average daily balance may be calculated based on your ledger balance or your Available
          Balance. To get the average daily balance, we add the balance in the Deposit Account for each day of the
          Cycle, and divide that figure by the number of days in the Cycle. You will not earn interest on any balance
          in your Deposit Account that is not classified as Available Balance.<br>ACCRUAL ON NON CASH DEPOSITS.
          Interest begins to accrue no later than the Business Day we receive credit for the deposit of non-cash
          items.
        </li>
        <li>
          TRANSACTION LIMITATIONS. You may not make transactions that would cause the Spent Money to exceed the total
          balance in your Deposit Account. If you have more than one Sub-Account, you may not make transactions with
          any Sub-Account that would cause the Spent Money in the Deposit Account to exceed the total Available
          Balance in the Deposit Account, if you exceed these limits, we may close, suspend, or freeze your Deposit
          Account.
        </li>
        <li>
          FEES AND CHARGES. Please refer to the separate <em>Fee Schedule</em> provided to you with this Agreement for
          information about fees and charges associated with your Deposit Account. A Fee Schedule will be provided to
          you at the time you open a Shifl Capital Account, periodically when fees or charges change, and upon
          request.
        </li>
      </ul>
      <h3><strong>II. GENERAL TERMS</strong></h3>
      <h3><em>This section applies to all features of your Shifl Capital Account</em></h3>
      <p><strong>A. About your Shifl Capital Account</strong></p>
      <ol>
        <li>Evolve. The Shifl Capital Account is provided by Evolve Bank &amp; Trust.</li>
        <li>Business Days. Our Business Days are every day except Saturdays, Sundays, and holidays.</li>
        <li>
          Writing to Us. You may e-mail us at <strong>hello@shifl.com</strong>. You may also write to us at 343 spook
          Rock Rd suffern ny 10901. We will deem any and all notices to us effective upon receipt by us.
        </li>
        <li>
          Your Account. Your Shifl Capital Account includes a Deposit Account and a Shifl Capital Card. <em>THE SHIFL
            CAPITAL CARD IS A CHARGE CARD THAT ACCESSES A LINE OF CREDIT.&nbsp; IT IS NOT A DEBIT CARD.&nbsp; INTEREST
            AND OTHER CHARGES MAY APPLY.&nbsp; FOR MORE INFORMATION SEE THE SHIFL CAPITAL &nbsp;CARD
            AGREEMENT.&nbsp; </em>Within your Shifl Capital Account, you may set up one or Authorized Users. Spending
          Limit. You may never spend more than the amount you have in your Deposit Account with your Shifl Capital
          Card or with transactions on your Deposit Account. &nbsp;If you make a transaction that causes you to exceed
          the balance on your Shifl Ca Account, you agree to immediately make a deposit to your Shifl Capital Account
          to return your Shifl Capital Account to good standing.<br>Our general practice is to decline any transaction
          that would cause you to exceed any Available Balance in your Shifl Capital Account. Even if we have
          permitted you to exceed your Available Balance before, we are not required to do it in the future. We may
          determine the amount of Available Balance, for the purpose of deciding whether a transaction will cause you
          to exceed the Available Balance only once, and at any time between the time you authorize the transaction
          and when we decline the transaction or send a notice of the declined transaction.
        </li>
        <li>
          The App. You can access your Shifl Capital Account through the Shifl Capital mobile application (App). You
          may log in to the App at any time to view and update information about your Account and initiate online
          transactions. Your use of the App is subject to the Shifl Capital Terms of Service.
        </li>
        <li>
          Periodic Statements. We will make available to you one periodic statement (Statement) for your Shifl Capital
          Account per month, covering activity on your Shifl Capital Card and Deposit Account during each monthly
          cycle (Cycle). &nbsp;We may make your Statements available through the App, or by e-mail. We have made the
          Statement available to you on the day we notify you that the Statement is available. If your Deposit Account
          balance has charged off, we may provide you with quarterly Statements.
        </li>
      </ol>
      <h4><strong>B. Opening Your Shifl Capital Account</strong></h4>
      <ol>
        <li>
          Identifying You. To help the United States Government fight terrorism and money laundering, federal law
          requires us to obtain, verify, and record information that identifies each person that opens an Account.
          What this means for you: when you open a Shifl Capital Account, we will ask you for your name, street
          address, email, phone number, last four digits of your Social Security Number (SSN), and date of birth, and
          other information that will allow us to identify you. To open a Shifl Capital Account with us, you must be
          at least 18 years old, a U.S. citizen or a resident alien with a valid SSN and have a residential address in
          the U.S. or U.S. territory where we offer the Shifl Capital Account. We may also ask to see your driver
          license or other identifying documents that will allow us to identify you. You must tell us when this
          information changes. We may ask you for additional documents to verify any changes. We may restrict or close
          your Shifl Capital Account if we cannot verify your information, or if you do not provide it as requested.
        </li>
        <li>
          Consumer Reporting Agencies. You authorize us to obtain information about you from time to time from
          consumer reporting agencies, check verification services, and other third parties for the purpose of
          considering your application for a Shifl Capital Account or any other purpose permitted by law.
        </li>
        <li>
          TIN/Backup Withholding. We are required by federal law to obtain your correct taxpayer identification number
          (TIN) in order to avoid potential backup withholding and to report interest income paid to you in connection
          with your Shifl Capital Account. If you are subject to backup withholding or if the Internal Revenue Service
          (IRS) notifies us that we do not have a correct TIN for you, we may be required to withhold and remit to the
          IRS a percentage of interest paid (backup withholding) to your Deposit Account. Amounts withheld are
          reported to the IRS as federal tax withheld and will be reflected on your IRS Form 1099-INT, Interest
          Income. A TIN may be your SSN if your Fintech Account is in your name and you are either a U.S. citizen,
          permanent resident, or otherwise eligible to receive an SSN.
        </li>
        <li>
          Identifying your business (if applying for a business account). You will need to provide Company information
          and certain personal information (collectively, “Company Information”), when you apply for an Account.
          Company Information may include your registered business name and state of incorporation, the business
          address, ownership details, the nature of the business, and other business information we may request from
          time to time; the name, contact information, and date of birth of beneficial owners and certain other
          personnel, and other personal information; and a corporate registration certificate, proof of address,
          personal identification, and any other documentary information used to verify business and personal
          information. We provide Company Information to our Banking Services Provider and other third-party service
          providers to determine your eligibility for the Services. We rely on the accuracy of the Company Information
          you provide us when opening and maintaining your Account. We may deny your applications, suspend provision
          of the Services to you, or close your Account if Company Information is out of date, incomplete, or
          inaccurate. At any time during the term of this Agreement and as part of your use of the Services, we may
          require additional Company Information from you to verify your identity and the identity of your business,
          to obtain other information about your business, to open and maintain accounts and aspects of the Services,
          to assess your financial condition, and to evaluate the risks associated with your business. You expressly
          consent and authorize us to retrieve additional Company Information about Company and its owners or
          directors from our vendors and other third parties to solely to assess the history and risks of the business
          and to prevent fraud, including by obtaining information from credit reporting agencies and information
          bureaus, and you authorize and direct such third parties to compile and provide such information to us. You
          acknowledge that this may include names, addresses, credit history, and other data. You acknowledge that we
          may use Company Information to verify any other information you provide to us, and that any information we
          collect may affect our assessment of your overall risk to our business.
        </li>
        <li>
          Ownership of Shifl Capital Account. This is an individual account or business account based on your
          application type.
        </li>
        <li>
          Authorized Users. You may authorize one or more Authorized Users to use and access your Shifl Capital
          Account, or any Sub-Account, subject to our approval. The Authorized User must be at least 18 years of age.
          We reserve the right to limit the number of Authorized Users of your Shifl Capital Account. We may require
          you to submit certain information about each person you request to make an Authorized User on your Shifl
          Capital Account or a Sub-Account. Authorized Users will have access to certain information about your Shifl
          Capital Account and will be able to make transactions with your Shifl Capital Account. You will be
          responsible for an Authorized User use of the Shifl Capital Account, and use of the Shifl Capital Account by
          anyone else they allow to use the Shifl Capital Account, even if you did not want, or agree to, that use. We
          may also limit an Authorized User ability to use the Shifl Capital Account. The Authorized User is
          designated to conduct transactions on your behalf. You do not give up any rights to act on the Shifl Capital
          Account, and the Authorized User may not in any manner affect the rights of the owner or beneficiaries, if
          any, other than by using the Shifl Capital Card and making transactions on the Shifl Capital Account. We
          undertake no obligation to monitor transactions to determine that they are on the owner's behalf. The
          Authorized User authority is automatically terminated by the death of any owner of the Shifl Capital
          Account. However, we may continue to honor the transactions of the Authorized User until: (a) we have
          received written notice or have actual knowledge of the termination of authority, and (b) we have a
          reasonable opportunity to act on that notice or knowledge. We may refuse to accept an Authorized User for
          any reason, at our sole discretion.
        </li>
        <li>
          Removing an Authorized User. If you want to remove an Authorized User from your Shifl Capital Account, you
          must contact us and request their removal. Authorized Users may remove themselves from your Shifl Capital
          Account upon request. You also must immediately destroy all Shifl Capital Cards in their possession, change
          or destroy any other Access Credentials (as defined below in Section V.C.1) in their possession, and cancel
          any arrangements, such as automatic or scheduled transactions, they may have set up on your Shifl Capital
          Account. The Authorized User will be able to use your Shifl Capital Account until we receive the request to
          remove the Authorized User from your Shifl Capital Account and we have acted on your notice. During this
          time, you will still be responsible for all transactions the Authorized User makes using your Shifl Capital
          Account. You will be responsible even if these transactions do not appear on or post to your Shifl Capital
          Account until after you remove the Authorized User from the Shifl Capital Account. We reserve the right to
          remove any Authorized User from your Shifl Capital Account for any reason. When you or we remove an
          Authorized User for any reason, we may revoke the Authorized User’s Shifl Capital Card, close any
          Sub-Account the Authorized User used, issue a new Shifl Capital Card with a different number, close your
          existing Shifl Capital Account and issue a new Shifl Capital Account with a new account number, and/or
          require you to change other Access Credentials. Any funds that remain in the Wallet associated with the
          closed Sub-Account will remain in your Deposit Account.
        </li>
        <li>
          Linking External Accounts. You may choose to link your Shifl Capital Account to an account you have with
          another financial institution in the U.S. or a U.S. territory where we offer the Shifl Capital Account. We
          will call that other account a Linked External Account. You can use your Linked External Account to transfer
          money to or from your Deposit Account. Any Linked External Account must be owned by you and must be a
          personal account (not a business or commercial account). Your request to establish a Linked External Account
          is subject to our verification and approval. In our discretion, we may limit the number of Linked External
          Accounts you may have at one time, block any transaction you make with your Linked External Account, or
          disconnect any Linked External Account.
        </li>
      </ol>
      <p><br></p>
      <h3><strong>III. SHIFL CAPITAL CARD TERMS</strong></h3>
      <p>
        <em>For the terms of your Shifl Capital Card (if applicable), please see the Shifl Capital Card Agreement
          provided to you at the time of opening your Shifl Capital Account and requesting a Shifl Capital Card.</em>
      </p>
      <h3><strong>IV. DEPOSIT ACCOUNT TERMS</strong></h3>
      <p><em>This section governs your use of your Deposit Account.</em></p>
      <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
        <li><strong> Deposits</strong></li>
      </ol>
      <ol>
        <li>
          Limited Deposit Account Access. Your Deposit Account is only accessible through the App on your mobile or
          web device. You may only make withdrawals and transactions from your Deposit Account by initiating permitted
          transactions on the App. You will not be able to make withdrawals or deposits on your Deposit Account at
          branch locations or by telephone. <em>You may not use your Shifl Capital Card to debit your Deposit
            Account</em>.
        </li>
        <li>
          FDIC Insurance. Funds in your Deposit Account are insured by the Federal Deposit Insurance Corporation
          (FDIC) and backed by the full faith and credit of the United States, up to the applicable limit. The amount
          of insurance coverage you have may depend on the balance in your Deposit Account and the ownership, type of,
          and balances held in any other accounts you have with us. For additional information, please visit the FDIC
          website at <a href="http://www.fdic.gov/"
            data-mce-href="http://www.fdic.gov/"><strong>www.fdic.gov</strong></a>.
        </li>
        <li>
          Deposit Requirements. Deposits must be made in U.S. Dollars. Deposits made by ACH are subject to the NACHA
          Operating Rules and Operating Guidelines and the rules of any regional clearing houses we may use from time
          to time (collectively, the ACH Rules). Before final settlement of any deposit, we act only as your agent. We
          will give only provisional credit until collection is final for any items we accept for deposit (including
          items drawn on us). You authorize us to attempt to collect previously returned items without giving you
          notice, and in attempting to collect we may permit the payor bank to hold an item beyond an applicable
          deadline. Actual credit for deposits of, or payable in, foreign currency will be at the exchange rate in
          effect on final collection in U.S. dollars. We will treat and record all transactions received after the
          Cutoff Time (defined below) on a Business Day we are open, or received on a day we are not open for
          business, as if initiated on the next Business Day that we are open. At our option, we may take an item for
          collection rather than for deposit.
        </li>
        <li>
          Permitted Deposits. Deposits to your Deposit Account may be made using one of the following payment methods:
          <ul>
            <li>
              External Debit Card. You may use the App to authorize us to charge a debit card issued by an
              external U.S. bank and deposit the funds in your Deposit Account. You are responsible for any fees
              charged by the issuing bank for using your debit card.
            </li>
            <li>
              Linked External Account. You may authorize us to initiate ACH transfers from a Linked External
              Account to your Deposit Account. Use the App to set up, review, change or cancel your scheduled
              transfers.
            </li>
            <li>
              Person-to-Person Transfer with Shifl Capital Users. You can receive transfers to your Deposit
              Account initiated by another Shifl Capital user, using the person-to-person transfer services (P2P
              Transfer) provided in the App pursuant to Section IV.D below.
            </li>
            <li>
              Direct Deposit. You can direct a third party, such as an employer, to electronically deposit your
              paycheck, retirement benefits, or other income to your Deposit Account by ACH or wire transfer.
              Direct deposits may not be initiated in the App.
            </li>
            <li>
              Other. We may permit you to make deposits by other means we make available from time to time.<br><br
                data-mce-bogus="1">
            </li>
          </ul>
        </li>
        <li>
          Deposit Records and Receipts. We and our service providers may rely on the account number on any record of a
          deposit you make, even if that account number is associated with a name that is different from the name you
          have provided. It is not our responsibility to detect any inconsistency between the account number you
          provide and the name. You may use the App or call us to confirm that we have received a deposit. If you make
          a deposit, we may provide a confirmation of your deposit, but the amount on your deposit confirmation may
          not reflect the amount of funds we actually receive. We may confirm the funds you deposit and, after review,
          may adjust your Deposit Account for any errors including any errors on your deposit request or similar
          records. If we give you a confirmation for a deposit that you then cancel, the confirmation is void and you
          may not claim those funds.<br><br>
        </li>
        <li>
          Our Right to Refuse Deposits. We may refuse a deposit, or part of a deposit, at any time. We also may refuse
          a deposit after initially accepting it. We will not be liable to you for refusing a deposit, even if it
          causes us to decline any transactions you have already made.<br><br>
        </li>
        <li>
          When You Can Withdraw Funds You Have Deposited. Please see the Funds Availability Policy for information
          about when funds you deposit will be available for withdrawal. If funds from a deposit become available and
          you can withdraw them, that does not mean the item you have deposited is good, has cleared, or has been paid
          by the paying bank. It is possible that the item will be returned unpaid months after we have made the funds
          available to you and you have withdrawn them.<br><br>
        </li>
        <li>
          Our Right to Charge Back Deposited or Cashed Items. If you make a deposit and (a) the sending bank returns
          it to us unpaid; (b) the sending bank demands that we repay them because the deposit was unauthorized; or
          (c) the sending bank or the originator of a transfer demands that we return the item because it was
          unauthorized, sent to the wrong account number or procured by fraud, we may pay the return or demand, and
          subtract the funds from your balance. If we have reason to believe that any of the events in the previous
          sentence has occurred or may occur or that the item should not have been paid or may not be paid for any
          other reason, we may place a hold on the funds or move them to a bank-managed administrative account until
          we determine who is entitled to them.<br><br>
        </li>
        <li>
          All Deposits Are Credited Subject to Final Settlement in Cash or Credits. We have the right to forward all
          deposited items to correspondents including all Federal Reserve Banks, and we will not be liable for default
          or neglect of any correspondents for loss in transit, and no correspondent will be liable except for its own
          negligence. You specifically authorize us or our correspondents including the Federal Reserve Banks to
          handle such items in accordance with provisions of Regulation J (12 CFR Part 210), as revised or amended
          from time to time by the Federal Reserve Board. In the event we are subject to local clearinghouse rules,
          you specifically authorize us to handle such items in accordance with the rules and regulations of the
          clearinghouse.<br>If we permit you to withdraw funds from your Deposit Account before final settlement has
          been made for any deposited item, and final settlement is not made, we have the right to charge your Deposit
          Account or obtain a refund from you. In addition, we may charge back any deposited item at any time before
          final settlement for whatever reason. We will not be liable for any damages resulting from the exercise of
          these rights. Except as may be attributable to our lack of good faith or failure to exercise ordinary care,
          we will not be liable for dishonor resulting from any reversal of credit, return of deposited items or for
          any damages resulting from any of those actions.
        </li>
      </ol>
      <h4><strong>B. Withdrawals and Transfers</strong></h4>
      <ol>
        <li>
          Available Balance. You may withdraw or transfer all or any part of the Available Balance in your Deposit
          Account at any time, provided that the withdrawal will not cause you to exceed the Spending Limit(s) and any
          other limitations in this Agreement or limits you set on the Deposit Account by using the App. We determine
          your Available Balance by taking your previous day balance, plus any pending credit transactions (excluding
          pending returns), such as ACH direct deposits, and subtracting (a) pending charges such as, electronic
          payments, according to the Processing Order (defined in Section IV.B.9 below) (b) amount of deposits that
          are not yet available for withdrawal under our <em>Funds Availability</em> Policy, and (c) any holds on your
          balance, such as holds on funds to comply with court orders or other legal requirements, or as authorized by
          the Security Agreement. We reserve the right to use a different method in certain states if required by law.
          We may permit Authorized Users to withdraw only the portion of the Available Balance which is designated to
          the Sub-Account(s) they are authorized to use. However, we are not required to do so unless required by
          applicable law.
        </li>
        <li>
          Withdrawal Rules. By initiating a withdrawal of funds from your Deposit Account, you authorize us to make
          the transaction you select. Withdrawals may be made in U.S. dollars by using the App to authorize a transfer
          of funds to a Linked External Account, to make a P2P transfer to another Shifl Capital user, or to make a
          transfer to another type of account or payee we may permit from time to time. Withdrawals to a Linked
          External Account and P2P transfers are processed as ACH transactions. Please see the <em>Electronic Fund
            Transfer Agreement</em> for more information.<br>We may subtract from your Deposit Account balance the
          amount of any withdrawal, P2P Transfer, or other debit transaction that you, or any Authorized User, or
          person you or an Authorized User authorized, initiated or approved. We may require you or any person you
          authorize to provide us with identification, documentation or information that is acceptable to us before
          allowing the transaction. You may not make any withdrawal in an amount that exceeds the applicable Available
          Balance. We may refuse to permit Authorized Users to make any withdrawal in an amount that exceeds the
          Available Balance applicable to the Deposit Account they are authorized to use. We may refuse any withdrawal
          or transfer request by any method on forms not approved by us, or that we do not specifically permit for
          your Deposit Account, or that does not include all information we require, or that is greater in number than
          the frequency permitted, or which is greater or less than any withdrawal limitations. We will use the date
          the transaction is completed by us (as opposed to the date you initiate it) to apply the frequency
          limitations. In addition, we may place limitations on your Deposit Account until your identity is verified.
          We reserve the right to establish (and change) maximum dollar limits for withdrawal transactions from time
          to time. Even if we honor a nonconforming request, we are not required to do so later.
        </li>
        <li>
          Cutoff Time. Online requests to transfer funds to or from your Deposit Account must be received by us by
          2:00 PM PT on a Business Day to be initiated on that Business Day. Online transfer requests received by us
          after 2:00 PM PT or on a day that is not a Business Day generally will be initiated on the next Business
          Day.
        </li>
        <li>
          Right to Require Advance Notice of Withdrawals. We reserve the right to require seven days' notice in
          writing before each withdrawal.
        </li>
        <li>
          Account Numbers on Fund Transfers. If you instruct us to send a fund transfer, such as an ACH transfer, we,
          our service providers, and every other bank involved in the transfer may rely on any bank number or account
          number you provide. If the fund transfer instruction gives both a bank number or account number and a name,
          and the name identifies a different person from the bank or account owner identified by number, we and other
          banks that handle the fund transfer may still rely exclusively on the number. We have no duty to detect any
          inconsistency between the bank number or account number and the name.
        </li>
        <li>
          Withdrawal Authority. Any person who signs to open your Deposit Account or has authority to make withdrawals
          (including Authorized Users) may, acting alone, withdraw or transfer all or any part of your Deposit Account
          balance at any time. Each of you (until we receive written notice to the contrary) authorizes each other
          person who signs or has authority to make withdrawals to endorse any item payable to you or your order for
          deposit to your Deposit Account or any other transaction with us.
        </li>
        <li>
          Rules for ACH Transfers. This Agreement is subject to Article 4A of the <em>Uniform Commercial Code Fund
            Transfers</em>, as adopted in the state in which you have your Deposit Account with us. If you originate a
          fund transfer and you identify by name and number a beneficiary financial institution, an intermediary
          financial institution or a beneficiary, we and every receiving or beneficiary financial institution may rely
          on the identifying number to make payment. We may rely on the number even if it identifies a financial
          institution, person or account other than the one named. You agree to be bound by automated clearing house
          association rules, such as the NACHA Rules. These rules provide, among other things, that payments made to
          you, or originated by you, are provisional until final settlement is made through a Federal Reserve Bank or
          payment is otherwise made as provided in Article 4A-403(a) of the <em>Uniform Commercial Code</em>. If we do
          not receive such payment, we are entitled to a refund from you in the amount credited to your Deposit
          Account and the party originating such payment will not be considered to have paid the amount so credited.
          If we receive a payment order to credit your Deposit Account, we are not required to give you any notice of
          the payment order or credit.
        </li>
        <li>
          Multiple Signatures. We are not required to comply with any multiple-signature requirement, even if you have
          otherwise instructed us to do so.
        </li>
        <li>
          Processing Order. The following Processing Order dictates the order in which we will process your
          transactions on your Deposit Account each Business Day. All credit transactions are processed first. Debits
          or withdrawals (such as ACH transactions) will be processed in the order in which they are received by us.
          The processing order of these items is important because if there is not enough money in your Deposit
          Account to pay for the items in the order, they are processed in accordance with the processing order, we
          may decline the transaction, or it may cause an overdraft on your Deposit Account.
        </li>
      </ol>
      <h4><strong>C. Overdrafts</strong></h4>
      <ol>
        <li>
          Overdrafts. Our general practice is to decline to pay any transaction that would cause an overdraft on your
          Deposit Account (i.e., your Available Balance is less than the amount of that item plus all other items
          received but not yet paid) (Overdraft), or would cause a negative Available Balance in any Wallet. Even if
          we have paid these items before, we are not required to do it in the future. We can change our practice of
          paying these items on your Deposit Account without notice to you. We may determine the amount of Available
          Balance for the purpose of deciding whether to return an item for insufficient funds at any time between the
          time we receive the item and when we return the item or send a notice in lieu of return. We need only look
          at your Available Balance in your Shifl Capital Account once to decide if the item would cause an Overdraft
          or negative Available Balance, but if we choose to make a subsequent determination, the Available Balance at
          the subsequent time will determine whether there are insufficient available funds. It is your responsibility
          to avoid overdrawing your Deposit Account and maintain an Available Balance in your Shifl Capital Account.
        </li>
        <li>
          Overdraft Fees. You agree that we may charge fees for Overdrafts as set forth in the <em>Fee Schedule</em>.
        </li>
        <li>
          Your Responsibility to Pay Overdrafts. You are liable for any Deposit Account or Wallet shortage resulting
          from charges or Overdrafts, whether caused by you, an Authorized User, or any another person to whom you
          provide access to your Shifl Capital Account. If we pay a transaction that causes an Overdraft or negative
          Available Balance, you must immediately pay the amount of any Overdraft and restore the Available Balance to
          at least $0.00. You have no right to defer such payments, and you are liable regardless of whether you
          signed the item or benefitted from the charge or Overdraft. If you fail to pay an Overdraft, we also may
          report you to consumer reporting agencies, close your Shifl Capital Account, or both. This could affect your
          ability to open accounts with us or other banks in the future. We may also apply funds in your Deposit
          Account to cure a negative Available Balance. You authorize us to use the money from any subsequent deposits
          to your Deposit Account to pay any Overdraft or apply the deposit to cure a negative Available Balance.
          Subsequent deposits include any federal or state benefit payments that you choose to deposit in your Deposit
          Account (including direct deposit of Social Security benefits), unless prohibited by law. You understand and
          agree that if you do not want your benefits applied in this way, you may change your direct deposit
          instructions at any time. We may still pursue collection of the amount you owe (including suing you) after
          it is charged off.
        </li>
        <li>
          Waiver of Notices. To the extent permitted by law, you waive any notice of non-payment, dishonor or protest
          regarding any items credited to or charged against your Deposit Account. For example, if you authorize a
          transaction and it is returned unpaid or we receive a notice of nonpayment, we do not have to notify you
          unless required by federal Regulation CC or other law.
        </li>
      </ol>
      <h4><strong>D. Person to Person Transfers</strong></h4>
      <p>
        You may use your Shifl Capital Account to make person-to-person transfers (P2P Transfers) to other Fintech
        users.
      </p>
      <ol>
        <li>
          Payment Authorization. By authorizing a P2P Transfer, you authorize us to debit your Deposit Account for the
          amount you request to send, plus applicable fees if any, on or after the date of your authorization. We will
          send that amount (less fees, if any) to your designated recipient. By authorizing a P2P Transfer, you also
          authorize us to initiate any other transactions necessary to correct errors or reverse payments that are
          rejected or returned. You may not use an external bank account or any other payment method to fund a P2P
          Transfer. You are solely responsible for ensuring you have a sufficient Available Balance in your Deposit
          Account necessary to complete any payments initiated through the P2P Transfer service, and that the
          transaction will not cause you to exceed any Spending Limit. You agree to reimburse us for any fees, costs,
          or expenses we incur as a result of insufficient or unavailable funds in connection with any P2P Transfer
          you initiate.
        </li>
        <li>
          Restrictions on P2P Transfers to Shifl Capital Users Without Shifl Capital Account. If you send a P2P
          Transfer to a recipient who does not have a Shifl Capital Account, you authorize us to hold the funds you
          send on behalf of your recipient, and we will issue promotional credit (Credit) to the recipient in lieu of
          cash. You understand that the recipient cannot redeem the Credit for cash unless and until the recipient
          open a Shifl Capital Account. To send a P2P Transfer, you must provide the recipient valid mobile telephone
          number, which is capable of receiving SMS or text messages. If the recipient does not have an online account
          on the Shifl Capital website (Online Account), the recipient will receive an SMS message notifying them of
          your P2P Transfer, with instructions for opening an Online Account or Shifl Capital Account and claiming the
          Credit. Once the recipient has an Online Account, the recipient will receive Credit in the amount of the P2P
          Transfer you send. An Online Account is not a Shifl Capital Account and your recipient may not use an Online
          Account to redeem the Credit for cash. If the recipient opens a Shifl Capital Account, the Credit will be
          automatically redeemed for cash and deposited in their Shifl Capital Account. Credit has no cash value until
          and unless we redeem the Credit for cash, and credit it to a Deposit Account. Credit is issued solely for
          promotional purposes and is not FDIC-insured. Credit expires 30 days after the date it is issued, if it is
          not used or redeemed for cash. If the recipient fails to claim the Credit or their Online Account is
          inactive for an extended period of time, it may be deemed unclaimed or abandoned under applicable state law.
          See the Shifl Capital Terms of Service for more information.
        </li>
        <li>
          Transaction Limits. You may not make P2P Transfers that would cause you to exceed any transaction limits set
          forth in provision of this Agreement. You may not make any P2P Transfer that would case you to exceed any
          applicable Spending Limit. We may reject or reverse any transaction that would cause you or the recipient of
          the P2P Transfer to exceed limits applicable to their Shifl Capital Account, or if we determine in our sole
          discretion that the P2P Transfer was invalid for any reason. You may not make P2P Transfers to conduct
          business, commercial or merchant transactions. We reserve the right to change these transaction limitations
          at any time, in our sole discretion, with or without notice to you, unless required by law.
        </li>
        <li>
          Stop Payment. All P2P Transfer requests may be processed as soon as we receive them, and we may be unable to
          stop the P2P Transfer. Our ability to stop a P2P Transfer once you have initiated it will depend on when you
          notify us of your desire to stop the transaction, and whether we have begun to process the P2P Transfer or
          have a reasonable opportunity to act on your request to stop the payment.
        </li>
        <li>
          Refunds, Reversals and Chargebacks. P2P Transfers may be invalidated or reversed by us if, among other
          reasons, we send cash or Credit to you or your recipient in error, the funding transaction is declined or
          reversed, the P2P Transfer was unauthorized or unfunded, or the P2P Transfer was made for activities or
          reasons that violate this Agreement, the Shifl Capital Terms of Service or any other agreement you have with
          us. If the P2P Transfer you send or receive is invalidated for any reason, you may be liable to us for the
          full amount of the P2P Transfer, and we may recover the amount of the P2P Transfer (plus applicable fees, if
          any) from you. We may recover the amount of the P2P Transfer from the sender or recipient of the P2P
          Transfer in our sole discretion (subject to applicable law). When recovering the amount of an invalidated
          P2P Transfer from you, we may apply any funds sent to you in a subsequent P2P Transfer, from your Deposit
          Account or any incoming deposit to your Deposit Account, engage in collection efforts to recover such
          amounts from you, place a limitation or restriction on your Shifl Capital Account, or take any other action
          authorized by this Agreement, Shifl Capital Terms of Service or any other agreement you have with us.
        </li>
        <li>
          Other Restrictions. Your ability to make P2P Transfers is subject to all other terms and restrictions set
          forth in the Shifl Capital Terms of Service, which are incorporated herein by reference. In the event of a
          conflict between the Terms of Service and this Agreement, this Agreement shall control.
        </li>
      </ol>
      <h3><strong>V. ADDITIONAL TERMS</strong></h3>
      <p><em>This section applies to all features of your </em>Shifl Capital<em> Account.</em></p>
      <ol>
        <li><strong> Communications, Statements, Notices of Errors, and Other Notices</strong></li>
      </ol>
      <ol>
        <li>
          Delivery of Notices. You agree that we and our service providers may communicate with you by mail,
          telephone, email, fax, prerecorded message, automated voice, text message or other means allowed by law
          regarding your Shifl Capital Account. Any legal disclosures, notices and communications and other Shifl
          Capital Account-related correspondence that we mail to you will be sent via U.S. Mail to the last mailing
          address shown in our records for your Shifl Capital Account. Notices will be deemed to have been delivered
          on the day we mailed it to you, or made it available electronically. We are not responsible for items lost
          in, or not delivered by, mail or e-mail. Some notices may only be available electronically or in paper form.
          Notice to any owner of your Shifl Capital Account is notice to all owners and Authorized Users.
        </li>
        <li>
          Notices You Send Us. Any written notice you give us is effective after we actually receive and act on it,
          and it must be given to us according to the specific delivery instructions provided elsewhere, if any. We
          must receive it in time to have a reasonable opportunity to act on it. If the notice is regarding a check or
          other item, you must give us sufficient information to be able to identify the item, including the item
          number, amount, date and payee.
        </li>
        <li>
          Electronic Communication. Your Shifl Capital Account is an electronic account. We may send communications
          electronically, such as by email or text message, rather than through U.S. mail or other means, unless the
          law says otherwise. If you give us your email address, you agree that we may send servicing messages (such
          as fraud alerts and hold alerts) related to your Shifl Capital Account to that address. You may be required
          to agree to communicate with us by electronic means in order to open a Shifl Capital Account. If you revoke
          your consent to electronic communications, we may declare you to be in default of this Agreement (see
          Section III.F.1), close your Shifl Capital Account or take other actions described in Section III.F.2 of
          this Agreement and the Electronic Communications Agreement.
        </li>
        <li>
          Telephone Communication. We may record and/or monitor any of our telephone conversations with you. If we do
          record, we do not have to keep the recordings, unless the law says we must. When you give us your mobile
          number, we have your permission to contact you at that number about all of your Evolve accounts. Your
          consent allows us and our service providers to use text messaging, artificial or prerecorded voice messages
          and automatic dialing technology for informational and service calls, but not for telemarketing or sales
          calls. This communication may include contact from companies working on our behalf to service your Shifl
          Capital Account. Message and data rates may apply. You may change these preferences by emailing
          <strong>hello@shifl.com</strong>.
        </li>
        <li>
          Change in Contact Information. We require you to provide an e-mail address, mobile telephone number, and
          physical street address if your mailing address is a P.O. box. You are responsible for promptly notifying us
          of any change to any such contact information or your name. In some instances, we may request additional
          information for verification purposes. Any notice we mail to you (or email to you if you have told us to
          communicate with you electronically) will be binding when sent to the last (postal or electronic) address in
          our records. We may change your address if we receive an address change notice from the U.S. Postal Service
          (USPS) or if a company in the business of providing correct addresses informs us that the address in our
          records no longer matches your address. Even if you elected to receive electronic communications from us,
          there may still be some legal information that we need to send you via the USPS.
        </li>
        <li>
          Communications Equipment and Services. You are responsible for obtaining and maintaining all
          telecommunications, mobile, broadband, computer, hardware, software, equipment and services needed to access
          and use your Shifl Capital Account online; view, save and/or print your Shifl Capital Account documents
          online; receive any messages or documents you have consented to receive electronically; and for paying all
          related charges. We are not required to act on any instruction or message from you provided by SMS/text, or
          telephone voice mail, message service or answering machine. Whenever this Agreement or applicable law
          requires or allows you to give us written notice, we will not consider SMS/text to be written notice.
        </li>
      </ol>
      <h4><strong>B. Closing and Restricting Your Shifl Capital Account</strong></h4>
      <ol>
        <li>
          You May Close Your Account. You may request to close your Shifl Capital Account at any time for any reason.
          However, we are not required to close your Shifl Capital Account at your request if you have pending
          transactions, your Deposit Account is overdrawn, or your Deposit Account is subject to legal process (such
          as a garnishment, attachment, execution, levy or similar order). In those cases, we will restrict your Shifl
          Capital Account against all future withdrawals and other transactions, other than under legal process until
          pending transactions are paid or returned, the balance on your Deposit Account is no longer negative, and
          any legal restriction on your Shifl Capital Account has been released.
        </li>
        <li>
          We May Close Your Account. We may also close your Shifl Capital Account at any time, for any reason or no
          reason, with or without prior notice to you. We will have no liability for any action we take under this
          section
        </li>
        <li>
          Rules for Closing Your Account. We may restrict your Shifl Capital Account in preparation for closing. As we
          do so, we will not pay any additional interest on your Deposit Account, and we may limit deposits to, or
          withdrawals from, your Deposit Account. We may prohibit further transactions on your Shifl Capital Card(s).
          Items presented for payment may be dishonored. When you close your Shifl Capital Account, you are
          responsible for leaving enough money in your Deposit Account to cover any outstanding items to be paid from
          your Deposit Account. We may send you written notice that we have closed your Shifl Capital Account. We will
          return the Available Balance in your Deposit Account less any outstanding unpaid Shifl Capital Card
          transactions, fees, claims, setoffs or other amounts you owe us, either electronically to a Linked External
          Account or by check sent to you by mail. This Agreement will survive the closing of your Shifl Capital
          Account and you are still responsible for obligations incurred prior to or in connection with the closing of
          your Shifl Capital Account. After your Shifl Capital Account is closed, we have no obligation to accept
          deposits or pay any outstanding transaction requests, but we may reopen your Deposit Account if we receive a
          deposit. This Agreement will survive the closing of your Account and you are still responsible for
          obligations incurred prior to or in connection with the closing of your Account.
        </li>
        <li>
          Restricting Your Account; Blocking and Delaying Transactions. There are many reasons we may decline or
          prevent transactions to or from your Shifl Capital Account, but we generally do it to protect you or us, or
          to comply with legal requirements. We may decline or prevent any or all transactions to or from your Shifl
          Capital Account in our sole discretion, as permitted by applicable law. We may refuse, freeze, reverse or
          delay any specific withdrawal, payment or transfer of funds to or from your Deposit Account, or we may
          remove funds from your Deposit Account to hold them pending investigation, including in one or more of the
          following circumstances:
          <ul>
            <li>You maintain your Deposit Account at zero balance for at least 180 days;</li>
            <li>
              You reside in or relocate outside of the U.S. or any U.S. territory where we offer the Shifl Capital
              Account;
            </li>
            <li>
              You attempt to use your Shifl Capital Account designated for business use, instead for personal
              purposes;
            </li>
            <li>Your Shifl Capital Account is involved in any legal or administrative proceeding;</li>
            <li>
              We receive conflicting information or instructions regarding Shifl Capital Account ownership,
              control or activity;
            </li>
            <li>
              We suspect that you may be the victim of a fraud, scam or financial exploitation, even though you
              have authorized the transaction(s);
            </li>
            <li>
              We suspect that any transaction may involve illegal activity, including violations of anti-money
              laundering laws and regulations, or may be fraudulent;
            </li>
            <li>
              We are complying in our sole judgment with any federal, state or local law, rule or regulation,
              including federal asset control and sanction rules and anti-money laundering rules, or with our
              policies adopted to assure that we comply with those laws, rules or regulations; or
            </li>
            <li>
              We reasonably believe that doing so is necessary to avoid a loss or reduce risk to us.<br
                data-mce-bogus="1">
            </li>
          </ul>
        </li>
      </ol>
      <h4><strong>C. Other Account Terms</strong></h4>
      <ol>
        <li>
          Security. It is your responsibility to protect the devices on which you access your Shifl Capital Account,
          your Shifl Capital Card(s), the username, password, biometrics or other data you use to log into the App
          (Access Credentials). Do not share this information with anyone. If you give another person permission to
          use this information, you may be held fully liable for any transactions they make with your Shifl Capital
          Account, even if that person exceeded the authority you gave them. Please notify us immediately if you
          believe your Shifl Capital Account has been accessed without your permission. See the Electronic Fund
          Transfer Agreement and Section III.E of this Agreement for more information about your rights and
          responsibility in the event of unauthorized use of your Shifl Capital Account.
        </li>
        <li>
          Assignment of Agreement and Successors. You may not assign or transfer any interest in your Shifl Capital
          Account unless we agree in writing. Any permitted assignment of your Shifl Capital Account is subject to our
          setoff rights. This Agreement will be binding on your personal representative, executors, administrators and
          successors, and on our successors and assigns.
        </li>
        <li>
          Power of Attorney. A power of attorney is a document you sign that authorizes someone else, called the
          agent, to act on your behalf. If you sign a power of attorney, the agent can sign on your behalf and do
          anything you could do regarding your Shifl Capital Account, including withdrawing or spending all of the
          money in your Deposit Account. Do not sign a power of attorney unless you trust the agent to act in your
          best interest. If you choose to add an agent, you must provide a power of attorney form that we agree to
          accept. We may rely on a copy of an original power of attorney. We are not required to investigate the facts
          relating to any power of attorney provided to us on your behalf, including whether your signature on the
          power of attorney is authentic or whether the agent continues to have authority. We may follow or refuse to
          follow the agent instructions at any time, including if we suspect fraud or abuse on your Shifl Capital
          Account, unless applicable law requires otherwise. We may also refuse an agent request to become a joint
          owner or a beneficiary of a Shifl Capital Account, but we have no liability to anyone if we do so. We have
          no liability when we follow or refuse to follow any instructions from an agent, for example, if your agent
          misuses the authority you have given them.
        </li>
        <li>
          Death or Incompetence. You agree to notify us promptly if any person with a right to use your Shifl Capital
          Account dies or is adjudicated incompetent. We may continue to honor your transactions and instructions
          until: (a) we know of your death or adjudication of incompetence, and (b) we have had a reasonable
          opportunity to act on that knowledge. You agree that we may pay or certify transactions made on or before
          the date of death or adjudication of incompetence for up to 10 days after your death or adjudication of
          incompetence unless ordered to stop payment by someone claiming an interest in the Shifl Capital Account.
        </li>
        <li>
          No Waiver. If we fail to exercise any right, that does not mean that we waive that right or any other right,
          and we may still enforce all of our rights in the future.
        </li>
        <li>
          Headings and Rules of Construction. The headings in this Agreement are for convenience or reference only and
          will not govern the interpretation of the provisions. Unless it would be inconsistent to do so, words and
          phrases used in this Agreement should be construed so the singular includes the plural and the plural
          includes the singular.
        </li>
        <li>
          Changes to this Agreement. We may change the terms and conditions of this Agreement, including fees and
          features of your Shifl Capital Account, at any time. We will notify you in advance of any such change when
          required by applicable law. If we have notified you of a change to this Agreement and you continue to use
          your Shifl Capital Account after the effective date of the change, you have agreed to the change. Notices
          will be made available on our App and website, or delivered electronically using the most recent contact
          information we have on file for you.
        </li>
        <li>
          Legal Actions Affecting Your Account. If we are served with a subpoena, restraining order, writ of
          attachment or execution, levy, garnishment, search warrant, or similar order relating to your Shifl Capital
          Account (Legal Action), we will comply with that Legal Action. Or, in our discretion, we may freeze the
          assets in your Deposit Account and not allow any payments out of your Deposit Account, or use of the Shifl
          Capital Card(s) for obligations secured by your Deposit Account, until a final court determination regarding
          the legal action. We may do these things even if the legal action involves less than all of your funds in
          your Deposit Account. In these cases, we will not have any liability to you if there are insufficient funds
          to pay your items because we have withdrawn funds from your Deposit Account or in any way restricted access
          to your funds in accordance with the legal action. Any fees or expenses we incur in responding to any legal
          action (including attorneys' fees and our internal expenses) may be charged against your Shifl Capital
          Account. The Fee Schedule may specify additional fees that we may charge for certain legal actions.
        </li>
        <li>
          Illegal Activities. You agree not to use your Shifl Capital Account for any illegal activity. We may refuse
          any gambling transaction, whether lawful or not.
        </li>
        <li>
          Inactive and Unclaimed Accounts. Each state has laws that govern when accounts are considered inactive or
          abandoned, and when we are required to send a customer funds to the state. We encourage you to make sure
          your Shifl Capital Account remains active by signing in to the App or making transactions. After a specified
          period of time determined by the law of the state of your residence (as shown in our records), if we are not
          able to locate you, we will be required to deliver your Deposit Account to the state as abandoned property.
          You will then need to apply to the state for return of your funds. If your Shifl Capital Account is or has
          been inactive, we may, in our discretion, refuse to honor requests for withdrawals or other transactions on
          your Shifl Capital Account until after we have verified that you have authorized the request.
        </li>
        <li>
          Reporting to Consumer Reporting Agency; Disputes. We may report information about you or your Shifl Capital
          Account to a consumer reporting agency. If you believe that we have reported inaccurate or incomplete
          information about your Shifl Capital Account to a consumer reporting agency, you have the right to file a
          dispute with that consumer reporting agency. You may also submit a dispute directly to us by writing to us
          at <strong>hello@shifl.com</strong>. Provide your name, address and phone number; your Shifl Capital Account
          number; the specific information you are disputing; an explanation of why it is inaccurate or incomplete;
          and any supporting documentation.
        </li>
        <li>
          Rules Governing Your Account. Except as otherwise provided in the Arbitration Agreement, this Agreement and
          your Shifl Capital Account are subject to applicable federal laws, the laws of the State of Tennessee
          without regard to internal principles of conflicts of law, and other applicable rules such as the operating
          letters of the Federal Reserve Banks and payment processing system rules (except to the extent that this
          Agreement can and does vary such rules or laws). Changes in these laws may change the terms and conditions
          of your Shifl Capital Account. We will notify you of any changes as required by law. Except as otherwise
          provided in the <em>Arbitration Agreement</em>, if any provision of this Agreement is found to be
          unenforceable according to its terms, all remaining provisions will continue in full force and effect. If
          this Agreement conflicts with any statements made by one of our employees or by a third-party service
          provider, this Agreement will govern.
        </li>
        <li>
          Fees. You agree to the terms of this Agreement and the Fee Schedule. You authorize us to deduct these
          charges, without notice to you, directly from the balance as accrued. You will pay any additional reasonable
          charges for services you request which are not covered by this Agreement.
        </li>
        <li>
          Attorneys' Fees Resulting from Collection or Dispute. You will be liable for our costs as well as for our
          reasonable attorneys' fees, to the extent permitted by law, whether incurred as a result of collection or in
          any other dispute involving your Shifl Capital Account. This includes you and any Authorized User(s); or a
          third party claiming an interest in your Shifl Capital Account. This also includes any action that you or a
          third party takes regarding the Shifl Capital Account that causes us, in good faith, to seek the advice of
          an attorney, whether or not we become involved in the dispute. All costs and attorneys' fees can be deducted
          from your Deposit Account balance when they are incurred, without notice to you.
        </li>
        <li>
          Limitation of Liability. You agree that we, our service providers, agents, officers, directors, and
          employees (and the same of our service providers, subsidiaries and affiliates themselves) (collectively, the
          Indemnified Parties) will not be liable for anything we do when following your instructions. In addition,
          the Indemnified Parties will not be liable if any such Indemnified Party does not follow your instructions
          if we reasonably believe that your instructions would expose us to potential loss or civil or criminal
          liability, or conflict with customary banking practices. To the extent you are entitled to relief under this
          Agreement, the total liability of the Indemnified Parties to you will not exceed $500, except as otherwise
          required by applicable law. THE INDEMNIFIED PARTIES WILL NOT BE LIABLE FOR INDIRECT, SPECIAL, OR
          CONSEQUENTIAL DAMAGES REGARDLESS OF THE FORM OF ACTION AND EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
          OF SUCH DAMAGES. IF WE FAIL TO STOP PAYMENT ON AN ITEM, OR PAY AN ITEM BEARING AN UNAUTHORIZED SIGNATURE,
          FORGED SIGNATURE, OR FORGED ENDORSEMENT OR ALTERATION, OUR LIABILITY, IF ANY, WILL BE LIMITED TO THE FACE
          AMOUNT OF THE ITEM. This section will survive termination of your Shifl Capital Account and termination of
          this Agreement.
        </li>
        <li>
          Indemnification. You agree to indemnify and hold the Indemnified Parties harmless from and against losses
          arising in connection with the services provided under this Agreement, except for losses arising out of our
          own gross negligence or willful misconduct. You further agree to hold the Indemnified Parties harmless from
          losses arising out of actions taken or omitted in good faith by any Indemnified Party in reliance upon
          instructions from you. The Indemnified Parties are not responsible for any actions or omissions by any third
          party. If you give us instructions that we believe may expose us to potential liability, we may refuse to
          follow your instructions and we will not be liable to you if we refuse to follow your instructions. If we do
          choose to follow your instructions, we may ask you for certain protections such as a surety bond or an
          indemnity agreement in a form that is satisfactory to us. This section will survive termination of your
          Shifl Capital Account and termination of this Agreement.
        </li>
        <li>
          Filing a Lawsuit or Arbitration. You must file any lawsuit or arbitration against the Indemnified Parties
          within two years after the cause of action arises, unless federal or state law or an applicable agreement
          provides for a shorter time. This limit is in addition to limits on notice as a condition to making a claim.
          If applicable state law does not permit contractual shortening of the time during which a lawsuit must be
          filed to a period as short as two years, you and we agree to the shortest permitted time under that state
          laws. We abide by applicable federal and state record retention laws and may dispose of any records that we
          retained or preserved for the period set forth in these laws. Any action against us or another Indemnified
          Party must be brought within the period that the law requires us to preserve records, unless applicable law
          or this Agreement provides a shorter limitation period or applicable law requires a longer limitation
          period. This section is subject to Section V.C.22, which may require you to submit your claims to
          arbitration. This section will survive termination of your Shifl Capital Account and termination of this
          Agreement. <em>YOU AGREE THAT YOU MAY BRING CLAIMS AGAINST THE BANK OR ANY OTHER INDEMNIFIED PARTY ONLY ON
            AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
            ACTION OR PROCEEDING.</em>
        </li>
        <li>
          Location of Legal Proceedings. If you file any lawsuit or other legal proceeding against an Indemnified
          Party that is connected in any way to your Shifl Capital Account or services, you agree to do so in an
          appropriate court in the State of Tennessee. If we file any lawsuit or legal proceeding that is connected in
          any way to your Shifl Capital Account or this Agreement, you consent to jurisdiction and venue in an
          appropriate court in the State of Tennessee. If any party chooses to have disputes resolved by arbitration,
          the Arbitration Agreement governs the process and location of the arbitration proceedings.
        </li>
        <li>
          Pre-Judgment Interest Rate. If either you or we are awarded a judgment against the other in connection with
          your Shifl Capital Account, the rate of interest earned before judgment on the judgment amount will be the
          rate of interest your Deposit Account earned during that period unless state law requires a different rate.
        </li>
        <li>
          Unlawful Internet Gambling Notice. Restricted transactions as defined in Federal Reserve Regulation GG are
          prohibited from being processed through this Shifl Capital Account or relationship. Restricted transactions
          generally include those in which credit, electronic fund transfers, checks, or drafts are knowingly accepted
          by gambling businesses in connection with the participation by others in unlawful Internet gambling.
        </li>
        <li>
          Severability. Any provision of this Agreement that limits the Bank liability does not negate the Bank duty
          (if any) under applicable law to act in good faith and with ordinary care. If any provision of this
          Agreement is determined to limit the Bank liability in a way prohibited by applicable law, or to be
          otherwise unenforceable, the parties agree to replace such provision with a provision that is valid and
          enforceable and that comes closest to expressing the intention of the invalid or unenforceable, and this
          Agreement will be enforceable as so modified.
        </li>
        <li>
          ARBITRATION AGREEMENT<br>PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS. YOU HAVE THE RIGHT TO
          OPT OUT OF THIS ARBITRATION AGREEMENT AS DESCRIBED BELOW.<br><strong>Agreement to Arbitrate<br></strong>This
          section is referred to as the Arbitration Agreement. If you have a dispute with the Bank or any other
          Indemnified Party, and are not able to resolve the dispute informally, you and we agree that upon demand by
          you, the Bank or any other Indemnified Party, the dispute will be resolved through the arbitration process
          set forth in this section.<br><strong>Arbitration<br></strong>You agree that if you have a dispute or claim
          that has arisen or may arise between you and the Bank or any other Indemnified Party, whether arising out of
          or relating to this Agreement (including any alleged breach), your Shifl Capital Account and services
          provided under this Agreement, any advertising, any aspect of the relationship or transactions between us,
          and you are not able to resolve the dispute or claim informally, you and we agree that upon demand by you,
          the Bank or any other Indemnified Party, the dispute or claim will be resolved exclusively through final and
          binding arbitration, rather than a court, in accordance with the terms of this Arbitration Agreement. except
          that you may assert individual claims in small claims court, if your claims qualify. Further, this
          Arbitration Agreement does not preclude you from bringing issues to the attention of federal, state, or
          local agencies, and such agencies can, if the law allows, seek relief against us on your behalf. You agree
          that, by entering into this Arbitration Agreement, you and the Bank are each waiving the right to a trial by
          jury or to participate in a class action. Your rights will be determined by a neutral arbitrator, not a
          judge or jury. The Federal Arbitration Act governs the interpretation and enforcement of this Arbitration
          Agreement.<br><strong>Parties Subject to this Arbitration Agreement<br></strong>This Arbitration Agreement
          applies whenever there is a claim between you and us. If a third party, such as an Indemnified Party other
          than the Bank, is also involved in a claim between you and us, or if a dispute arises between you and an
          Indemnified Party other than the Bank relating to this Agreement or your Shifl Capital Account, then the
          claim will be decided with respect to the third party in arbitration as well, in accordance with this
          Arbitration Agreement, and it must be named as a party in accordance with the rules of procedure governing
          the arbitration. No award or relief will be granted by the arbitrator except on behalf of, or against, a
          named party.<br><em>THIS ARBITRATION AGREEMENT DOES NOT APPLY TO YOU IF, AS OF THE DATE OF THIS AGREEMENT,
            YOU ARE A COVERED BORROWER UNDER THE FEDERAL MILITARY LENDING ACT. IF YOU WOULD LIKE MORE INFORMATION
            ABOUT WHETHER YOU ARE COVERED BY THE MILITARY LENDING ACT, IN WHICH CASE THIS ARBITRATION PROVISION DOES
            NOT APPLY TO YOU, PLEASE CONTACT US AT
            <strong>hello@shifl.com</strong><br></em><strong>Exclusions<br></strong>You and we retain the right to
          pursue in small claims court (or an equivalent state court) any dispute that is within that court
          jurisdiction, so long as the disputes remain in such court and advance only an individual claim for relief.
          If either you or we fail to submit to binding arbitration of an arbitrable dispute following lawful demand,
          the party so failing will bear all costs and expenses incurred by the other in compelling
          arbitration.<br><strong>Your Right to Opt Out<br></strong>If you do not want this Arbitration Agreement to
          apply to your Shifl Capital Account, you may opt out by sending us written notice of your decision within 30
          days of the opening of your Shifl Capital Account. Such notice must clearly state that you wish to cancel or
          opt out of the Arbitration Agreement section of this Agreement. It should include your name, address, Shifl
          Capital Account number, and your signature and must be mailed to Shifl capital, ATTN Legal department 343
          spook rock rd suffern ny 10901.<strong />This is the sole and only method by which you can opt out
          of this Arbitration Agreement. Your exercise of the right to opt-out will not affect any remaining terms of
          this Agreement and will not result in any adverse consequence to you or your Shifl Capital Account. You
          agree that our business records will be final and conclusive evidence with respect to whether you cancelled
          or opted out of this Arbitration Agreement in a timely and proper fashion.<br><br>
        </li>
      </ol>
      <p>
        <strong>Prohibition of Class and Representative Actions and Non-Individualized Relief<br></strong><em>YOU AGREE
          THAT YOU MAY BRING CLAIMS AGAINST THE BANK OR ANY OTHER INDEMNIFIED PARTY ONLY ON AN INDIVIDUAL BASIS AND NOT
          AS
          A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH YOU AND
          THE BANK AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON OR PARTY CLAIMS AND
          MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE
          ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE
          INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY
          INDIVIDUAL CLAIM(S), EXCEPT THAT YOU MAY PURSUE A CLAIM FOR AND THE ARBITRATOR MAY AWARD PUBLIC INJUNCTIVE
          RELIEF UNDER APPLICABLE LAW TO THE EXTENT REQUIRED FOR THE ENFORCEABILITY OF THIS
          PROVISION.<br></em><strong>Pre-Arbitration
          Dispute Resolution<br></strong>The Bank and Indemnified Parties are always interested in resolving disputes
        amicably and efficiently, and most customer concerns can be resolved quickly and to your satisfaction by
        emailing customer support at <strong>hello@shifl.com</strong>. If such efforts prove unsuccessful, a party who
        intends to seek arbitration must first send to the other, by certified mail, a written Notice of Dispute
        (Notice). The Notice to the Bank or Indemnified Party should be sent to Shifl capital, 343 spook rock rd suffern
        ny 10901. The Notice must (a) describe the nature and basis of the claim or dispute and (b) set forth the
        specific relief sought. If you do not resolve the claim with the Bank or Indemnified Party within 60 calendar
        days after the Notice is received, you or the Bank or Indemnified Party, as applicable, may commence an
        arbitration proceeding. During the arbitration, the amount of any settlement offer made by any party will not be
        disclosed to the arbitrator until after the arbitrator determines the amount, if any, to which you or the Bank
        is entitled.
      </p>
      <p>
        <br><strong>Arbitration Procedures<br></strong>Arbitration will be conducted by a neutral arbitrator in
        accordance with the American Arbitration Association (AAA) rules and procedures, including the AAA Consumer
        Arbitration Rules (collectively, the AAA Rules), as modified by this <em>Arbitration Agreement</em>. For
        information on the AAA, please visit its website, <a href="http://www.adr.org/"
          data-mce-href="http://www.adr.org/"><strong>http://www.adr.org</strong></a>.
        Information about the AAA Rules and fees for consumer disputes can be found at the AAA consumer arbitration
        page, <a href="http://www.adr.org/consumer_arbitration"
          data-mce-href="http://www.adr.org/consumer_arbitration"><strong>http://www.adr.org/consumer_arbitration</strong></a>.
        If there is any inconsistency between any term of the AAA Rules and any term of this <em>Arbitration
          Agreement</em>, the applicable terms of this <em>Arbitration Agreement</em> will control unless the
        arbitrator determines that the application of the inconsistent <em>Arbitration Agreement</em> terms would not
        result in a fundamentally fair arbitration. The arbitrator must also follow the provisions of this Agreement as
        a court would. All issues are for the arbitrator to decide, including issues relating to the scope,
        enforceability, and arbitrability of this <em>Arbitration Agreement</em>. Although arbitration proceedings are
        usually simpler and more streamlined than trials and other judicial proceedings, the arbitrator can award the
        same damages and relief on an individual basis that a court can award to an individual under this Agreement and
        applicable law. Decisions by the arbitrator are enforceable in court and may be overturned by a court only for
        very limited reasons.<br>Unless the parties to the arbitration agree otherwise, any arbitration hearings will
        take place in a reasonably convenient location for both parties with due consideration of their ability to
        travel and other pertinent circumstances. If the parties are unable to agree on a location, the determination
        will be made by AAA. If your claim is for $10,000 or less, you may choose whether the arbitration will be
        conducted solely on the basis of documents submitted to the arbitrator, through a telephonic hearing, or by an
        in-person hearing as established by the AAA Rules. If your claim exceeds $10,000, the right to a hearing will be
        determined by the AAA Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator will
        issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the
        award is based.<br><strong>Costs of Arbitration<br></strong>Payment of all filing, administration, and
        arbitrator fees (collectively, the Arbitration Fees) will be governed by the AAA Rules, unless otherwise
        provided in this Arbitration Agreement. If the value of the relief sought is $75,000 or less, at your request,
        the Bank will pay all Arbitration Fees. If the value of relief sought is more than $75,000 and you are able to
        demonstrate to the arbitrator that you are economically unable to pay your portion of the Arbitration Fees or if
        the arbitrator otherwise determines for any reason that you should not be required to pay your portion of the
        Arbitration Fees, the Bank will pay your portion of such fees. In addition, if you demonstrate to the arbitrator
        that the costs of arbitration will be prohibitive as compared to the costs of litigation, the Bank will pay as
        much of the Arbitration Fees as the arbitrator deems necessary to prevent the arbitration from being
        cost-prohibitive. Any payment of attorneys' fees will be governed by the AAA
        Rules.<br><strong>Confidentiality<br></strong>All aspects of the arbitration proceeding, and any ruling,
        decision, or award by the arbitrator, will be strictly confidential for the benefit of all
        parties.<br><strong>Severability<br></strong>If
        a court or the arbitrator decides that any term or provision of this <em>Arbitration Agreement</em> (other than
        the <em>Prohibition of Class and Representative Actions and Non-Individualized Relief</em> section above) is
        invalid or unenforceable, the parties agree to replace such term or provision with a term or provision that is
        valid and enforceable and that comes closest to expressing the intention of the invalid or unenforceable term or
        provision, and this <em>Arbitration Agreement</em> will be enforceable as so modified. If a court or the
        arbitrator decides that any of the provisions of the <em>Prohibition of Class and Representative Actions and
          Non-Individualized Relief</em> section are invalid or unenforceable, then the entirety of this <em>Arbitration
          Agreement</em> will be null and void, unless such provisions are deemed to be invalid or unenforceable
        solely with respect to claims for public injunctive relief. The remainder of this <em>Arbitration Agreement</em>
        will continue to apply.
      </p>
      <p>
        <strong>Future Changes to this Arbitration AGREEMENT<br></strong>Notwithstanding any provision in this Agreement
        to the contrary, if the Bank makes any future change to this <em>Arbitration Agreement</em> (other than a change
        to the Notice Address) while you have a Shifl Capital Account, you may reject any such change by sending the
        Bank written notice within 30 days of the change to the Notice Address. By rejecting any future change, you are
        agreeing that you will arbitrate any dispute covered by this <em>Arbitration Agreement</em> in accordance with
        the terms of this <em>Arbitration Agreement</em> as of the date you first accepted this Agreement (or accepted
        any subsequent changes to this Agreement).
      </p>
      <h3><strong>FEE SCHEDULE</strong></h3>
      <p>
        This <em>Fee Schedule</em> lists the fees that may be applicable to your Shifl Capital Account. You agree to pay
        all fees applicable to your Shifl Capital Account. We reserve the right to add or change fees at a later time,
        and we will notify you of any changes to our fees and this Fee Schedule, as required by applicable law. We may
        subtract these fees from your balance, even if the fee makes your balance negative.
      </p>
      <table class="mce-item-table">
        <tbody>
          <tr>
            <td colspan="2">
              <p>
                <strong>Shifl Capital Banking Services
                  Fees</strong>
              </p>
              <p><em>Fintech will apply the following fees: </em></p>
            </td>
          </tr>
          <tr>
            <td>
              <p><strong>Type</strong></p>
            </td>
            <td>
              <p><strong>Fee</strong></p>
            </td>
          </tr>
          <tr>
            <td>
              <p><strong>Account Monthly Fee</strong></p>
            </td>
            <td>
              <p>$0.0 per account</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><strong>ACH Transactions</strong></p>
            </td>
            <td width="293">
              <p>Incoming: $0 per ACH transfer received</p>
              <p>Outgoing: $0.25 per ACH transfer sent</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><strong>Domestic Wires </strong></p>
            </td>
            <td>
              <p>Incoming: $1.00 per wire transfer received</p>
              <p>Outgoing: $20.00 per wire transfer sent</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><strong>International Wires</strong></p>
            </td>
            <td>
              <p>Incoming: $0.00 per wire transfer received</p>
              <p>Outgoing: $30.00 per wire transfer sent</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><strong>Remote Check Deposit</strong></p>
            </td>
            <td>
              <p>$0.0 per check</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><strong>ACH returns</strong></p>
            </td>
            <td>
              <p>$15 per transaction</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><strong>NSF Fees</strong></p>
            </td>
            <td>
              <p>$35 per transaction</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><strong>Stop Payment</strong></p>
            </td>
            <td>
              <p>$35 per transaction</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><strong>Research Request</strong></p>
            </td>
            <td>
              <p>$15 per transaction</p>
            </td>
          </tr>
        </tbody>
      </table>
      <h3><strong>&nbsp;</strong></h3>
      <h3><strong>FUNDS AVAILABILITY POLICY</strong></h3>
      <p>
        PURPOSE. The information here describes our policy of holding deposited items in a Deposit Account before funds
        are made available to you for withdrawal.
      </p>
      <p>
        DETERMINING THE AVAILABILITY OF YOUR DEPOSIT. Our policy is to delay the availability of funds from your
        deposits. The length of the delay varies based on the type of deposit as set forth below. During the delay, you
        may not withdraw the funds, and we will not pay transactions from your Deposit Account using these funds.
      </p>
      <p>
        The length of the delay is counted in Business Days from the day of your deposit. If you make a deposit before
        2:00PM PT on a Business Day, we will consider that day to be the day of your deposit. However, if you make a
        deposit after 2:00PM PT or on a day that is not a Business Day, we will consider the deposit made on the next
        Business Day.
      </p>
      <p>
        Same Day Availability. Electronic direct deposits (e.g., ACH, person-to-person transfers, and other EFTs your
        Deposit Account supports) will be available on the day we receive your deposit.
      </p>
      <p>
        Next Day Availability. Funds from the following deposits are available on the first Business Day after the day
        we
        receive your deposit:
      </p>
      <ul>
        <li>The first $200.00 of check deposits.</li>
      </ul>
      <p>
        Second Business Day. Unless otherwise provided by this Agreement, all other funds from deposits will be made on
        the second Business Day after the day of your deposit.
      </p>
      <p>
        Longer Delays May Apply. Funds you deposit may be delayed for longer than two Business Days under the following
        circumstances:
      </p>
      <ul>
        <li>We believe a you initiated will not be honored;</li>
        <li>Your deposits total more than $2,500 in any one day;</li>
        <li>You have overdrawn your Deposit Account repeatedly in the last six months; or</li>
        <li>There is an emergency, such as failure of communications or our systems.</li>
        <li>
          If we delay availability for one of these reasons, funds may not be available until the seventh Business Day
          after the day of your deposit. We will notify you if we delay your ability to withdraw funds for any of
          these reasons, and we will tell you when the funds will be available. If you will need the funds from a
          deposit right away, you should ask us when the funds will be available.
        </li>
      </ul>
      <p>
        Special Rules for New Accounts. If you are a new customer, the following special rules will apply during the
        first 60 days your Deposit Account is open.
      </p>
      <ul>
        <li>
          Funds from electronic direct deposits to your Deposit Account will be available on the first Business Day
          after we receive the deposit.
        </li>
        <li>
          Funds from deposits of cash, wire transfers, and the first $2,500 of a day's total deposits of cashier's,
          certified, teller's, traveler's, and federal, state and local government checks will be available on the
          first Business Day after the day of your deposit if the deposit meets certain conditions.
        </li>
        <li>
          The excess over $2,500 will be available no later than the ninth Business Day after the day of your
          deposit.
        </li>
        <li>
          Funds from all other deposits will be available on the third Business Day after the day of your deposit.
        </li>
      </ul>
      <p>
        Your Responsibility for Deposits. Even if we have made funds available to you and you have withdrawn the funds,
        you are still responsible for deposits that are returned to us unpaid and for any other problems involving your
        deposit.
      </p>
      <h3><strong>ELECTRONIC FUND TRANSFER AGREEMENT</strong></h3>
      <p>Electronic Fund Transfers: Your Rights and Responsibilities</p>
      <p>
        This disclosure contains important information about your use of electronic fund transfer (EFT) services
        provided
        by the Bank in connection with your Shifl Capital Account. Whenever you accept or authorize EFTs to your Deposit
        Account, you agree to these terms and conditions, in addition to any other applicable limitations in this
        Agreement concerning deposits to and withdrawals from your Deposit Account. Please read this document carefully
        and retain it for future reference.
      </p>
      <p>
        Business Days. Our Business Days are every day except Saturdays, Sundays, and holidays on which banks in
        [Tennessee] are legally authorized to be closed.
      </p>
      <p>Types of Available EFTs</p>
      <ul>
        <li>
          <em>Online ACH Transfers</em>. You may use the App and request ACH transfers between your Deposit Account
          and a Linked External Account.
        </li>
        <li>
          <em>P2P Transfers</em>. You can make or receive transfers between your Deposit Account and the Deposit
          Account of another Shifl Capital user, using the person-to-person transfer services (P2P Transfer) provided
          in the App. P2P Transfers are subject to additional terms and conditions.
        </li>
      </ul>
      <p>
        Limits on the Type, Frequency or Amount of EFTs. Your Deposit Account may be subject to limitations on the
        amount
        or number of withdrawals that you may make from your Deposit Account, including EFTs. Please see Article IV of
        this Agreement and the Truth in Savings Disclosures. For security reasons, we may also, in our sole discretion,
        set limits on the dollar amounts of EFTs and on the number of EFTs that may be requested or initiated. To
        protect the security of the Bank or your Deposit Account, we may suspend your use of EFTs, as permitted by
        law.
      </p>
      <p>
        Managing Your Account Balance. You agree that the amount of any EFT debit transaction you authorize will not
        exceed the Available Balance in your Deposit Account used to make the transaction, or your Spending Limit. We do
        not have to allow any EFT that would create an overdraft in your Deposit Account. We may in our sole discretion
        choose to permit an EFT that would create an overdraft in your Deposit Account. If we are unable to complete an
        EFT or perform any other EFT service for any reason associated with your Deposit Account, the EFT or other
        service may not be completed and we will not notify you unless required by applicable law.
      </p>
      <p><br></p>
      <p><strong><u>Your Rights and Responsibilities</u></strong></p>
      <p>
        The use of EFT services described in this Agreement creates certain rights and responsibilities regarding these
        services as described below.
      </p>
      <p>Documentation of Your EFTs</p>
      <p>
        <em>Periodic Statements.</em> All EFT transactions will be reported on your periodic statement. See Section
        II.A.8 of this Agreement for more information.
      </p>
      <p>
        <em>Direct Deposits.</em> If you have arranged to have direct deposits made to your Deposit Account at least
        once
        every 60 days, the company making the deposit may tell you every time they send us the money. You may also email
        us at <a href="mailto:hello@shifl.com"
          data-mce-href="mailto:hello@shifl.com"><strong>hello@shifl.com</strong></a><strong />to find
        out whether or not the deposit has been made.
      </p>
      <p>
        <em>Confirmation.</em> You may write to us at <strong>hello@shifl.com</strong> to find out whether any EFT you
        request was successfully completed.
      </p>
      <p>Preauthorized and Other Regular (Automatic) Debits</p>
      <p><em>Right to Stop Payment and Procedure for Doing So</em></p>
      <p>
        If you have told us in advance to make regular payments out of your Deposit Account, you can stop any of the
        payments. Here is how:
      </p>
      <p>
        Email to us at <strong>hello@shifl.com</strong> in time for us to receive your request three Business Days or
        more before the payment is scheduled to be made. If you contact us to stop a payment, please have the following
        information ready: your account number, the date the transfer is to be made, to whom the transfer is being made,
        and the amount of the scheduled transfer. If you call, we may also require you to put your request in writing
        and get it to us within 14 days after you call. We may charge you (see Fee Schedule for exact fees) for each
        stop-payment order you give. Stop-payment orders may expire after six months from the date of your stop-payment
        request, and you may be required to renew it in order to ensure its continued effectiveness.
      </p>
      <p><em>Liability for Failure to Stop Payment of Preauthorized Transfer</em></p>
      <p>
        If you order us to stop a preauthorized automatic debit three Business Days or more before the transfer is
        scheduled and we do not do so, we will be liable for your losses or damages.
      </p>
      <p><em>Notice of Varying Amounts</em></p>
      <p>
        If you have authorized regular payments out of your Deposit Account, and these regular payments may vary in
        amount, we or the person you are going to pay will tell you, 10 days before each payment, when it will be made
        and how much it will be. You may choose instead to get this notice only when the payment would differ by more
        than a certain amount from the previous payment, or when the amount would fall outside certain limits that you
        set.
      </p>
      <p><em>Our Refusal to Pay a Preauthorized or Other Regular (Automatic) Debit</em></p>
      <p>
        We reserve the right to refuse to pay a regular (automatic) debit, for example, if your Deposit Account has
        insufficient funds to cover such transfer or if we are unable to verify the ownership of or other information
        about the account to which the funds will be transferred. You may write to us at <a
          href="mailto:hello@shifl.com"
          data-mce-href="mailto:hello@shifl.com"><strong>hello@shifl.com</strong></a><strong />to request
        information about a refusal by us to pay a regular (automatic) debit.
      </p>
      <p><em>Your Liability for Unauthorized Transfers</em></p>
      <p>
        During your online Shifl Capital Account set-up, you will be invited to register your phone number and create
        Access Credentials to protect your Shifl Capital Account from unauthorized access. You are responsible for
        maintaining ownership of the mobile device associated with the phone number and security of your other Access
        Credentials.
      </p>
      <p>
        Tell us AT ONCE if you believe that any Access Credentials or device you use to access your Fintech Account has
        been lost or stolen, that an EFT has been made without your permission, or that someone has transferred or may
        transfer money from your Deposit Account without your authorization. You may write to us at <a
          href="mailto:hello@shifl.com" data-mce-href="mailto:hello@shifl.com">hello@shifl.com</a> or such other
        address as we may provide to you from time to time). Using our mobile chat messenger from the Shifl Capital
        application is the best way of keeping your possible losses down. You could lose all the money in your Deposit
        Account. If you tell us within two Business Days after you learn of the loss or theft of your Access Credentials
        or device, your liability will be limited to $50 if someone accesses your Deposit Account without your
        authorization.
      </p>
      <p>
        If you DO NOT tell us within two Business Days after you learn of the lost or theft of your Access Credentials
        or
        device, and we can prove we could have stopped someone from using your Access Credentials or device without your
        permission if you had told us, you could lose as much as $500.
      </p>
      <p>
        Also, if your statement shows transfers that you did not make, tell us AT ONCE. If you do not tell us within 60
        days after the statement was mailed to you, you may not get back any money you lost after the 60 days if we can
        prove that we could have stopped someone from taking the money had you told us in time. If a good reason (such
        as a long trip or a hospital stay) kept you from telling us, we will extend the time periods.
      </p>
      <p>
        If you grant authority to make transfers to another person who exceeds the authority granted, you are fully
        liable for any transfers until you notify us that transfers by that person are no longer authorized.
      </p>
      <p>In Case of Errors or Questions about your EFTs</p>
      <p>
        Write to us at <a href="mailto:hello@shifl.com" data-mce-href="mailto:hello@shifl.com">hello@shifl.com</a> as
        soon as you can, if you think your statement or receipt is wrong or if you need more information about a
        transfer listed on the statement or receipt. We must hear from you no later than 60 days after we send the FIRST
        statement on which the problem or error appeared.
      </p>
      <p>(1) Tell us your name and your account number (if any).</p>
      <p>
        (2) Describe the error or transfer you are unsure about, and explain as clearly as you can why you believe it is
        an error or why you need more information.
      </p>
      <p>(3) Tell us the dollar amount of the suspected error.</p>
      <p>
        If you tell us orally, we may require that you send us your complaint or question in writing within 10 Business
        Days.
      </p>
      <p>
        We will determine whether an error occurred within 10 Business Days after we hear from you and will correct any
        error promptly. If we need more time, however, we may take up to 45 days to investigate your complaint or
        question. If we decide to do this, we will credit your Deposit Account within 10 Business Days for the amount
        you think is in error, so that you will have use of the money during the time it takes us to complete our
        investigation. If we ask you to put your complaint or question in writing and we do not receive it within 10
        Business Days, we may not credit your Deposit Account.
      </p>
      <p>
        For errors involving new Shifl Capital Accounts, point-of-sale, or foreign-initiated transactions, we may take
        up
        to 90 days to investigate your complaint or question. For new Shifl Capital Accounts, we may take up to 20 days
        to credit your Deposit Account for the amount you think is in error.
      </p>
      <p>
        We will tell you the results within three Business Days after completing our investigation. If we decide that
        there was no error, we will send you a written explanation. You may ask for copies of the documents that we used
        in our investigation.
      </p>
      <p>
        Our Liability for Failure to Make a Transfer. If we do not complete a transfer to or from your Deposit Account
        on
        time or in the correct amount according to this Agreement, we will be liable for your losses or damages.
        However, there are some exceptions. We will not be liable, for instance:
      </p>
      <ul>
        <li>
          If, through no fault of ours, you do not have enough money in your Deposit Account to make the transfer.
        </li>
        <li>If the transfer would go over the credit limit on your overdraft line of credit, if you have one.</li>
        <li>
          If an automated teller machine where you are making a transfer does not have enough cash. If a system or
          terminal you use to make a transfer was not working properly and you knew about the breakdown when you
          started the transfer.
        </li>
        <li>
          If circumstances beyond our control (such as fire or flood) prevent the transfer despite reasonable
          precautions that we have taken.
        </li>
        <li>There may be other exceptions stated in this Agreement.</li>
      </ul>
      <p>Charges for Transfers or the Right to Make Transfers</p>
      <p>
        We may assess a fee for each EFT, or stop-payment order. Please see the applicable <em>Fee Schedule</em> to
        determine the applicable amount. The circumstances under which such charges will be assessed, as well as the
        amount of the charge, are included in the current Fee Schedule.
      </p>
      <p>Confidentiality</p>
      <p>We will disclose information to third parties about your Shifl Capital Account or the transfers you make:</p>
      <p>(1) Where it is necessary for completing transfers;</p>
      <p>
        (2) In order to verify the existence and condition of your Shifl Capital Account for a third party, such as a
        credit bureau or merchant;
      </p>
      <p>(3) In order to comply with government agency or court orders;</p>
      <p>(4) To persons authorized by law in the course of their official duties;</p>
      <p>
        (5) To our employees, auditors, service providers, attorneys, or collection agents in the course of their
        duties;
      </p>
      <p>(6) As disclosed in our Privacy Notice; and,</p>
      <p>(7) If you give us your written permission.</p>
      <p>
        Amendments. We may change any provision of this <em>Electronic Fund Transfer Agreement</em> in our discretion.
        If
        we do, we will give you notice if and to the extent required by applicable law.
      </p>
      <p>
        Termination. We reserve the right to terminate your use of any or all of our EFT services for any reason and at
        any time without notifying you. You also have the right to terminate your use of the EFT services described in
        this Agreement at any time by calling us or writing to us. If you call, we may require you to put your request
        in writing and get it to us within 10 Business Days after you call. (Please note that e-mail, SMS/text or online
        notification will not meet the notice requirements of this section.) If you terminate your use of our EFT
        services and you have arranged for automatic (regular) transfers involving third parties, you are required to
        inform any third parties that the transfers will cease. Any termination of your use of our EFT services, whether
        initiated by you or us, will not affect any of your or our rights and obligations under this Agreement that have
        arisen before the effective date of such termination.
      </p>
      <p><br data-mce-bogus="1"></p>
      <!--  END OF ACCOUNT AGREEMENT  -->
      <!--  ELECTRONIC COMM AGREEMENT  -->
      <h2>Shifl Capital</h2>
      <p>Effective May 1, 2021</p>
      <h2>Electronic Communications Agreement</h2>
      <p>
        It is our goal to provide you with as many digital banking options as possible, including providing information
        to you electronically. We are required by law to give you certain information in writing. This means you have
        the right to receive that information on paper. With your consent, we can provide it to you electronically,
        instead.
      </p>
      <p>In this Agreement:</p>
      <ul>
        <li>"We," "us," "our," and "Fintech" mean Shifl Capital.</li>
        <li>
          "You" and "your" mean the person giving this consent, and also each additional account owner, authorized
          signer, authorized representative, delegate, product owner and/or service user identified on any Fintech
          product that you apply for, use or access.
        </li>
        <li>
          "Communications" means each disclosure, notice, agreement, fee schedule, privacy policy, statement, record,
          document, and other information we provide to you, or that you sign or submit or agree to at our request.
        </li>
      </ul>
      <h3><strong>You agree to receive electronic Communications from us.</strong></h3>
      <p>
        As part of your relationship with us, we will provide you with Communications. By accepting this Agreement, you
        consent to receive Communications in electronic form for any products, services and accounts that you have or
        apply for, either now or in the future. You also consent to the use of electronic signatures in your
        relationship with us.
      </p>
      <p>
        We may still choose to provide you with information on paper even though you have consented to receive it
        electronically. In some cases, we are not permitted by law to deliver certain Communications to you
        electronically. However, if the law changes in the future and permits additional Communications to be delivered
        electronically, this Agreement will automatically cover those Communications as well.
      </p>
      <h3><strong>We may deliver electronic Communications to you in several ways.</strong></h3>
      <p>
        When we deliver electronic Communications to you, we will either: (1) email them to you; (2) post them on our
        website; (3) present them on-screen or for download through one of our online or mobile banking services; (4)
        include them in other electronic Communications that we are delivering to you, such as electronic account
        statements; or (5) in the manner specified in any other agreement we have with you. If we are not emailing or
        presenting the information to you directly, we will tell you where you can go to view that information.
      </p>
      <p>
        If you seek to obtain a new product, service or account with us, we may remind you that you have already
        consented to receiving electronic Communications and using electronic signatures in your relationship with us.
        If you choose not to receive electronic Communications or use electronic signatures in connection with the new
        product, service or account, that choice does not mean you have withdrawn your consent with respect to any other
        product, service or account.
      </p>
      <h3><strong>You will need some technology to receive electronic Communications.</strong></h3>
      <p>To receive and retain electronic Communications from us, you will need the following:</p>
      <ul>
        <li>A computer or mobile device with an operating system that supports everything below;</li>
        <li>A connection to the internet;</li>
        <li>
          A current version of a web browser that we support. You should check the system requirements periodically
          for updates to supported software;
        </li>
        <li>A hard drive or other data storage unit;</li>
        <li>A valid, active email address; and</li>
        <li>
          A current version of a program that displays PDF files (such as<a href="https://get.adobe.com/reader"
            data-mce-href="https://get.adobe.com/reader" /><a href="https://get.adobe.com/reader"
            data-mce-href="https://get.adobe.com/reader">Adobe® Reader®</a>).
        </li>
      </ul>
      <p>
        If our hardware and software requirements change, and that change would create a material risk that you would
        not
        be able to access or retain electronic Communications, we will give you notice of the change. Continuing to use
        our electronic services after receiving notice of updates to our system requirements signifies your acceptance
        of the change and reaffirmation of your consent.
      </p>
      <h3><strong>Please keep your contact information up to date.</strong></h3>
      <p>
        It is your responsibility to keep all of your contact information current so that we can send you electronic
        Communications. You can update your email address and other contact information by editing your profile in the
        FintechApplication. You may also contact us at hello@fintech.com
      </p>
      <h3><strong>You may have the option to receive paper copies.</strong></h3>
      <p>
        We may choose to make paper copies of certain electronic Communications available upon request. You can request
        paper account statements via email to hello@fintech.com
      </p>
      <h3><strong>You can withdraw your consent to receiving electronic Communications.</strong></h3>
      <p>
        You have the right to withdraw this consent at any time. Withdrawing consent may terminate your access to
        certain
        electronic services. You may also no longer be able to use certain Fintech products. After we have processed
        your withdrawal, future Communications required to be in writing will be delivered to you on paper, subject to
        applicable fees. To withdraw your consent to receiving electronic Communications, you may contact us via email
        at hello@fintech.com Your withdrawal of consent will become effective only after we have had a reasonable
        opportunity to act upon it.
      </p>
      <p><br data-mce-bogus="1"></p>
      <!--  END OF ELECTRONIC COMM AGREEMENT  -->
      <!--  PRIVACY POLICY  -->
      <h1>Privacy Policy</h1>
      <h2>What does Evolve Bank &amp; Trust do with your Personal Information?</h2>

      <div>
        <!-- Pull in WP content -->
        <main id="main" class="site-main">
          <article class="post-5710 page type-page status-publish ast-article-single" id="post-5710"
            itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
            <header class="entry-header ast-header-without-markup">

            </header><!-- .entry-header -->

            <div class="entry-content clear" itemprop="text">



              <h2>Policies</h2>



              <figure class="wp-block-table">
                <table>
                  <tbody>
                    <tr>
                      <td><strong>WHY?</strong></td>
                      <td>Financial companies choose how they share your
                        personal information. Federal law gives consumers
                        the right to limit some but not all sharing. Federal
                        law also requires us to tell you how we collect,
                        share, and protect your personal information. Please
                        read this notice carefully to understand what we do.
                      </td>
                    </tr>
                    <tr>
                      <td><strong>WHAT?</strong></td>
                      <td>The types of personal information we collect and
                        share depend on the product or service you have with
                        us. This information can include:<br><br>Social
                        Security number and account transactions<br>Payment
                        history and account balances<br>Credit history and
                        transaction or loss history<br><br>When you are
                        <em>no longer</em> our member, we continue to share
                        your information as described in this notice.
                      </td>
                    </tr>
                    <tr>
                      <td><strong>HOW?</strong></td>
                      <td>All financial companies need to share members’
                        personal information to run their everyday business.
                        In the section below, we list the reasons financial
                        companies can share their customers’ or
                        members&#8217; personal information; the reasons
                        Evolve Bank &amp; Trust chooses to share; and
                        whether you can limit this sharing.</td>
                    </tr>
                  </tbody>
                </table>
              </figure>



              <h2>Sharing Your Information</h2>



              <figure class="wp-block-table is-style-regular">
                <table class="has-fixed-layout">
                  <thead>
                    <tr>
                      <th class="has-text-align-center" data-align="center">
                        Reasons We Can Share Your Information</th>
                      <th class="has-text-align-center" data-align="center">
                        Does Evolve Bank &amp; Trust Share?</th>
                      <th class="has-text-align-center" data-align="center">
                        Can You Limit This Sharing?</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="has-text-align-center" data-align="center">
                        <strong>For our everyday business purposes</strong>
                        such as to process your transactions, maintain your
                        account(s), respond to court orders and legal
                        investigations, or report to credit bureaus
                      </td>
                      <td class="has-text-align-center" data-align="center">
                        <strong>YES</strong>
                      </td>
                      <td class="has-text-align-center" data-align="center">
                        <strong>NO</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="has-text-align-center" data-align="center">
                        <strong>For our marketing purposes&nbsp;</strong>to
                        offer our products and services to you
                      </td>
                      <td class="has-text-align-center" data-align="center">
                        <strong>YES</strong>
                      </td>
                      <td class="has-text-align-center" data-align="center">
                        <strong>NO</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="has-text-align-center" data-align="center">
                        <strong>For joint marketing with other financial
                          companies</strong>
                      </td>
                      <td class="has-text-align-center" data-align="center">
                        <strong>YES</strong>
                      </td>
                      <td class="has-text-align-center" data-align="center">
                        <strong>NO</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="has-text-align-center" data-align="center">
                        <strong>For our affiliates’ everyday business
                          purposes&nbsp;</strong>information about your
                        transactions and experiences
                      </td>
                      <td class="has-text-align-center" data-align="center">
                        <strong>YES</strong>
                      </td>
                      <td class="has-text-align-center" data-align="center">
                        <strong>NO</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="has-text-align-center" data-align="center">
                        <strong>For our affiliates’ everyday business
                          purposes&nbsp;</strong>information about your
                        creditworthiness
                      </td>
                      <td class="has-text-align-center" data-align="center">
                        <strong>NO</strong>
                      </td>
                      <td class="has-text-align-center" data-align="center">
                        <strong>WE DON&#8217;T SHARE</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="has-text-align-center" data-align="center">
                        <strong>For our affiliates to market to you</strong>
                      </td>
                      <td class="has-text-align-center" data-align="center">
                        <strong>NO</strong>
                      </td>
                      <td class="has-text-align-center" data-align="center">
                        <strong>WE DON&#8217;T SHARE</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="has-text-align-center" data-align="center">
                        <strong>For non-affiliates to market to
                          you<br></strong>
                      </td>
                      <td class="has-text-align-center" data-align="center">
                        <strong>NO</strong>
                      </td>
                      <td class="has-text-align-center" data-align="center">
                        <strong>WE DON&#8217;T SHARE</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="has-text-align-center" data-align="center">
                      </td>
                      <td class="has-text-align-center" data-align="center">
                      </td>
                      <td class="has-text-align-center" data-align="center">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </figure>



              <h2>Questions? Call <a href="tel:866.952754">866.395.2754</a></h2>



              <figure class="wp-block-table">
                <table>
                  <tbody>
                    <tr>
                      <td><strong>WHO WE ARE</strong></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Who is providing this notice?</td>
                      <td>Evolve Bank &amp; Trust</td>
                    </tr>
                    <tr>
                      <td><strong>WHAT WE DO</strong></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>How does Evolve Bank &amp; Trust protect my personal
                        information?</td>
                      <td>To protect your personal information from
                        unauthorized access and use, we use security
                        measures that comply with federal law. These
                        measures include computer safeguards and secured
                        files and buildings.</td>
                    </tr>
                    <tr>
                      <td>How does Evolve Bank &amp; Trust collect my personal
                        information?&nbsp;</td>
                      <td>We collect your personal information, for example,
                        when you:<br><br>Open an account or provide account
                        information<br>Use your credit or debit card or
                        apply for financing<br>Give us your contact
                        information<br><br>We also collect your personal
                        information from others, such as credit bureaus,
                        affiliates, or other companies.</td>
                    </tr>
                    <tr>
                      <td>Why can’t I limit all sharing?</td>
                      <td>Federal law gives you the right to limit
                        only:<br><br>Sharing for affiliates’ everyday
                        business purposes – information about your
                        creditworthiness<br>Affiliates from using your
                        information to market to you<br>Sharing for
                        non-affiliates to market to you<br><br>State laws
                        and individual companies may give you additional
                        rights to limit sharing. See below for more on your
                        rights under state law.</td>
                    </tr>
                  </tbody>
                </table>
              </figure>



              <h2>Definitions</h2>



              <figure class="wp-block-table">
                <table>
                  <tbody>
                    <tr>
                      <td><strong>Affiliates</strong></td>
                      <td>Companies related by common ownership or control.
                        They can be financial and non-financial
                        companies.<br><br><em>Our affiliates include
                          companies with an Evolve name, such as Evolve
                          Bancorp, Inc.</em></td>
                    </tr>
                    <tr>
                      <td><strong>Non-Affiliates</strong></td>
                      <td>Companies not related by common ownership or
                        control. They can be financial and non-financial
                        companies.<br><br><em>Non-affiliates we share with
                          can include insurance companies, service
                          providers, co-branded partners, retailers, data
                          processors, membership clubs, companies engaged
                          in selling of consumer products and services,
                          financial services companies, banks, and
                          mortgage servicing companies.</em></td>
                    </tr>
                    <tr>
                      <td><strong>Joint Marketing</strong></td>
                      <td>A formal agreement between nonaffiliated financial
                        companies that together market financial products or
                        services to you.<br><br><em>Our joint marketing
                          partners include companies such as financial
                          services companies and other financial
                          institutions.</em></td>
                    </tr>
                  </tbody>
                </table>
              </figure>



              <h5>OTHER IMPORTANT INFORMATION:</h5>



              <p><strong>VT:</strong> Accounts with a Vermont mailing address are
                automatically treated as if they have limited the sharing described
                on page 1 and we will not share information about you with
                nonaffiliated third parties, except as permitted by law.</p>



              <p><strong>NV:</strong> If you prefer not to receive marketing calls
                from us, you may be placed on our Internal Do Not Call List by
                calling 866.395.2754 or by writing to us at: 6000 Poplar Ave, Suite
                300, Memphis, TN 38119. For more information about telemarketing
                practices, contact us at the address in the preceding sentence.</p>



              <p>You may also contact the Nevada Attorney General’s Office:<br>Bureau
                of Consumer Protection<br>Office of the Nevada Attorney
                General<br>555 E. Washington St., Suite 3900,<br>Las Vegas, NV
                89101<br>tel: <a href="tel:702.486.3132">702.486.3132</a><br>email:
                <a href="mailto:BCPINFO@ag.state.nv.us">BCPINFO@ag.state.nv.us</a>
              </p>



              <p><strong>CA:</strong> Under California law, we will not share
                information we collect about you nonaffiliated third parties, except
                as permitted by law. For example, we may share information with your
                consent, to service your accounts, or to provide rewards or benefits
                you are entitled to. We will limit sharing among our companies to
                the extent required by California law.</p>



              <p><strong>Telephone Communications:</strong> If you communicate with us
                via telephone, we may monitor and record the call.</p>



              <h6>UPDATED: December 2022</h6>



            </div><!-- .entry-content .clear -->



          </article><!-- #post-## -->

        </main><!-- #main -->
      </div>
      <!--  END OF PRIVACY POLICY  -->
      <!--  TERMS OF SERVICES BUSINESS  -->
      <h1><strong>Shifl Capital Terms of Service</strong></h1>
      <p>May 1, 2021</p>
      <p>
        Welcome to <a href="https://shifl.capital/" data-mce-href="https://shifl.capital/">https://shifl.capital</a>, a
        website of Shifl a
        New York S-corp corporation Shifl Capital. This page explains the terms by which you may use the financial
        services we make available to you on our Site or on our mobile application (collectively the “Services”).
      </p>
      <p>
        By submitting your application to obtain an account with us (“Account”) and to use the Services, you signify
        that
        you have read, understood, and agree to be bound by these Shifl Capital Terms of Service (“Agreement”), Shifl
        Capital Account Agreement, Shifl Capital Card Agreement, the Deposit Account Agreements of the banking service
        provider for your account, other applicable terms and conditions referenced and incorporated in this Agreement,
        and to the collection and use of your information as set forth in the Shifl Capital Privacy Policy. You also
        agree to receive all notices and other communications from us electronically. Shifl Capital reserves the right
        to make unilateral modifications to these terms and will provide notice of these changes by posting an updated
        version to our legal page.
      </p>
      <p>
        “Company” or “You” means the legal entity that is applying for or that has opened an Account to use the Services
        and the individual applying for the Account. If you are the individual applying for the Account for a business,
        you represent and warrant that you are an authorized representative of Company with the authority to bind
        Company to this Agreement, and that you agree to this Agreement on Company’s behalf.
      </p>
      <p>
        PLEASE READ THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THIS AGREEMENT CONTAINS A
        MANDATORY INDIVIDUAL ARBITRATION AND CLASS ACTION/JURY TRIAL WAIVER PROVISION THAT REQUIRES THE USE OF
        ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS.
      </p>
      <ol>
        <li>
          <strong> Scope of the Services; Use of the Services; Eligibility</strong>
          <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
            <li>
              <strong>Eligibility</strong>. This is a contract between you and Shifl Capital. You must read and
              agree to these terms before using the Services. If you do not agree, you may not apply for an
              Account or use the Services. You may only apply for an Account or use the Services for business
              purposes, if you are a legal entity formed and registered in the United States and can form a
              binding contract with Shifl Capital and only in compliance with this Agreement and all applicable
              local, state, national, and international laws, rules and regulations. Any use of or access to the
              Services for consumer or non-commercial purposes, by anyone under 18 is strictly prohibited and in
              violation of this Agreement. There may be other eligibility requirements applicable to specific
              Services.
            </li>
            <li>
              <strong>Scope of the Services</strong>. Your Account gives you access to various Services, including
              the demand deposit account (“Deposit Account”), the Shifl Capital Card, and other services as made
              available from time-to-time by Shifl Capital and by Evolve Bank &amp; Trust (our “Banking Services
              Provider”), and any other functionality that we may establish and maintain from time to time and in
              our sole discretion.
            </li>
            <li>
              <strong>Applying for an Account.</strong> You will need to provide Company information and certain
              personal information (collectively, “Company Information”), when you apply for an Account for
              business purposes. Company Information may include your registered business name and state of
              incorporation, the business address, ownership details, the nature of the business, and other
              business information we may request from time to time; the name, contact information, and date of
              birth of beneficial owners and certain other personnel, and other personal information; and a
              corporate registration certificate, proof of address, personal identification, and any other
              documentary information used to verify business and personal information. We provide Company
              Information to our Banking Services Provider and other third-party service providers to determine
              your eligibility for the Services. We rely on the accuracy of the Company Information you provide us
              when opening and maintaining your Account. We may deny your applications, suspend provision of the
              Services to you, or close your Account if Company Information is out of date, incomplete, or
              inaccurate. At any time during the term of this Agreement and as part of your use of the Services,
              we may require additional Company Information from you to verify your identity and the identity of
              your business, to obtain other information about your business, to open and maintain accounts and
              aspects of the Services, to assess your financial condition, and to evaluate the risks associated
              with your business. You expressly consent and authorize us to retrieve additional Company
              Information about Company and its owners or directors from our vendors and other third parties to
              solely to assess the history and risks of the business and to prevent fraud, including by obtaining
              information from credit reporting agencies and information bureaus, and you authorize and direct
              such third parties to compile and provide such information to us. You acknowledge that this may
              include names, addresses, credit history, and other data. You acknowledge that we may use Company
              Information to verify any other information you provide to us, and that any information we collect
              may affect our assessment of your overall risk to our business.
            </li>
            <li>
              <strong>Account Management and Security</strong>. You are solely responsible for the activity that
              occurs on your Account, and you must keep your Account password secure. We encourage you to use
              “strong” passwords (for recommendations on what constitutes a strong password, check NIST SP
              800-63B) with your Account. You must notify Shifl Capital immediately of any breach of security or
              unauthorized use of your Account. Shifl Capital will not be liable for any losses caused by any
              unauthorized use of your Account. We may suspend access to your Account if we believe that your
              Account has been compromised.
            </li>
            <li>
              <strong>Prohibitions</strong>. You may not use the Account or the Services (a) for any purpose that
              is unlawful or prohibited by this Agreement; (b) for any personal, family, household, or other use
              that is not related to Company’s business purpose in case of a business Account; (c) for the benefit
              of an individual, organization, or country identified on the United States Office of Foreign Assets
              Control’s Specially Designated Nationals List, or (d) for any third parties unaffiliated with
              Company.
            </li>
            <li>
              <strong>Acceptable Use.</strong> By registering for and using the Services, you are confirming that
              you will not use the Services (i) to accept payments in connection with any illegal, unauthorized,
              or not bona fide transactions; (ii) to handle, process, or transmit funds for any third party; or
              (iii) to conduct any of the restricted business activities or practices listed here:
            </li>
          </ol>
        </li>
      </ol>
      <ul>
        <li style="list-style-type: none;">
          <ul>
            <li style="list-style-type: none;">
              <ul>
                <li>Any illegal activity, goods, or services</li>
                <li>
                  Money and legal services (including financial institutions, money transmission, prepaid
                  cards, check cashing, wire transfers, money orders, currency exchanges or dealers, credit
                  counseling services, bill pay services, crowdfunding, insurance, bail bonds, or collections
                  agencies or collections attorneys)
                </li>
                <li>Buyer or membership clubs, including dues associated with such clubs</li>
                <li>Credit protection or identity theft protection services</li>
                <li>
                  Direct marketing or subscription offers or services or unauthorized multi-level marketing
                  businesses
                </li>
                <li>Infomercial sales</li>
                <li>
                  Internet/mail order/telephone order pharmacies or pharmacy referral services (where
                  fulfillment of medication is performed with an Internet or telephone consultation, absent a
                  physical visit with a physician including re-importation of pharmaceuticals from foreign
                  countries)
                </li>
                <li>Inbound or outbound telemarketers</li>
                <li>Rebate based businesses</li>
                <li>Up-sell merchants</li>
                <li>
                  Betting, including lottery tickets, casino gaming chips, Fantasy Football, off-track
                  betting, and wagers at races
                </li>
                <li>Virtual currency</li>
                <li>High-risk products and services, including telemarketing sales</li>
                <li>Service station merchants</li>
                <li>Automated fuel dispensers</li>
                <li>
                  Adult entertainment-oriented products or services (in any medium, including Internet,
                  telephone or printed material)
                </li>
                <li>
                  Sales of (i) firearms, firearm parts or hardware, and ammunition; or (ii) weapons and other
                  devices designed to cause physical injury
                </li>
                <li>Drug paraphernalia</li>
                <li>Occult materials</li>
                <li>Hate or harmful products</li>
                <li>Escort services</li>
                <li>Internet/mail order/telephone order of age restricted products (e.g., tobacco)</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        We may, at any time and without notice, suspend
        or terminate your Account or your access to any of the Services if you engage in activities that violate the
        letter or spirit of this section. Our determination of whether a violation of this section has occurred will be
        final and binding, and any action taken with respect to enforcing this section will be at our sole discretion.
        We may, at our discretion, permit businesses that conduct any of the activities above to use the Services with
        restrictions as determined by us. Shifl Capital may modify this section at any time by posting a revised version
        in this Agreement.
      </p>
      <p><strong>&nbsp;</strong></p>
      <ol start="2">
        <li>
          <strong> The Shifl Capital Services</strong>
          <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
            <li>
              <strong>Deposit Account.<br></strong> Your Deposit Account is a demand deposit account held with our
              Banking Services Provider and is subject to the terms of this Agreement and the additional terms of
              our Banking Services Provider. Your funds are fully insured by the Federal Deposit Insurance
              Corporation up to $250,000. Your Deposit Account will be used in connection with the provision of
              the other Services under this Agreement. You may also use your Deposit Account to the extent
              provided in the terms of our Banking Services Provider.<br><br>
            </li>
            <li>
              <strong>Shifl Capital Virtual and Physical Cards.<br></strong>Your Shifl Capital Account gives you
              access to virtual and physical cards (the “Shifl Capital Card” or “Shifl Capital Cards”) that you
              can use to make purchases for your business using funds in your Shifl Capital Deposit Account. You
              may not use the Shifl Capital Cards for any non-business purpose, such as for personal or household
              purchases, when issued in conjunction with your business Account.
              <ol data-mce-style="list-style-type: lower-roman;" style="list-style-type: lower-roman;">
                <li>
                  <p>
                    <strong>Requesting and Receiving Shifl Capital Cards.</strong> When you sign up for a
                    Shifl Capital Deposit Account for your business, you will be issued one physical Shifl
                    Capital Card. You may request additional virtual and physical Shifl Capital Cards for your
                    employees, contractors, or other people affiliated with your business.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Spending Limits, Payments, and Refunds.</strong> Spending on your Shifl Capital
                    Card(s) is limited by the available funds in your Deposit Account. Your Shifl Capital Card
                    transactions will be settled as they occur by debiting your Shifl Capital Deposit Account an
                    amount equal to each Shifl Capital Card transaction. Refunds to your Shifl Capital Card will
                    be applied to your Shifl Capital Deposit Account. If you use your Shifl Capital Card for
                    preauthorized, recurring, or subscription payments, you should regularly monitor the
                    available funds in your Deposit Account to ensure that funds are available to make those
                    payments. If funds are not available at the time a preauthorized, recurring or subscription
                    payment is attempted on your Shifl Capital Card, the transaction will ordinarily be
                    declined. We will generally decline transactions attempted on your Shifl Capital Card that
                    would overdraw your Deposit Account. If, however, we do not decline a transaction and that
                    transaction does overdraw your account, we may limit your ability to use other features or
                    spend additional funds from your Deposit Account until your Deposit Account has a positive
                    balance and the transaction that has overdrawn your account has been paid. For more
                    information related to the manner in which your Shifl Capital Card transactions are debited
                    and settled, refer to the Shifl Capital Card Agreement.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Disputes.</strong> If you have a dispute about a purchase on your Shifl Capital
                    Card that you cannot resolve with the merchant directly (a “Disputed Charge”), please
                    contact us at hello@shifl.com as soon as possible, and in all cases within 60 days of the
                    charge. We cannot help you with Disputed Charges you report to us more than 60 days after
                    the charge appears on your account. Disputed Charges will remain debited from your Deposit
                    Account while we determine the outcome of the dispute. We will resolve all disputes in a
                    commercially reasonable timeframe. If we determine you should be reimbursed, we will credit
                    the amount of the Disputed Charge back to your Deposit Account and you will not be charged
                    any fees. If we determine that the Disputed Charge was valid, your Deposit Account will not
                    be reimbursed, and you will be charged a fee as set forth in the Fee Schedule. You assign
                    and transfer to us any rights and claims, excluding tort claims, that you have against a
                    merchant for any Disputed Charge that is fully or partially credited to your Deposit
                    Account. We may assign and transfer the rights you assign and transfer to us under this
                    provision to the Shifl Capital Card Issuer.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Records, Fees, and Communication.</strong> All transaction records and periodic
                    statements may be found in your dashboard for your Account. All fees associated with the
                    Shifl Capital Cards may be found in the Shifl Capital Card Agreement.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Other Shifl Capital Card Terms. </strong>Shifl Capital Cards are issued by Evolve
                    Bank &amp; Trust (the “Issuer.” ) You agree to the applicable Evolve Bank &amp; Trust terms
                    and conditions. You agree that any future changes to the relevant Evolve Bank &amp; Trust
                    terms and conditions will apply to your Shifl Capital Card, whether or not Shifl Capital or
                    Evolve Bank &amp; Trust alerts you to those changes to the Evolve terms and conditions.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                <strong>Mobile Remote Deposit Capture.</strong> You may remotely deposit paper checks into your
                Deposit Account through the Shifl Capital app by creating a Mobile Deposit. When making a Mobile
                Deposit, you are also subject to the Evolve Bank &amp; Trust Deposit Account Agreement &amp; Service
                Terms, including the Funds Availability Disclosure section of those terms. A “Mobile Deposit” is the
                information that is transmitted to us allowing for the deposit, processing, and collection of the
                item deposited. You may only deposit items made payable to you as the business entity that is named
                on the Deposit Account. We reserve the right to reject any Mobile Deposit for any reason.
              </p>
              <ol style="list-style-type: lower-roman;" data-mce-style="list-style-type: lower-roman;">
                <li>
                  <p>
                    <strong>Depositing your Check.</strong> You are responsible for creating an accurate
                    Mobile Deposit. To deposit your check, you must endorse the check, enter the correct amount
                    of the check (you are liable for any errors you make during the deposit process), create a
                    check image using the camera on your mobile device to take a picture of the front and back
                    of your check. You may only deposit eligible items through the mobile remote deposit capture
                    service. To mitigate the risk of fraud or the presentment of duplicate items, you agree to
                    mark on the original paper check that the item has been deposited electronically. You also
                    agree to retain all checks deposited via mobile remote deposit capture for at least two
                    business days after the deposit is made and to destroy all deposited checks as soon as
                    reasonably possible, and in no event later than 30 days after the deposit.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Returned Items.</strong> You acknowledge and agree that we may charge you any
                    fees associated with a returned item if a check is returned to us for any reason.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Representations and Warranties.</strong> In addition to any other representations
                    and warranties applicable to your Deposit Account, with respect to each Mobile Deposit, you
                    make any representation or warranty that would have applied had you deposited the original
                    paper check. This includes representations and warranties we make on your behalf when we
                    transfer, present, or originate the Mobile Deposit created from your check image. These
                    representations and warranties include but are not limited to, that: (a) the transmissions
                    contain accurate images of the front and back of the original checks; (b) the transmissions
                    contain all necessary endorsements; and (c) no depository bank, drawee, drawer, or endorser
                    will be asked to make a payment based on an item that it has already paid.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                <strong>Digital Checks.</strong> You may send digital checks to payees from your Deposit Account.
                Payees will be able to print the digital check or present the digital check via email or other
                electronic means to the payee’s bank for deposit. By creating and authorizing the issuance of a
                digital check or remotely created check (“RCC”), you authorize Shifl Capital to honor the RCC in the
                amount and to the payee stated on the RCC even though the RCCs do not contain your signature. You
                authorize Shifl Capital to debit your Account for any claim or return based upon an unauthorized RCC
                and you agree to indemnify and hold Shifl Capital harmless from and against any claims, liabilities,
                costs and expenses (including attorney’s fees) resulting directly or indirectly from any breach of
                the foregoing warranty.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <ol start="3">
        <li>
          <strong>Fees and Payment.</strong>
          <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
            <li>
              <strong>Payment Method.</strong> You must keep a valid payment method on file with us to pay for all
              fees owed. We will debit fees from your Deposit Account or another payment method that you
              authorize. Shifl Capital will charge the payment method that you authorize for all applicable fees
              until the Services are terminated, and any and all outstanding fees have been paid in full. If we
              are unable to process payment of fees using your payment method on file, we will make a second
              attempt to process the payment using the payment method on file within three (3) days. If the second
              attempt is not successful, we will notify you, and may suspend and revoke access to the Services. If
              the Services are suspended, your Services will be reactivated upon payment of any amounts owed. If
              the outstanding fees remain unpaid for sixty (60) days following the date of suspension, then Wise
              reserves the right to terminate your Account. You may change your payment method through your
              account settings.
            </li>
            <li>
              <strong>Applicable Fees.</strong> Your use of the Services is subject to the fees on the Fee
              Schedule, which may be updated from time-to-time at our discretion. For fees owed under this
              Agreement, we will automatically charge you using the payment method you have on file with us and by
              agreeing to this Agreement, you authorize us to do this. You will be charged via invoice each month
              on your billing date (“Billing Date”) for all outstanding fees that have not previously been charged
              or collected. All fees are exclusive of applicable taxes. You are responsible for all applicable
              taxes that arise from or as a result of your use of the Services.
            </li>
            <li>
              <strong>Collection and Right to Set-Off</strong>. You agree to pay all amounts owed to us on demand.
              Your failure to pay amounts owed to us under this Agreement is a breach and you will be liable for
              any costs we incur during collection in addition to the amount you owe. Collection costs may include
              attorneys’ fees, costs of arbitration or court proceeding, collection agency fees, any applicable
              interest, and any other related cost. We may collect amounts you owe to us under this Agreement by
              deducting any amounts you owe to use from your Deposit Account. You grant us a security interest in
              and right to set off against the Deposit Account.
            </li>
          </ol>
        </li>
      </ol>
      <p><br></p>
      <ol start="4">
        <li>
          <strong> Proprietary Rights</strong>
          <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
            <li>
              <strong>Limited License</strong>. Subject to the terms and conditions of this Agreement, you are
              hereby granted a non-exclusive, limited, non-transferable, freely revocable license to use the
              Services as permitted by the features of the Services. Shifl Capital reserves all rights not
              expressly granted herein in the Services and the Shifl Capital IP (as defined below). Shifl Capital
              may terminate this license at any time for any reason or no reason. The Services and all materials
              therein or transferred thereby, including, without limitation, all software, images, text, graphics,
              illustrations, logos, patents, trademarks, service marks, copyrights, photographs, audio, videos,
              music, and other content available on the Site (the “Shifl Capital IP”), and all Intellectual
              Property Rights related thereto, are the exclusive property of Shifl Capital and its licensors.
              Except as explicitly provided herein, nothing in this Agreement shall be deemed to create a license
              in or under any such Intellectual Property Rights, and you agree not to sell, license, rent, modify,
              distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or
              create derivative works from any Shifl Capital IP. Use of the Shifl Capital IP for any purpose not
              expressly permitted by this Agreement is strictly prohibited. For the purposes of this Agreement,
              “Intellectual Property Rights” means all patent rights, copyright rights, mask work rights, moral
              rights, rights of publicity, trademark, trade dress and service mark rights, goodwill, trade secret
              rights and other intellectual property rights as may now exist or hereafter come into existence, and
              all applications therefore and registrations, renewals and extensions thereof, under the laws of any
              state, country, territory or other jurisdiction.
            </li>
            <li>
              <strong>Feedback</strong>. You may choose to, or we may invite you to, submit comments or ideas
              about the Services, including without limitation about how to improve the Services or our products
              (“Feedback”). By submitting any Feedback, you agree that your disclosure is gratuitous, unsolicited
              and without restriction, and will not place Shifl Capital under any fiduciary or other obligation,
              and that we are free to use your Feedback without any additional compensation to you, or to disclose
              your Feedback on a non-confidential basis or otherwise to anyone. You further acknowledge that, by
              acceptance of your submission, Shifl Capital does not waive any rights to use similar or related
              ideas previously known to Shifl Capital, or developed by its employees, or obtained from sources
              other than you.
            </li>
            <li>
              <strong>Copyright Complaints</strong>. If you believe that any material on the Site infringes upon
              any copyright which you own or control, you may send a written notification of such infringement to
              our Designated Agent as set forth below:<br>343 spook rock rd suffern ny 10901<br><a
                href="mailto:hello@shifl.com">hello@shifl.com</a><br>To meet the notice restrictions under
              the Digital Millennium Copyright Act, the notification must be a written communication that includes
              the following:
            </li>
          </ol>
        </li>
      </ol>
      <ul>
        <li style="list-style-type: none;">
          <ul>
            <li style="list-style-type: none;">
              <ul style="">
                <li>
                  A physical or electronic signature of a person authorized to act on behalf of the owner of
                  an exclusive right that is allegedly infringed;
                </li>
                <li>
                  Identification of the copyrighted work claimed to have been infringed, or, if multiple
                  copyrighted works at a single online site are covered by a single notification, a
                  representative list of such works at that site;
                </li>
                <li>
                  Identification of the material that is claimed to be infringing or to be the subject of
                  infringing activity and that is to be removed or access to which is to be disabled, and
                  information reasonably sufficient to permit us to locate the material;
                </li>
                <li>
                  Information reasonably sufficient to permit us to contact the complaining party, such as an
                  address, telephone number and, if available, an electronic mail address at which the
                  complaining party may be contacted;
                </li>
                <li>
                  A statement that the complaining party has a good-faith belief that use of the material in
                  the manner complained of is not authorized by the copyright owner, its agent or the law; and
                </li>
                <li>
                  A statement that the information in the notification is accurate, and under penalty of
                  perjury, that the complaining party is authorized to act on behalf of the owner of an
                  exclusive right that is allegedly infringed.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ol start="5">
        <li>
          <strong> Site Data and Privacy<br></strong>You understand that by using the Services you consent to the
          collection, use and disclosure of Company Information and aggregate data we collect from your use of the
          Services (“Site Data”) as set forth in our Privacy Policy, and to have Company Information and Site Data
          collected, used, transferred to and processed in the United States. You grant us a worldwide, irrevocable
          license to use, modify, distribute, copy, and create derivative works from Site Data for the purposes
          identified in this Agreement. Shifl Capital uses commercially reasonable physical, managerial, and technical
          safeguards to preserve the integrity and security of your Company Information and implement your privacy
          settings. However, we cannot guarantee that unauthorized third parties will never be able to defeat our
          security measures or use Company Information for improper purposes. You acknowledge that you provide Company
          Information at your own risk. Your use of the Deposit Account and other financial services is subject to the
          privacy policies of our Banking Services Provider.
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="6">
        <li>
          <strong> Third-Party Links and Information<br></strong>The Services may contain links to third-party
          materials that are not owned or controlled by Shifl Capital. Shifl Capital does not endorse or assume any
          responsibility for any such third-party services, information, materials, products, or Services. If you
          access a third-party website, application or service from the Services, you do so at your own risk, and you
          understand that this Agreement and Shifl Capital’s Privacy Policy do not apply to your use of such
          third-party services. You expressly relieve Shifl Capital from any and all liability arising from your use
          of any third-party websites, applications, services, or content. Additionally, your dealings with or
          participation in promotions of advertisers found on the Services, including payment and delivery of goods,
          and any other terms (such as warranties) are solely between you and such advertisers. You agree that Shifl
          Capital shall not be responsible for any loss or damage of any sort relating to your dealings with such
          advertisers.
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="7">
        <li>
          <strong> Indemnification<br></strong>You agree to defend, indemnify and hold harmless Shifl Capital and its
          subsidiaries, agents, licensors, managers, and other affiliated companies, and their employees, contractors,
          agents, officers and directors, our Banking Services Provider, and our third-party service providers, from
          and against any and all third-party claims, damages, obligations, losses, liabilities, costs or debt, and
          expenses (including but not limited to attorney’s fees) arising out of, related to, or resulting from : (a)
          your use of and access to the Services, including any data or content transmitted or received by you; (b)
          your violation of any term of this Agreement, including without limitation your breach of any of the
          representations and warranties above; (c) your violation of any third-party right, including without
          limitation any right of privacy or Intellectual Property Rights; (d) your violation of any applicable law,
          rule or regulation; (e) your intentional misconduct; or (f) any other party’s access and use of the Services
          with your unique username, password or other appropriate security code.
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="8">
        <li>
          <strong> No Warranty<br></strong>THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF
          THE SERVICES IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES ARE
          PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. NO ADVICE OR
          INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM SHIFL CAPITAL OR THROUGH THE SERVICES WILL CREATE
          ANY WARRANTY NOT EXPRESSLY STATED HEREIN. WITHOUT LIMITING THE FOREGOING, SHIFL CAPITAL ITS SUBSIDIARIES,
          ITS AFFILIATES, AND ITS LICENSORS DO NOT WARRANT THAT THE INFORMATION PROVIDED TO YOUR THROUGH THE SERVICES
          IS ACCURATE, RELIABLE OR CORRECT; THAT THE SERVICES WILL MEET YOUR REQUIREMENTS; THAT THE SERVICES WILL BE
          AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE
          CORRECTED; OR THAT THE SERVICES ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ANY CONTENT DOWNLOADED OR
          OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY
          RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH
          DOWNLOAD OR YOUR USE OF THE SERVICES.<br><br>SHIFL CAPITAL DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
          RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES OR ANY
          HYPERLINKED WEBSITE OR SERVICE, AND SHIFL CAPITAL WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY
          TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="9">
        <li>
          <strong> Limitation of Liability<br></strong>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
          SHALL SHIFL CAPITAL, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS OR LICENSORS BE LIABLE FOR ANY
          INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION
          DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR RELATING TO
          THE USE OF, OR INABILITY TO USE, THE SERVICES. UNDER NO CIRCUMSTANCES WILL SHIFL CAPITAL BE RESPONSIBLE FOR
          ANY DAMAGE, LOSS OR INJURY RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE
          SERVICES OR YOUR ACCOUNT OR THE INFORMATION CONTAINED THEREIN.<br><br>TO THE MAXIMUM EXTENT PERMITTED BY
          APPLICABLE LAW, SHIFL CAPITAL ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR
          INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
          YOUR ACCESS TO OR USE OF OUR SERVICES; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR
          ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
          FROM THE SERVICES; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH
          OUR SERVICES BY ANY THIRD PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE
          INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
          THROUGH THE SERVICES; AND/OR (VII) USER CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY
          THIRD PARTY. IN NO EVENT SHALL SHIFL CAPITAL, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR
          LICENSORS BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS
          IN AN AMOUNT EXCEEDING $100.00.<br><br>THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED
          LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OROLID ANY OTHER BASIS, EVEN IF SHIFL
          CAPITAL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE FOREGOING LIMITATION OF LIABILITY SHALL
          APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="10">
        <li>
          <strong> Term and Termination<br><br></strong>This Agreement is effective when you submit your application
          to us and continues until terminated by either you or us, or in accordance with the Banking Services
          Provider’s agreements, third-party payment processor agreements, or as otherwise set forth in this
          Agreement. You may terminate this Agreement by paying all amounts you owe and providing notice to us; except
          that you will still be responsible for any charges, fees, fines, and other losses caused by your action or
          inaction prior to terminating this Agreement. We may terminate this Agreement, or suspend your Account for
          any reason, by providing you notice, or we may terminate this Agreement immediately in the event you breach
          or violate any of the terms of this Agreement, as determined in the sole discretion of Shifl Capital.
        </li>
      </ol>
      <p><br></p>
      <ol start="11">
        <li>
          <strong> Governing Law, Arbitration, and Class Action/Jury Trial Waiver</strong>
          <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
            <li>
              <strong>Governing Law</strong>. You agree that: (i) the Services shall be deemed solely based in New
              York except as may be set forth in the Bank Provider Agreements; and (ii) the Services shall be
              deemed a passive one that does not give rise to personal jurisdiction over us, either specific or
              general, in jurisdictions other than New York. This Agreement shall be governed by the internal
              substantive laws of the State of New York, without respect to its conflict of laws principles. The
              parties acknowledge that this Agreement evidences a transaction involving interstate commerce.
              Notwithstanding the preceding sentences with respect to the substantive law, any arbitration
              conducted pursuant to the terms of this Agreement shall be governed by the Federal Arbitration Act
              (9 U.S.C. §§ 1-16). You agree to submit to the personal jurisdiction of the federal and state courts
              located in Rockland county NY for any actions for which we retain the right to seek injunctive or
              other equitable relief in a court of competent jurisdiction to prevent the actual or threatened
              infringement, misappropriation or violation of a our copyrights, trademarks, trade secrets, patents,
              or other intellectual property or proprietary rights, as set forth in the Arbitration provision
              below, including any provisional relief required to prevent irreparable harm. Subject to Section
              11.B, you agree that the federal or state courts located in Rockland county NY is the proper forum
              for any appeals of an arbitration award or for trial court proceedings in the event that the
              Arbitration provision below is found to be unenforceable.<br><br>
            </li>
            <li>
              <strong>Arbitration</strong>. READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE PARTIES TO
              ARBITRATE THEIR DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM SHIFL CAPITAL. For
              any dispute with Shifl Capital, you agree to first contact us at 343 spook rock rd suffern ny 10901
              and attempt to resolve the dispute with us informally. In the unlikely event that Shifl Capital has
              not been able to resolve a dispute it has with you after sixty (60) days, we each agree to resolve
              any claim, dispute, or controversy (excluding any claims arising from protection of Intellectual
              Property Rights, breach of Confidential Information, which will be resolved through litigation in
              accordance with Section 11.A, or for injunctive or other equitable relief as provided below) arising
              out of or in connection with or relating to this Agreement, or the breach or alleged breach thereof
              (collectively, “Claims”), by binding arbitration by JAMS. Claims with amounts claimed greater than
              $250,000 will apply the JAMS Comprehensive Arbitration Rules and Procedures; and Disputes with
              amounts claimed less than or equal to $250,000 will apply the JAMS Streamlined Arbitration Rules.
              The arbitration will be conducted in Rockland county NY, unless you and Shifl Capital agree
              otherwise. If JAMS cannot administer the Claim, either party may petition the US district Court of
              New York to appoint an arbitrator. Each party will be responsible for paying any JAMS filing,
              administrative and arbitrator fees in accordance with JAMS rules, and the award rendered by the
              arbitrator shall include costs of arbitration, reasonable attorneys’ fees and reasonable costs for
              expert and other witnesses. Any judgment on the award rendered by the arbitrator may be entered in
              any court of competent jurisdiction. Either party may commence arbitration by providing a written
              demand for arbitration to JAMS and the other party detailing the subject of the Claim and the relief
              requested. Each party will continue to perform its obligations under this Agreement unless that
              obligation or the amount (to the extent in dispute) is itself the subject of the Claim. Nothing in
              this Section shall be deemed as preventing either party from seeking injunctive or other equitable
              relief from the courts as necessary to prevent the actual or threatened infringement,
              misappropriation, or violation of our data security, Intellectual Property Rights or other
              proprietary rights. Proceedings and information related to them will be maintained as confidential,
              including the nature and details of the Claim, evidence produced, testimony given, and the outcome
              of the Claim, unless such information was already in the public domain or was independently
              obtained. Company and Shifl Capital, and all witnesses, advisors, and arbitrators will only share
              such information as necessary to prepare for or conduct arbitration or other legal proceeding, or
              enforcement of the outcome, unless additional disclosure is required by law.<br><br>
            </li>
            <li>
              <strong>Class Action/Jury Trial Waiver</strong>. With respect to all persons and entities,
              regardless of whether they have obtained an account or used the services for personal, commercial or
              other purposes, all Claims must be brought in the parties’ individual capacity, and not as a
              plaintiff or class member in any purported class action, collective action, private attorney general
              action or other representative proceeding. This waiver applies to class arbitration, and, unless we
              agree otherwise, the arbitrator may not consolidate more than one person’s claims. You agree that,
              by entering into this agreement, you and Shifl Capital are each waiving the right to a trial by jury
              or to participate in a class action, collective action, private attorney general action, or other
              representative proceeding of any kind.
            </li>
          </ol>
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="12">
        <li>
          <strong> Communication<br></strong>By providing us with a telephone number for a mobile device, including a
          number that you later convert to a mobile device number, you are expressly consenting to receiving
          communications—including but not limited to prerecorded or artificial voice message calls, text messages,
          and calls made by an automatic telephone dialing system—from us and our affiliates and agents at that
          number, about any product or services offered by Shifl Capital. This express consent applies to each such
          telephone number that you provide to us now or in the future. Calls and messages may incur access fees from
          your mobile services provider. You understand that you need not provide this consent as a condition of
          obtaining goods or services from Shifl Capital, and that you may decline to provide or revoke your consent
          at any time by emailing hello@shifl.com or by any other method that reasonably ensures we receive your
          revocation.<br><br>By providing us with a telephone number, you agree that Shifl Capital may record any
          telephone conversation with you (or any another individual) during any telephone call to or from that number
          for training purposes, whether or not the call was initiated by you, and whether or Shifl Capital disclosed
          the fact that the call was recorded during the call.
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="13">
        <li>
          <strong> General</strong>
          <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
            <li>
              <strong>Assignment</strong>. This Agreement, and any rights and licenses granted hereunder, may not
              be transferred or assigned by you, but may be assigned by Shifl Capital without restriction. Any
              attempted transfer or assignment in violation hereof shall be null and void.<br><br>
            </li>
            <li>
              <strong>Notification Procedures and Changes to the Agreement.</strong> You consent to us providing
              notices to you under this Agreement electronically and understand that this consent has the same
              legal effect as a physical signature. We will provide notices to you electronically through your
              Account, and via text or SMS to the phone numbers provided to us by you. If you sign up to receive
              certain Shifl Capital notifications or information via text or SMS, you may incur additional charges
              from your wireless provider for these notices. You agree that you are solely responsible for any
              such charges. Notices affecting the terms of this Agreement will be sent to you and are considered
              received 24 hours after they are sent. You understand that you may not use the Services unless you
              consent to receive notices from us electronically. You may only withdraw consent to receive notices
              electronically by closing your Account. Notices may include alerts about the Services, your Account,
              and your Deposit Account and may provide you the ability to respond with information about Deposit
              Account transactions or your Account. You may disable notification preferences to limit the use of
              certain Service features or to decrease financial risks to the Company. You are required to maintain
              a regularly updated web browser, and computer and mobile device operating systems to receive notices
              correctly. You will be responsible for all costs imposed by internet or mobile service providers for
              sending or receiving notices electronically. Contact us immediately at 343 spook rock rd suffern ny
              10901 if you are having trouble receiving notices from us.<br><br>
            </li>
            <li>
              <strong>Entire Agreement/Severability</strong>. This Agreement, together with any amendments and any
              additional agreements you may enter into with Shifl Capital in connection with the Services, shall
              constitute the entire agreement between you and Shifl Capital concerning your Account and the
              Services. If any provision of this Agreement is deemed invalid by a court of competent jurisdiction,
              the invalidity of such provision shall not affect the validity of the remaining provisions of this
              Agreement, which shall remain in full force and effect, except that in the event of unenforceability
              of the universal Class Action/Jury Trial Waiver, the entire arbitration agreement shall be
              unenforceable.<br><br>
            </li>
            <li>
              <strong>No Waiver</strong>. No waiver of any term of this Agreement shall be deemed a further or
              continuing waiver of such term or any other term, and Shifl Capital’s failure to assert any right or
              provision under this Agreement shall not constitute a waiver of such right or provision.<br><br>
            </li>
            <li>
              <strong>Legal Orders</strong>. We may respond to and comply with any subpoenas, warrants, liens, or
              any other legal order we receive related to your use of the Services. We are not responsible to you
              for any losses you incur due to our response to such legal order. We may hold funds or provide
              information as required by the issuer of the legal order or take any other actions we believe are
              required of us under legal orders. Where permitted, we will provide you reasonable notice that we
              have received such an order.<br><br>
            </li>
            <li>
              <strong>Survival</strong>. Any and all provisions of this Agreement reasonably giving rise to
              continued obligations of the parties will survive termination of this Agreement.<br><br>
            </li>
            <li>
              <strong>Relationship</strong>. No joint venture, partnership, employment, or agency relationship
              exists between you and Shifl Capital as a result of this Agreement or your use of the Site.
            </li>
          </ol>
        </li>
      </ol>
      <!--  END OF TERMS OF SERVICES BUSINESS  -->
      <!--  TERMS OF SERVICES CONSUMER  -->
      <h1><strong>Shifl Capital Terms of Service</strong></h1>
      <p>August 25, 2021</p>
      <p><br></p>
      <p>
        Welcome to <a href="https://shifl.capital/" data-mce-href="https://shifl.capital/">https://shifl.capital</a>, a
        website of Shifl. a
        New York corporation, Shifl Capital. This page explains the terms by which you may use the financial services we
        make available to you on our Site or on our mobile application (collectively the “Services”).
      </p>
      <p>
        By submitting your application to obtain an account with us (“Account”) and to use the Services, you signify
        that
        you have read, understood, and agree to be bound by these Shifl Capital Terms of Service (“Agreement”), Shifl
        Capital Account Agreement, the Deposit Account Agreements of the banking service provider for your account,
        other applicable terms and conditions referenced and incorporated in this Agreement, and to the collection and
        use of your information as set forth in the Shifl Capital Privacy Policy. You also agree to receive all notices
        and other communications from us electronically. Shifl Capital reserves the right to make unilateral
        modifications to these terms and will provide notice of these changes by posting an updated version to our legal
        page.
      </p>
      <p>“You” means the individual that is applying for or that has opened an Account to use the Services.</p>
      <p>
        PLEASE READ THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THIS AGREEMENT CONTAINS A
        MANDATORY INDIVIDUAL ARBITRATION AND CLASS ACTION/JURY TRIAL WAIVER PROVISION THAT REQUIRES THE USE OF
        ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS.
      </p>
      <p><strong>&nbsp;</strong></p>
      <ol>
        <li>
          <strong> Scope of the Services; Use of the Services; Eligibility</strong>
          <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
            <li>
              <strong>Eligibility</strong>. This is a contract between you and Shifl Capital. You must read and
              agree to these terms before using the Services. If you do not agree, you may not apply for an
              Account or use the Services. You may only apply for an Account or use the Services for personal
              purposes. By applying for an Account or using the Services you are forming a binding contract with
              Shifl Capital in compliance with this Agreement and all applicable local, state, national, and
              international laws, rules and regulations. There may be other eligibility requirements applicable to
              specific Services.
            </li>
            <li>
              <strong>Scope of the Services</strong>. Your Account gives you access to various Services, including
              the demand deposit account (“Deposit Account”), the Shifl Capital Card, and other services as made
              available from time-to-time by Shifl Capital and by Evolve Bank &amp; Trust (our “Banking Services
              Provider”), and any other functionality that we may establish and maintain from time to time and in
              our sole discretion.
            </li>
            <li>
              <strong>Applying for an Account.</strong> You will need to provide personal information(“Personal
              Information”), when you apply for an Account for personal purposes. Personal Information may include
              your name, contact information, date of birth and certain other personal information; proof of
              address, personal identification, and any other documentary information used to verify your personal
              information. We provide Personal Information to our Banking Services Provider and other third-party
              service providers to determine your eligibility for the Services. We rely on the accuracy of the
              Personal Information you provide us when opening and maintaining your Account. We may deny your
              applications, suspend provision of the Services to you, or close your Account if Personal
              Information is out of date, incomplete, or inaccurate. At any time during the term of this Agreement
              and as part of your use of the Services, we may require additional Personal Information from you to
              verify your identity, to open and maintain accounts and aspects of the Services, and to assess your
              financial condition.&nbsp; You expressly consent and authorize us to retrieve additional Personal
              Information about you from our vendors and other third parties solely to assess the history and
              risks and to prevent fraud, including by obtaining information from credit reporting agencies and
              information bureaus, and you authorize and direct such third parties to compile and provide such
              information to us. You acknowledge that this may include names, addresses, credit history, and other
              data. You acknowledge that we may use Personal Information to verify any other information you
              provide to us, and that any information we collect may affect our assessment of your overall risk to
              our business.
            </li>
            <li>
              <strong>Account Management and Security</strong>. You are solely responsible for the activity that
              occurs on your Account, and you must keep your Account password secure. We encourage you to use
              “strong” passwords (for recommendations on what constitutes a strong password, check NIST SP
              800-63B) with your Account. You must notify Shifl Capital immediately of any breach of security or
              unauthorized use of your Account. Shifl Capital will not be liable for any losses caused by any
              unauthorized use of your Account. We may suspend access to your Account if we believe that your
              Account has been compromised.
            </li>
            <li>
              <strong>Prohibitions</strong>. You may not use the Account or the Services (a) for any purpose that
              is unlawful or prohibited by this Agreement; (b) business purposes; (c) for the benefit of an
              individual, organization, or country identified on the United States Office of Foreign Assets
              Control’s Specially Designated Nationals List.
            </li>
            <li>
              <strong>Acceptable Use.</strong> By registering for and using the Services, you are confirming that
              you will not use the Services (i) to accept payments in connection with any illegal, unauthorized,
              or not bona fide transactions; (ii) to handle, process, or transmit funds for any third party.We
              may, at any time and without notice, suspend or terminate your Account or your access to any of the
              Services if you engage in activities that violate the letter or spirit of this section. Our
              determination of whether a violation of this section has occurred will be final and binding, and any
              action taken with respect to enforcing this section will be at our sole discretion. Shifl Capital
              may modify this section at any time by posting a revised version in this Agreement.
            </li>
          </ol>
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="2">
        <li>
          <strong> The Shifl Capital Services</strong>
          <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
            <li>
              <strong>Deposit Account.</strong> Your Deposit Account is a demand deposit account held with our
              Banking Services Provider and is subject to the terms of this Agreement and the additional terms of
              our Banking Services Provider. Your funds are fully insured by the Federal Deposit Insurance
              Corporation up to $250,000. Your Deposit Account will be used in connection with the provision of
              the other Services under this Agreement. You may also use your Deposit Account to the extent
              provided in the terms of our Banking Services Provider.
            </li>
            <li>
              <strong>Shifl Capital Virtual and Physical Cards.</strong>Your Shifl Capital Account gives you
              access to virtual and physical cards (the “Shifl Capital Card” or “Shifl Capital Cards”) that you
              can use to make purchases for yourself using funds in your Shifl Capital Deposit Account. Your Shifl
              Capital Card must only be used for any non-business purpose, such as for personal or household
              purchases, when issued in conjunction with your Account.
              <ul>
                <li>
                  <p>
                    <strong>Requesting and Receiving Shifl Capital Cards.</strong> When you sign up for a
                    Shifl Capital Deposit Account, you will be issued one physical Shifl Capital Card.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Spending Limits, Payments, and Refunds.</strong> Spending on your Shifl Capital
                    Card(s) is limited by the available funds in your Deposit Account. Your Shifl Capital Card
                    transactions will be settled as they occur by debiting your Shifl Capital Deposit Account an
                    amount equal to each Shifl Capital Card transaction. Refunds to your Shifl Capital Card will
                    be applied to your Shifl Capital Deposit Account. If you use your Shifl Capital Card for
                    preauthorized, recurring, or subscription payments, you should regularly monitor the
                    available funds in your Deposit Account to ensure that funds are available to make those
                    payments. If funds are not available at the time a preauthorized, recurring or subscription
                    payment is attempted on your Shifl Capital Card, the transaction will ordinarily be
                    declined. We will generally decline transactions attempted on your Shifl Capital Card that
                    would overdraw your Deposit Account. If, however, we do not decline a transaction and that
                    transaction does overdraw your account, we may limit your ability to use other features or
                    spend additional funds from your Deposit Account until your Deposit Account has a positive
                    balance and the transaction that has overdrawn your account has been paid. For more
                    information related to the manner in which your Shifl Capital Card transactions are debited
                    and settled, refer to the Shifl Capital Card Agreement.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Disputes.</strong> If you have a dispute about a purchase on your Shifl Capital
                    Card that you cannot resolve with the merchant directly (a “Disputed Charge”), please
                    contact us at hello@shifl.com as soon as possible, and in all cases within 60 days of the
                    charge. We cannot help you with Disputed Charges you report to us more than 60 days after
                    the charge appears on your account. Disputed Charges will remain debited from your Deposit
                    Account while we determine the outcome of the dispute. We will resolve all disputes in a
                    commercially reasonable timeframe. If we determine you should be reimbursed, we will credit
                    the amount of the Disputed Charge back to your Deposit Account and you will not be charged
                    any fees. If we determine that the Disputed Charge was valid, your Deposit Account will not
                    be reimbursed, and you will be charged a fee as set forth in the Fee Schedule. You assign
                    and transfer to us any rights and claims, excluding tort claims, that you have against a
                    merchant for any Disputed Charge that is fully or partially credited to your Deposit
                    Account. We may assign and transfer the rights you assign and transfer to us under this
                    provision to the Shifl Capital Card Issuer.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Records, Fees, and Communication.</strong> All transaction records and periodic
                    statements may be found in your dashboard for your Account. All fees associated with the
                    Shifl Capital Cards may be found in the Shifl Capital Card Agreement.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Other Shifl Capital Card Terms. </strong>Shifl Capital Cards are issued by Evolve
                    Bank &amp; Trust (the “Issuer.” ) You agree to the applicable Evolve Bank &amp; Trust terms
                    and conditions. You agree that any future changes to the relevant Evolve Bank &amp; Trust
                    terms and conditions will apply to your Shifl Capital Card, whether or not Shifl Capital or
                    Evolve Bank &amp; Trust alerts you to those changes to the Evolve terms and conditions.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Mobile Remote Deposit Capture.</strong> You may remotely deposit paper checks into your
                Deposit Account through the Shifl Capital app by creating a Mobile Deposit. When making a Mobile
                Deposit, you are also subject to the Evolve Bank &amp; Trust Deposit Account Agreement &amp; Service
                Terms, including the Funds Availability Disclosure section of those terms. A “Mobile Deposit” is the
                information that is transmitted to us allowing for the deposit, processing, and collection of the
                item deposited. You may only deposit items made payable to you. We reserve the right to reject any
                Mobile Deposit for any reason.
              </p>
              <ul>
                <li>
                  <strong>Depositing your Check.</strong> You are responsible for creating an accurate Mobile
                  Deposit. To deposit your check, you must endorse the check, enter the correct amount of the
                  check (you are liable for any errors you make during the deposit process), create a check
                  image using the camera on your mobile device to take a picture of the front and back of your
                  check. You may only deposit eligible items through the mobile remote deposit capture
                  service. To mitigate the risk of fraud or the presentment of duplicate items, you agree to
                  mark on the original paper check that the item has been deposited electronically. You also
                  agree to retain all checks deposited via mobile remote deposit capture for at least two
                  business days after the deposit is made and to destroy all deposited checks as soon as
                  reasonably possible, and in no event later than 30 days after the deposit.
                </li>
                <li>
                  <strong>Returned Items.</strong> You acknowledge and agree that we may charge you any fees
                  associated with a returned item if a check is returned to us for any reason.
                </li>
                <li>
                  <strong>Representations and Warranties.</strong> In addition to any other representations
                  and warranties applicable to your Deposit Account, with respect to each Mobile Deposit, you
                  make any representation or warranty that would have applied had you deposited the original
                  paper check. This includes representations and warranties we make on your behalf when we
                  transfer, present, or originate the Mobile Deposit created from your check image. These
                  representations and warranties include but are not limited to, that: (a) the transmissions
                  contain accurate images of the front and back of the original checks; (b) the transmissions
                  contain all necessary endorsements; and (c) no depository bank, drawee, drawer, or endorser
                  will be asked to make a payment based on an item that it has already paid.
                </li>
              </ul>
            </li>
            <li>
              <strong>Digital Checks.</strong> You may send digital checks to payees from your Deposit Account.
              Payees will be able to print the digital check or present the digital check via email or other
              electronic means to the payee’s bank for deposit. By creating and authorizing the issuance of a
              digital check or remotely created check (“RCC”), you authorize Shifl Capital to honor the RCC in the
              amount and to the payee stated on the RCC even though the RCCs do not contain your signature. You
              authorize Shifl Capital to debit your Account for any claim or return based upon an unauthorized RCC
              and you agree to indemnify and hold Shifl Capital harmless from and against any claims, liabilities,
              costs and expenses (including attorney’s fees) resulting directly or indirectly from any breach of
              the foregoing warranty.
            </li>
          </ol>
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="3">
        <li>
          <strong>Fees and Payment.</strong>
          <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
            <li>
              <strong>Payment Method.</strong> You must keep a valid payment method on file with us to pay for all
              fees owed. We will debit fees from your Deposit Account or another payment method that you
              authorize. Shifl Capital will charge the payment method that you authorize for all applicable fees
              until the Services are terminated, and any and all outstanding fees have been paid in full. If we
              are unable to process payment of fees using your payment method on file, we will make a second
              attempt to process the payment using the payment method on file within three (3) days. If the second
              attempt is not successful, we will notify you, and may suspend and revoke access to the Services. If
              the Services are suspended, your Services will be reactivated upon payment of any amounts owed. If
              the outstanding fees remain unpaid for sixty (60) days following the date of suspension, then Wise
              reserves the right to terminate your Account. You may change your payment method through your
              account settings.
            </li>
            <li>
              <strong>Applicable Fees.</strong> Your use of the Services is subject to the fees on the Fee
              Schedule, which may be updated from time-to-time at our discretion. For fees owed under this
              Agreement, we will automatically charge you using the payment method you have on file with us and by
              agreeing to this Agreement, you authorize us to do this. You will be charged via invoice each month
              on your billing date (“Billing Date”) for all outstanding fees that have not previously been charged
              or collected. All fees are exclusive of applicable taxes. You are responsible for all applicable
              taxes that arise from or as a result of your use of the Services.
            </li>
            <li>
              <strong>Collection and Right to Set-Off</strong>. You agree to pay all amounts owed to us on demand.
              Your failure to pay amounts owed to us under this Agreement is a breach and you will be liable for
              any costs we incur during collection in addition to the amount you owe. Collection costs may include
              attorneys’ fees, costs of arbitration or court proceeding, collection agency fees, any applicable
              interest, and any other related cost. We may collect amounts you owe to us under this Agreement by
              deducting any amounts you owe to use from your Deposit Account. You grant us a security interest in
              and right to set off against the Deposit Account.
            </li>
          </ol>
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="4">
        <li>
          <strong> Proprietary Rights</strong>
          <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
            <li>
              <strong>Limited License</strong>. Subject to the terms and conditions of this Agreement, you are
              hereby granted a non-exclusive, limited, non-transferable, freely revocable license to use the
              Services as permitted by the features of the Services. Shifl Capital reserves all rights not
              expressly granted herein in the Services and the Shifl Capital IP (as defined below). Shifl Capital
              may terminate this license at any time for any reason or no reason. The Services and all materials
              therein or transferred thereby, including, without limitation, all software, images, text, graphics,
              illustrations, logos, patents, trademarks, service marks, copyrights, photographs, audio, videos,
              music, and other content available on the Site (the “Shifl Capital IP”), and all Intellectual
              Property Rights related thereto, are the exclusive property of Shifl Capital and its licensors.
              Except as explicitly provided herein, nothing in this Agreement shall be deemed to create a license
              in or under any such Intellectual Property Rights, and you agree not to sell, license, rent, modify,
              distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or
              create derivative works from any Shifl Capital IP. Use of the Shifl Capital IP for any purpose not
              expressly permitted by this Agreement is strictly prohibited. For the purposes of this Agreement,
              “Intellectual Property Rights” means all patent rights, copyright rights, mask work rights, moral
              rights, rights of publicity, trademark, trade dress and service mark rights, goodwill, trade secret
              rights and other intellectual property rights as may now exist or hereafter come into existence, and
              all applications therefore and registrations, renewals and extensions thereof, under the laws of any
              state, country, territory or other jurisdiction.
            </li>
            <li>
              <strong>Feedback</strong>. You may choose to, or we may invite you to, submit comments or ideas
              about the Services, including without limitation about how to improve the Services or our products
              (“Feedback”). By submitting any Feedback, you agree that your disclosure is gratuitous, unsolicited
              and without restriction, and will not place Shifl Capital under any fiduciary or other obligation,
              and that we are free to use your Feedback without any additional compensation to you, or to disclose
              your Feedback on a non-confidential basis or otherwise to anyone. You further acknowledge that, by
              acceptance of your submission, Shifl Capital does not waive any rights to use similar or related
              ideas previously known to Shifl Capital, or developed by its employees, or obtained from sources
              other than you.
            </li>
            <li>
              <strong>Copyright Complaints</strong>. If you believe that any material on the Site infringes upon
              any copyright which you own or control, you may send a written notification of such infringement to
              our Designated Agent as set forth below:<br>343 spook Rock Rd suffern ny 10901<br><a
                href="mailto:hello@shifl.com">hello@shifl.com</a><br>To meet the notice restrictions under
              the Digital Millennium Copyright Act, the notification must be a written communication that includes
              the following:
              <ul>
                <li>
                  A physical or electronic signature of a person authorized to act on behalf of the owner of
                  an exclusive right that is allegedly infringed;
                </li>
                <li>
                  Identification of the copyrighted work claimed to have been infringed, or, if multiple
                  copyrighted works at a single online site are covered by a single notification, a
                  representative list of such works at that site;
                </li>
                <li>
                  Identification of the material that is claimed to be infringing or to be the subject of
                  infringing activity and that is to be removed or access to which is to be disabled, and
                  information reasonably sufficient to permit us to locate the material;
                </li>
                <li>
                  Information reasonably sufficient to permit us to contact the complaining party, such as an
                  address, telephone number and, if available, an electronic mail address at which the
                  complaining party may be contacted;
                </li>
                <li>
                  A statement that the complaining party has a good-faith belief that use of the material in
                  the manner complained of is not authorized by the copyright owner, its agent or the law; and
                </li>
                <li>
                  A statement that the information in the notification is accurate, and under penalty of
                  perjury, that the complaining party is authorized to act on behalf of the owner of an
                  exclusive right that is allegedly infringed.<br data-mce-bogus="1">
                </li>
              </ul>
            </li>
          </ol>
        </li>
      </ol>
      <ol start="5">
        <li>
          <strong> Site Data and Privacy<br></strong>You understand that by using the Services you consent to the
          collection, use and disclosure of Personal Information and aggregate data we collect from your use of the
          Services (“Site Data”) as set forth in our Privacy Policy, and to have Personal Information and Site Data
          collected, used, transferred to and processed in the United States. You grant us a worldwide, irrevocable
          license to use, modify, distribute, copy, and create derivative works from Site Data for the purposes
          identified in this Agreement. Shifl Capital uses commercially reasonable physical, managerial, and technical
          safeguards to preserve the integrity and security of your Personal Information and implement your privacy
          settings. However, we cannot guarantee that unauthorized third parties will never be able to defeat our
          security measures or use Personal Information for improper purposes. You acknowledge that you provide
          Personal Information at your own risk. Your use of the Deposit Account and other financial services is
          subject to the privacy policies of our Banking Services Provider.
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="6">
        <li>
          <strong> Third-Party Links and Information<br></strong>The Services may contain links to third-party
          materials that are not owned or controlled by Shifl Capital. Shifl Capital does not endorse or assume any
          responsibility for any such third-party services, information, materials, products, or Services. If you
          access a third-party website, application or service from the Services, you do so at your own risk, and you
          understand that this Agreement and Shifl Capital’s Privacy Policy do not apply to your use of such
          third-party services. You expressly relieve Shifl Capital from any and all liability arising from your use
          of any third-party websites, applications, services, or content. Additionally, your dealings with or
          participation in promotions of advertisers found on the Services, including payment and delivery of goods,
          and any other terms (such as warranties) are solely between you and such advertisers. You agree that Shifl
          Capital shall not be responsible for any loss or damage of any sort relating to your dealings with such
          advertisers.
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="7">
        <li>
          <strong> Indemnification<br></strong>You agree to defend, indemnify and hold harmless Shifl Capital and its
          subsidiaries, agents, licensors, managers, and other affiliated companies, and their employees, contractors,
          agents, officers and directors, our Banking Services Provider, and our third-party service providers, from
          and against any and all third-party claims, damages, obligations, losses, liabilities, costs or debt, and
          expenses (including but not limited to attorney’s fees) arising out of, related to, or resulting from : (a)
          your use of and access to the Services, including any data or content transmitted or received by you; (b)
          your violation of any term of this Agreement, including without limitation your breach of any of the
          representations and warranties above; (c) your violation of any third-party right, including without
          limitation any right of privacy or Intellectual Property Rights; (d) your violation of any applicable law,
          rule or regulation; (e) your intentional misconduct; or (f) any other party’s access and use of the Services
          with your unique username, password or other appropriate security code.
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="8">
        <li>
          <strong> No Warranty<br></strong>THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF
          THE SERVICES IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES ARE
          PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. NO ADVICE OR
          INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM SHIFL CAPITAL OR THROUGH THE SERVICES WILL CREATE
          ANY WARRANTY NOT EXPRESSLY STATED HEREIN. WITHOUT LIMITING THE FOREGOING, SHIFL CAPITAL ITS SUBSIDIARIES,
          ITS AFFILIATES, AND ITS LICENSORS DO NOT WARRANT THAT THE INFORMATION PROVIDED TO YOUR THROUGH THE SERVICES
          IS ACCURATE, RELIABLE OR CORRECT; THAT THE SERVICES WILL MEET YOUR REQUIREMENTS; THAT THE SERVICES WILL BE
          AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE
          CORRECTED; OR THAT THE SERVICES ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ANY CONTENT DOWNLOADED OR
          OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY
          RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH
          DOWNLOAD OR YOUR USE OF THE SERVICES.<br>SHIFL CAPITAL DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
          RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES OR ANY
          HYPERLINKED WEBSITE OR SERVICE, AND SHIFL CAPITAL WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY
          TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
        </li>
      </ol>
      <ol start="9">
        <li>
          <strong> Limitation of Liability<br></strong>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
          SHALL SHIFL CAPITAL, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS OR LICENSORS BE LIABLE FOR ANY
          INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION
          DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR RELATING TO
          THE USE OF, OR INABILITY TO USE, THE SERVICES. UNDER NO CIRCUMSTANCES WILL SHIFL CAPITAL BE RESPONSIBLE FOR
          ANY DAMAGE, LOSS OR INJURY RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE
          SERVICES OR YOUR ACCOUNT OR THE INFORMATION CONTAINED THEREIN.<br>TO THE MAXIMUM EXTENT PERMITTED BY
          APPLICABLE LAW, SHIFL CAPITAL ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR
          INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
          YOUR ACCESS TO OR USE OF OUR SERVICES; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR
          ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
          FROM THE SERVICES; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH
          OUR SERVICES BY ANY THIRD PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE
          INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
          THROUGH THE SERVICES; AND/OR (VII) USER CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY
          THIRD PARTY. IN NO EVENT SHALL SHIFL CAPITAL, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR
          LICENSORS BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS
          IN AN AMOUNT EXCEEDING $100.00.<br>THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED
          LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OROLID ANY OTHER BASIS, EVEN IF SHIFL
          CAPITAL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE FOREGOING LIMITATION OF LIABILITY SHALL
          APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="10">
        <li>
          <strong> Term and Termination<br></strong>This Agreement is effective when you submit your application to us
          and continues until terminated by either you or us, or in accordance with the Banking Services Provider’s
          agreements, third-party payment processor agreements, or as otherwise set forth in this Agreement. You may
          terminate this Agreement by paying all amounts you owe and providing notice to us; except that you will
          still be responsible for any charges, fees, fines, and other losses caused by your action or inaction prior
          to terminating this Agreement. We may terminate this Agreement, or suspend your Account for any reason, by
          providing you notice, or we may terminate this Agreement immediately in the event you breach or violate any
          of the terms of this Agreement, as determined in the sole discretion of Shifl Capital.
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="11">
        <li>
          <strong> Governing Law, Arbitration, and Class Action/Jury Trial Waiver</strong>
          <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
            <li>
              <strong>Governing Law</strong>. You agree that: (i) the Services shall be deemed solely based in New
              York, except as may be set forth in the Bank Provider Agreements; and (ii) the Services shall be
              deemed a passive one that does not give rise to personal jurisdiction over us, either specific or
              general, in jurisdictions other than New York. This Agreement shall be governed by the internal
              substantive laws of the State of New York, without respect to its conflict of laws principles. The
              parties acknowledge that this Agreement evidences a transaction involving interstate commerce.
              Notwithstanding the preceding sentences with respect to the substantive law, any arbitration
              conducted pursuant to the terms of this Agreement shall be governed by the Federal Arbitration Act
              (9 U.S.C. §§ 1-16). You agree to submit to the personal jurisdiction of the federal and state courts
              located in Rockland county NY for any actions for which we retain the right to seek injunctive or
              other equitable relief in a court of competent jurisdiction to prevent the actual or threatened
              infringement, misappropriation or violation of a our copyrights, trademarks, trade secrets, patents,
              or other intellectual property or proprietary rights, as set forth in the Arbitration provision
              below, including any provisional relief required to prevent irreparable harm. Subject to Section
              11.B, you agree that the federal or state courts located in Rockland county NY is the proper forum
              for any appeals of an arbitration award or for trial court proceedings in the event that the
              Arbitration provision below is found to be unenforceable.<br><br>
            </li>
            <li>
              <strong>Arbitration</strong>. READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE PARTIES TO
              ARBITRATE THEIR DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM SHIFL CAPITAL. For
              any dispute with Shifl Capital, you agree to first contact us at 343 spook rock rd suffern ny 10901
              and attempt to resolve the dispute with us informally. In the unlikely event that Shifl Capital has
              not been able to resolve a dispute it has with you after sixty (60) days, we each agree to resolve
              any claim, dispute, or controversy (excluding any claims arising from protection of Intellectual
              Property Rights, breach of Confidential Information, which will be resolved through litigation in
              accordance with Section 11.A, or for injunctive or other equitable relief as provided below) arising
              out of or in connection with or relating to this Agreement, or the breach or alleged breach thereof
              (collectively, “Claims”), by binding arbitration by JAMS. Claims with amounts claimed greater than
              $250,000 will apply the JAMS Comprehensive Arbitration Rules and Procedures; and Disputes with
              amounts claimed less than or equal to $250,000 will apply the JAMS Streamlined Arbitration Rules.
              The arbitration will be conducted in Rockland county NY, unless you and Shifl Capital agree
              otherwise. If JAMS cannot administer the Claim, either party may petition the US District Court for
              the US district Court of New York to appoint an arbitrator. Each party will be responsible for
              paying any JAMS filing, administrative and arbitrator fees in accordance with JAMS rules, and the
              award rendered by the arbitrator shall include costs of arbitration, reasonable attorneys’ fees and
              reasonable costs for expert and other witnesses. Any judgment on the award rendered by the
              arbitrator may be entered in any court of competent jurisdiction. Either party may commence
              arbitration by providing a written demand for arbitration to JAMS and the other party detailing the
              subject of the Claim and the relief requested. Each party will continue to perform its obligations
              under this Agreement unless that obligation or the amount (to the extent in dispute) is itself the
              subject of the Claim. Nothing in this Section shall be deemed as preventing either party from
              seeking injunctive or other equitable relief from the courts as necessary to prevent the actual or
              threatened infringement, misappropriation, or violation of our data security, Intellectual Property
              Rights or other proprietary rights. Proceedings and information related to them will be maintained
              as confidential, including the nature and details of the Claim, evidence produced, testimony given,
              and the outcome of the Claim, unless such information was already in the public domain or was
              independently obtained. Person and Shifl Capital, and all witnesses, advisors, and arbitrators will
              only share such information as necessary to prepare for or conduct arbitration or other legal
              proceeding, or enforcement of the outcome, unless additional disclosure is required by law.<br><br>
            </li>
            <li>
              <strong>Class Action/Jury Trial Waiver</strong>. With respect to all persons and entities,
              regardless of whether they have obtained an account or used the services for personal, commercial or
              other purposes, all Claims must be brought in the parties’ individual capacity, and not as a
              plaintiff or class member in any purported class action, collective action, private attorney general
              action or other representative proceeding. This waiver applies to class arbitration, and, unless we
              agree otherwise, the arbitrator may not consolidate more than one person’s claims. You agree that,
              by entering into this agreement, you and Shifl Capital are each waiving the right to a trial by jury
              or to participate in a class action, collective action, private attorney general action, or other
              representative proceeding of any kind.
            </li>
          </ol>
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="12">
        <li>
          <strong> Communication<br></strong>By providing us with a telephone number for a mobile device, including a
          number that you later convert to a mobile device number, you are expressly consenting to receiving
          communications—including but not limited to prerecorded or artificial voice message calls, text messages,
          and calls made by an automatic telephone dialing system—from us and our affiliates and agents at that
          number, about any product or services offered by Shifl Capital. This express consent applies to each such
          telephone number that you provide to us now or in the future. Calls and messages may incur access fees from
          your mobile services provider. You understand that you need not provide this consent as a condition of
          obtaining goods or services from Shifl Capital, and that you may decline to provide or revoke your consent
          at any time by emailing hello@shifl.com or by any other method that reasonably ensures we receive your
          revocation.<br>By providing us with a telephone number, you agree that Shifl Capital may record any
          telephone conversation with you (or any another individual) during any telephone call to or from that number
          for training purposes, whether or not the call was initiated by you, and whether or Shifl Capital disclosed
          the fact that the call was recorded during the call.
        </li>
      </ol>
      <p><br></p>
      <ol start="13">
        <li>
          <strong> General</strong>
          <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
            <li>
              <strong>Assignment</strong>. This Agreement, and any rights and licenses granted hereunder, may not
              be transferred or assigned by you, but may be assigned by Shifl Capital without restriction. Any
              attempted transfer or assignment in violation hereof shall be null and void.
            </li>
            <li>
              <strong>Notification Procedures and Changes to the Agreement.</strong> You consent to us providing
              notices to you under this Agreement electronically and understand that this consent has the same
              legal effect as a physical signature. We will provide notices to you electronically through your
              Account, and via text or SMS to the phone numbers provided to us by you. If you sign up to receive
              certain Shifl Capital notifications or information via text or SMS, you may incur additional charges
              from your wireless provider for these notices. You agree that you are solely responsible for any
              such charges. Notices affecting the terms of this Agreement will be sent to you and are considered
              received 24 hours after they are sent. You understand that you may not use the Services unless you
              consent to receive notices from us electronically. You may only withdraw consent to receive notices
              electronically by closing your Account. Notices may include alerts about the Services, your Account,
              and your Deposit Account and may provide you the ability to respond with information about Deposit
              Account transactions or your Account. You may disable notification preferences to limit the use of
              certain Service features or to decrease financial risks to yourself. You are required to maintain a
              regularly updated web browser, and computer and mobile device operating systems to receive notices
              correctly. You will be responsible for all costs imposed by internet or mobile service providers for
              sending or receiving notices electronically. Contact us immediately at 343 spook rock rd suffern ny
              10901 if you are having trouble receiving notices from us.
            </li>
            <li>
              <strong>Entire Agreement/Severability</strong>. This Agreement, together with any amendments and any
              additional agreements you may enter into with Shifl Capital in connection with the Services, shall
              constitute the entire agreement between you and Shifl Capital concerning your Account and the
              Services. If any provision of this Agreement is deemed invalid by a court of competent jurisdiction,
              the invalidity of such provision shall not affect the validity of the remaining provisions of this
              Agreement, which shall remain in full force and effect, except that in the event of unenforceability
              of the universal Class Action/Jury Trial Waiver, the entire arbitration agreement shall be
              unenforceable.
            </li>
            <li>
              <strong>No Waiver</strong>. No waiver of any term of this Agreement shall be deemed a further or
              continuing waiver of such term or any other term, and Shifl Capital’s failure to assert any right or
              provision under this Agreement shall not constitute a waiver of such right or provision.
            </li>
            <li>
              <strong>Legal Orders</strong>. We may respond to and comply with any subpoenas, warrants, liens, or
              any other legal order we receive related to your use of the Services. We are not responsible to you
              for any losses you incur due to our response to such legal order. We may hold funds or provide
              information as required by the issuer of the legal order or take any other actions we believe are
              required of us under legal orders. Where permitted, we will provide you reasonable notice that we
              have received such an order.
            </li>
            <li>
              <strong>Survival</strong>. Any and all provisions of this Agreement reasonably giving rise to
              continued obligations of the parties will survive termination of this Agreement.
            </li>
            <li>
              <strong>Relationship</strong>. No joint venture, partnership, employment, or agency relationship
              exists between you and Shifl Capital as a result of this Agreement or your use of the Site.
            </li>
          </ol>
        </li>
      </ol>
      <!--  END OF TERMS OF SERVICES CONSUMER  -->
      <!--  VISA CREDIT CARD AGREEMENT  -->
      <h1><strong>Shifl Capital Visa Business Credit Card Agreement</strong></h1>
      <p>
        Before you execute this agreement, please read through these Agreement Disclosures for eligibility, fee, and
        other important information. Please keep this Shifl Capital Secured Charge Card Agreement for your records. The
        Shifl Capital Visa® Business Credit Card (“Card”) is offered by Evolve Bank &amp; Trust, Member FDIC (“Bank”),
        and is distributed and serviced by Shifl Capital service provider Solid Financial Technologies, Inc. (“Shifl
        Capital”) on behalf of the Bank. “We”, “us” and “our” means Bank and its successors and assigns.
      </p>
      <p><strong>&nbsp;</strong></p>
      <p>
        <strong>I. Truth In Lending Disclosures<br></strong>IMPORTANT PRICING INFORMATION ABOUT YOUR &nbsp;SHIFL CAPITAL
        CARD
      </p>
      <table width="636" class="mce-item-table">
        <tbody>
          <tr>
            <td colspan="2" width="636">
              <p><strong>Shifl Capital Truth in Lending Disclosures</strong></p>
              <p>Shifl Capital will apply the following fees or interest:</p>
            </td>
          </tr>
          <tr>
            <td width="288">
              <p><strong>Type</strong></p>
            </td>
            <td width="348">
              <p><strong>Interest Rate or Fee</strong></p>
            </td>
          </tr>
          <tr>
            <td width="288">
              <p>Annual Percentage Rate (APR) for Purchases and Cash Advances</p>
            </td>
            <td width="348">
              <p>0.0%</p>
            </td>
          </tr>
          <tr>
            <td width="288">
              <p>Penalty APR and When it Applies</p>
            </td>
            <td width="348">
              <p>0.0%</p>
              <p>
                This APR (the Penalty APR) may be applied to your account if you do not pay the minimum payment due,
                make a late payment, or your payment is returned and you fail to make a successful payment by the
                Due Date. Your entire balance will be subject to the Penalty APR.
              </p>
              <p>
                How Long Will the Penalty APR Apply?: If your APR is increased for any of these reasons, the Penalty
                APR may apply until you make all your required payments and you have no amount past due.
              </p>
            </td>
          </tr>
          <tr>
            <td width="288">
              <p>How to Avoid Paying Interest</p>
              <p><strong>&nbsp;</strong></p>
            </td>
            <td width="348">
              <p>
                Your Due Date is at least 21 days after the close of each Cycle. We will not charge you
                interest on purchases or cash advances if you pay your entire balance by the Due Date each month.
              </p>
            </td>
          </tr>
          <tr>
            <td width="288">
              <p>Minimum Interest Charge</p>
              <p><strong>&nbsp;</strong></p>
            </td>
            <td width="348">
              <p>None</p>
            </td>
          </tr>
          <tr>
            <td width="288">
              <p>For Credit Card Tips from the Consumer Financial Protection Bureau</p>
            </td>
            <td width="348">
              <p>
                To learn more about the factors to consider when applying for or using a credit card,
                visit the website of the Consumer Financial Protection Bureau at <a
                  href="http://www.consumerfinance.gov/learnmore"
                  data-mce-href="http://www.consumerfinance.gov/learnmore"><strong>http://www.consumerfinance.gov/learnmore</strong></a>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p><strong>&nbsp;</strong></p>
      <table width="636" class="mce-item-table">
        <tbody>
          <tr>
            <td colspan="2" width="636">
              <p><strong>Card Services Fees</strong></p>
              <p>
                Shifl Capital will assess the Card procurement fees listed below at any time after Client submits its
                order request to Shifl Capital.
              </p>
            </td>
          </tr>
          <tr>
            <td width="288">
              <p><strong>Type</strong></p>
            </td>
            <td width="348">
              <p><strong>Fee</strong></p>
            </td>
          </tr>
          <tr>
            <td width="288">
              <p><strong>Initial Card Procurement Fee</strong></p>
              <p><br></p>
            </td>
            <td width="348">
              <p>$0.00 per physical card with a magnetic stripe and EMV chip.</p>
              <p>Additional service fees may apply for the type of postage.</p>
            </td>
          </tr>
          <tr>
            <td width="288">
              <p><strong>Replacement Card Procurement Fee</strong></p>
              <p><br></p>
            </td>
            <td width="348">
              <p>
                $5.00 per physical card with a magnetic stripe and EMV chip. Additional service fees may
                apply for the type of postage.
              </p>
            </td>
          </tr>
          <tr>
            <td width="288">
              <p><strong>Cross-border Fee</strong></p>
              <p><br></p>
            </td>
            <td width="348">
              <p>1.00% of the Transaction Amount + $0.30, per cross-border Transaction.</p>
              <p><br></p>
              <p>Applied where an Authorized Client makes a purchase in a country other than the United States.</p>
            </td>
          </tr>
          <tr>
            <td width="288">
              <p><strong>Foreign exchange Fee</strong></p>
              <p><br></p>
            </td>
            <td width="348">
              <p>1.00% of the Transaction Amount</p>
              <p><br></p>
              <p>
                Applied in each instance where Shifl Capital converts funds from one currency to another. This
                calculation is made on the converted amount.
              </p>
            </td>
          </tr>
          <tr>
            <td width="288">
              <p><strong>Disputes Fee or Late Payment or Returned Payment </strong></p>
            </td>
            <td width="348">
              <p>$15 per occurrence</p>
            </td>
          </tr>
          <tr>
            <td width="288">
              <p><strong>Cash Advance Fee (Out of Network ATM Withdrawals)*</strong></p>
            </td>
            <td width="348">
              <p>$2.5</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>*</strong>If you use an ATM that is not a MoneyPass ATM for any transaction, including a Cash Advance or
        balance inquiry, you may be charged a fee by the ATM operator even if you do not complete a transaction. This
        ATM fee is a third-party fee amount assessed by the individual ATM operator only and is not assessed by us. This
        ATM fee amount will be charged to your Credit Account.&nbsp;
      </p>
      <p><strong>&nbsp;</strong></p>
      <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
        <li>
          <strong> Billing Rights<br></strong>Information on your rights to dispute transactions and how to exercise
          those rights is provided in this Cardholder Agreement.
        </li>
        <li>
          <strong> How We Will Calculate Your Balance<br></strong>We figure the interest charge each Cycle by applying
          the periodic rate to the average daily balance (including new purchases) on the Card. To get the average
          daily balance, we take the beginning balance on the Card each day of the Cycle, add any new purchases, cash
          advances, and/or fees, and subtract any unpaid interest or other finance charges and any payments or
          credits. This gives us the daily balance. Then, we add up all the daily balances for the Cycle and divide
          the total by the number of days in the Cycle. This gives us the average daily balance. The interest charge
          calculated for each Card will be added to the balance on that Card, and to the total Shifl Capital Card
          Balance for your Shifl Capital Account, at the end of each Cycle.
        </li>
        <li>
          <strong> Secured Credit (Charge) Card Account<br></strong>Your Shifl Capital Card is secured by a deposit
          account (“Shifl Capital Account”) that you are required to establish and maintain in order to have the Shifl
          Capital Card. Funds from your Shifl Capital Account will be used to pay down your balance on your Shifl
          Capital Card. You may never spend more than the amount you have in your Shifl Capital Account with your
          Shifl Capital Card.&nbsp; We refer to this limit as the Spending Limit.&nbsp; Your Shifl Capital Card is
          also subject to a maximum Credit Limit.&nbsp; See Section III.A.2 for more information.&nbsp; Your
          obligation to pay us back for all Card transactions is secured by your Shifl Capital Account. If you do not
          pay us back for any amount you owe us when your payments are due, we may exercise our interest and debit
          your Shifl Capital Account and you can lose the money in your Shifl Capital Account.&nbsp; See the Security
          Agreement section for more information.&nbsp; You are responsible for all activity conducted on your Shifl
          Capital Card.
        </li>
        <li>
          <strong> The </strong><strong>Shifl Capital Card and associated Shifl Capital Account Are Only Available
            Electronically<br></strong>This Shifl Capital Card and the Shifl Capital Account are only available
          electronically. By applying for the Shifl Capital Card and Shifl Capital Account you agreed to receive all
          disclosures electronically. See the Shifl Capital Electronic Communications Consent agreement.&nbsp; If you
          do not have the systems needed to receive disclosures electronically, we cannot provide this Shifl Capital
          Card or the Shifl Capital Account to you.
        </li>
        <li>
          <strong> Negative Credit Reports<br></strong>You are hereby notified that a negative credit report
          reflecting on your credit record may be submitted to a credit reporting agency if you fail to fulfill the
          terms of your credit obligations.
        </li>
        <li><strong> State Disclosures</strong></li>
      </ol>
      <p style="padding-left: 80px;" data-mce-style="padding-left: 80px;">
        <strong>California Residents:</strong>&nbsp;After
        credit approval, each applicant shall have the right to use the Shifl Capital Card up to the limit of the
        account. Each applicant may be liable for amounts extended under the plan to any joint applicant.
      </p>
      <p style="padding-left: 80px;" data-mce-style="padding-left: 80px;">
        <strong>Kentucky Residents:</strong>&nbsp;You
        may pay the unpaid balance of your Shifl Capital Card in whole or in part at any time.
      </p>
      <p style="padding-left: 80px;" data-mce-style="padding-left: 80px;">
        <strong>New Jersey Residents:</strong>&nbsp;Because
        certain provisions of this Agreement are subject to applicable law, they may be void, unenforceable or
        inapplicable in some jurisdictions. None of these provisions, however, is void, unenforceable or inapplicable in
        New Jersey.
      </p>
      <p style="padding-left: 80px;" data-mce-style="padding-left: 80px;">
        <strong>New York, Rhode Island and Vermont
          Residents:</strong>&nbsp;We may obtain a consumer credit report for any legitimate purpose in connection with
        your Shifl Capital Card or your application, including but not limited to reviewing, modifying, renewing and
        collecting on your Shifl Capital Card. Upon your request, we will inform you of the names and addresses of any
        credit reporting agencies that have furnished the reports.
      </p>
      <p style="padding-left: 80px;" data-mce-style="padding-left: 80px;">
        <strong>Ohio Residents:</strong>&nbsp;The Ohio
        laws against discrimination require that all creditors make credit equally available to all creditworthy
        customers, and that credit reporting agencies maintain separate credit histories on each individual upon
        request. The Ohio Civil Rights Commission administers compliance with this law.
      </p>
      <p style="padding-left: 80px;" data-mce-style="padding-left: 80px;">
        <strong>Married Wisconsin Residents:</strong>&nbsp;If
        you are married, by submitting your Shifl Capital Card application you are confirming that this Shifl Capital
        Card obligation is being incurred in the interest of your marriage and your family. No provision of a marital
        property agreement, unilateral statement under Section 766.59 of the Wisconsin Statutes, or court order under
        Section 766.70 of the Wisconsin Statutes adversely affects the interest of the creditor unless the creditor,
        prior to the time the credit is granted, is furnished a copy of the agreement, statement or decree or has actual
        knowledge of the adverse provision when the obligation to the creditor is incurred. If the Shifl Capital Card
        for which you are applying is granted, you will notify the Bank if you have a spouse who needs to receive
        notification that credit has been extended to you.
      </p>
      <p style="padding-left: 80px;" data-mce-style="padding-left: 80px;">
        <strong>Military Annual Percentage Rate
          Disclosures<br></strong>Federal law provides important protections to active-duty members of the Armed Forces
        and their dependents relating to extensions of consumer credit. In general, the cost of consumer credit to a
        member of the Armed Forces and his or her dependent may not exceed an annual percentage rate of 36 percent. This
        rate must include, as applicable to the credit transaction or account: The costs associated with credit
        insurance premiums; fees for ancillary products sold in connection with the credit transaction; any application
        fee charged (other than certain application fees for specified credit transactions or accounts); and any
        participation fee charged (other than certain participation fees for a Shifl Capital Card account). For more
        information contact <a href="mailto:hello@shifl.com">hello@shifl.com</a>.
      </p>
      <p style="">
        <strong>II. Security Agreement</strong>
      </p>
      <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
        <li>
          <strong>Our Security Interest in Your </strong><strong>Shifl Capital Account<br></strong>You hereby grant to
          us, as of the date you enter into this Agreement, a security interest in all right, title and interest in
          any Shifl Capital Account you have with us, and all funds deposited therein, now owned or hereinafter
          acquired (collectively, the Collateral), to secure your performance under this Agreement, including your
          duty to pay us for all obligations you owe us under this Agreement, present or hereinafter occurring, when
          payments are due, for every transaction made with your Shifl Capital Account by you.
        </li>
        <li>
          <strong> Our Right to Exercise the Security Interest<br></strong>You irrevocably and unconditionally
          relinquish possession and control over the Collateral, and you pledge and assign as security to us all of
          your right, title, and interest in it. You must take any action we request to perfect or protect our first
          lien position Security Interest in the Collateral. You waive the benefit of any homestead or other
          exemptions in the Collateral. The Security Interest will be governed by Uniform Commercial Code - Article 9
          (as adopted by the applicable state law) whether Article 9 applies by its terms or not. We do not have to
          give you any prior notice to apply the funds in your Deposit Account or other Collateral or its proceeds to
          satisfy your obligations. You expressly agree that our rights under this Security Agreement extend to any
          electronically deposited federal or state benefit payments (including Social Security benefits) to the
          extent permitted by law. If you do not want your benefits applied in this way, you may change your direct
          deposit instructions at any time with the person or organization paying the benefits. In addition, you grant
          us a right of setoff to your Shifl Capital Account to secure all amounts you owe us under this Agreement.
          This right of setoff does not apply to your Shifl Capital Account if prohibited by law. You agree to hold us
          harmless from any claim arising as a result of our exercise of our right of setoff or Security Interest.
        </li>
        <li>
          <strong> What Happens When You Spend Money<br></strong>When you make a transaction on a Shifl Capital Card,
          an equivalent amount of funds in the Shifl Capital Account will be classified as Spent Money. The Spent
          Money will remain in your Shifl Capital Account, but you will not earn interest on it. When you make a
          payment on your Shifl Capital Card, an amount of the Spent Money equal to the amount of the payment will no
          longer be classified as Spent Money. You authorize us to limit your ability to withdraw or transfer all or
          part of the funds in your Shifl Capital Account, up to the amount of funds classified as Spent Money, as
          reasonably necessary to ensure that your Shifl Capital Card Balance (defined below in Section III.A.2) and
          total of other transactions on your Shifl Capital Account do not exceed the Available Balance (defined below
          in Section III.A.2) in your Shifl Capital Account at any given time, and to preserve our rights under this
          Security Agreement.
        </li>
        <li>
          <strong> YOU MAY LOSE FUNDS IN YOUR Shifl Capital ACCOUNT<br></strong>If you are in default of any
          obligation under this Agreement, or your Shifl Capital Account is closed for any reason, you authorize us to
          withdraw funds from your Shifl Capital Account and apply such amounts to the balance you owe us without
          sending you notice or demand for payment. We may do this in addition to the other rights we have under law
          or this Agreement. The application of your funds to the balance you owe under this Agreement will not affect
          your obligation to pay us in full. You are responsible for the repayment of all amounts you owe us under
          this Agreement.
        </li>
        <li>
          <strong> Legal Proceedings<br></strong>You represent that (a) there are no current lawsuits or bankruptcy
          proceedings that might affect our interest in your Shifl Capital Account; and (b) you have not and will not
          attempt to transfer any interest in your Deposit Account to any other person or offer your Shifl Capital
          Account as collateral or security for any other obligation. If any person seeks to attach your Shifl Capital
          Account, for example by legal garnishment, you agree that we may deem all amounts you owe us, including any
          unpaid Shifl Capital Card Balance (defined below in Section III.A.2), immediately payable and apply the
          funds in your Shifl Capital Account as payment of those amounts. If we must hire an attorney to defend or
          enforce our rights under the Security Agreement, you will pay our reasonable attorneys' fees and court
          costs, unless prohibited by law.
        </li>
      </ol>
      <p><br></p>
      <h1><br></h1>
      <p><strong>&nbsp;III.</strong><strong>Shifl Capital CARD TERMS</strong></p>
      <p><strong>&nbsp;</strong></p>
      <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
        <li>
          <strong> A Shifl Capital Card is a credit card. It is not a debit card, and you do not access the money in
            your Shifl Capital Account when you use a Shifl Capital Card. All credit we extend to you is secured by your
            Shifl Capital Account. If you do not pay us back for transactions you make with your Shifl Capital Card(s)
            when your payments are due, we may exercise our Security Interest and withdraw the amount you owe us from
            your Shifl Capital Account. You can lose the money in your Shifl Capital Account. Refer to the Security
            Agreement section (II) for more information.</strong>
          <ol style="">
            <li>
              <strong> Your Shifl Capital Card<br></strong>The following rules apply to your use of a Shifl
              Capital Card:
              <ul>
                <li>You must sign the Shifl Capital Card when you receive it</li>
                <li>You must return the Shifl Capital Card to us or destroy it if we ask you to.</li>
                <li>
                  You must take reasonable action to prevent the unauthorized use of your Shifl Capital
                  Card.
                </li>
                <li>
                  We are not responsible for any losses you incur if anyone refuses to accept your Shifl
                  Capital Card for any reason.
                </li>
                <li>We may decline to authorize a transaction for any reason.</li>
                <li>We are not responsible for any losses you incur if we do not authorize a transaction.</li>
                <li>You must not use, or try to use, the Shifl Capital Card for any illegal activity.</li>
                <li>You will only use your Shifl Capital Card for business purposes, not personal use.</li>
                <li>
                  We are not liable for any losses that may result when our services are unavailable due to
                  reasons beyond our control.
                </li>
              </ul>
            </li>
            <li>
              <strong>Credit Limit and Available Credit</strong>
              <ul>
                <li>
                  <strong>Credit Limit.</strong> We will assign a Credit Limit to your Shifl Capital Card
                  Account. A Credit Limit is the maximum amount of credit we may extend to you when you use
                  your Shifl Capital Card(s). The total outstanding unpaid balance you owe on your Shifl
                  Capital Card(s) (Shifl Capital Card Balance) may never be greater than your Credit Limit.
                  You are responsible for keeping track of your Shifl Capital Card Balance.
                </li>
                <li>
                  <strong>Spending Limit.</strong> All transactions on your Shifl Capital Card(s) are subject
                  to the applicable Spending Limit.
                </li>
                <li>
                  <strong>Available Credit.</strong> Your Available Credit is the amount of credit that is
                  available for you to make transactions with your Shifl Capital Card(s) at any given time.
                  Your Available Credit is equal to the Available Balance in your Shifl Capital Account, minus
                  the Spent Money, up to your Credit Limit.
                </li>
                <li>
                  <strong>Rules </strong>You can use the App or email us at <strong>hello@shifl.com</strong>
                  to view your Shifl Capital Card Balance, Spending Limit, and Available Credit at any time.
                  While we do our best to provide the most up-to-date information about your Shifl Capital
                  Account, there may be some delay in reflecting new transactions, and we cannot guarantee the
                  accuracy of this information in the App and by phone. You are responsible for keeping track
                  of your Shifl Capital Card Balance, Spending Limit, and Available Credit. Our general
                  practice is to decline transactions that will cause you to exceed your Credit Limit,
                  Spending Limit or Available Credit. If we do honor these transactions, you are responsible
                  for paying us back in full immediately, and these limits will not increase. We may increase
                  or decrease your Credit Limit, Spending Limit, or Available Credit at any time, in our sole
                  discretion. We are not required to extend credit up to your maximum Credit Limit, if it
                  would cause you to exceed your Available Credit or your Spending Limit.
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <strong>Using Your Shifl Capital Card</strong>
          <ol style="">
            <li>
              <strong>Business Use Only. </strong>You acknowledge and understand that the Shifl Capital Card shall
              not be treated as a consumer card under the provisions of state and federal law. You agree to limit
              the use of each Shifl Capital Card to legitimate business purposes and to take all necessary steps
              to assure that each Shifl Capital Card is used for no other purpose. Bank may immediately terminate
              this Agreement if you or Cardholder uses the Shifl Capital Card for any consumer or personal purpose
              or in violation of any provision of this Agreement. You understand and agree that the Shifl Capital
              Card is not a consumer access device under the provisions of the Electronic Funds Transfer Act or
              any other applicable law.
            </li>
            <li>
              <strong>Point of Sale Purchases. </strong>You may use your Shifl Capital Card to make purchases at
              points-of-sale.
            </li>
            <li>
              <strong>Using a PIN. </strong>We may give you a personal identification number (PIN). For security
              reasons, you may have to provide the PIN before you are able to use your Shifl Capital Card. Keep
              your PIN secure. Do not write it down, give it to anyone, or keep it with your Shifl Capital Card.
              If you lose your Shifl Capital Card or believe the confidentiality of your PIN has been compromised
              for any reason, you must contact us immediately.
            </li>
            <li>
              <strong> Foreign Transactions. </strong>The Shifl Capital Card may only be used to conduct
              transactions within the 50 United States, District of Columbia and U.S. Territories (Puerto Rico,
              Guam, Northern Marianas, the U.S. Virgin Islands, and American Samoa). The Shifl Capital Card may be
              used to conduct international transactions, including, without limitation, transactions conducted on
              international merchant websites or mobile applications accessible within the United States; provided
              the merchant accepts the Visa or any other logo on the Shifl Capital Card. All debits to your Shifl
              Capital Account will be posted in U.S. dollars. Transactions made in a foreign currency are
              converted into U.S. dollar amounts by Visa, using its then current currency conversion procedure and
              rate. The currency conversion rate used on the processing date may differ from the rate in effect on
              the transaction date or periodic statement posting date. Foreign transactions are subject to foreign
              transaction fees, as set forth in Exhibit 1, and will be included in the amount charged to your
              Shifl Capital Card.
            </li>
            <li>
              <strong> Card Not Present Transactions.</strong> If you initiate a transaction without presenting
              your Shifl Capital Card (such as for a mail order, internet or telephone purchase or an ACH debit),
              the legal effect will be the same as if you used the Shifl Capital Card itself. For security
              reasons, the amount or number of such transactions you may make may be limited.
            </li>
            <li>
              <strong> Rewards.</strong> Your Shifl Capital Card may provide you with the opportunity to earn
              rewards. If it does, we will separately provide you with information and terms about the rewards.
            </li>
            <li>
              <strong> Lost or Stolen Card.</strong> If your Card is lost or stolen or if you think someone else
              may be using your Shifl Capital Account, Card, or Access Credentials without your permission, you
              must contact us immediately at <a href="mailto:hello@shifl.com"><strong>hello@shifl.com</strong></a>
            </li>
            <li>
              <strong>Returns and Refunds.</strong> If you are entitled to a refund for any reason for goods or
              services obtained with the Shifl Capital Card, you agree to accept credits to your Shifl Capital
              Account for refunds and agree to the refund policy of the merchant. Neither the Bank nor Program
              Manager is responsible for the delivery, quality, safety, legality or any other aspects of the goods
              or services you purchase from others with the Shifl Capital Card. If you have a problem with a
              purchase you made with the Shifl Capital Card, or if you have a dispute with the merchant, you must
              handle it directly with the merchant. The amounts credited to your Shifl Capital Card for refunds
              may not be available for up to five (5) days from the date the refund transaction occurs.
            </li>
            <li>
              <strong> Authorized Users. </strong>You are liable for all transactions made with your Shifl Capital
              Card or Shifl Capital Account, and all related fees or expenses incurred, by you, all Authorized
              Users, and any other person that you or an Authorized User permits to use or have access to your
              Shifl Capital Account, Shifl Capital Card, or any other Access Credential.
            </li>
            <li>
              <strong> Daily Transaction Limits. </strong>In addition to your Shifl Capital Card Credit limit and
              Spending limit, your Shifl Capital Card has the following daily transaction limits.<br>
              <table style="width: 528px;" width="528" class="mce-item-table">
                <tbody>
                  <tr>
                    <td style="width: 256.578px;" width="270">
                      <p><strong>Transaction Type</strong></p>
                    </td>
                    <td style="width: 242.859px;" width="258">
                      <p><strong>Dollar Limit per Day</strong></p>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 256.578px;" width="270">
                      <p>Points of Sales (Merchants) Transactions</p>
                    </td>
                    <td style="width: 242.859px;" width="258">
                      <p>$2,500 daily</p>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 256.578px;" width="270">
                      <p>ATM Cash Advance (Withdrawal)</p>
                    </td>
                    <td style="width: 242.859px;" width="258">
                      <p>$500 daily</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
          </ol>
        </li>
        <li>
          <strong>Interest and Fees</strong>
          <ol style="">
            <li>
              <strong>Interest and Fees.</strong> We will charge interest and fees to as disclosed in the Truth in
              Lending Disclosures of this Agreement, subsequent disclosures, Statements, any change in terms
              notices, and in the Fee Schedule provided with this Agreement. In general, interest begins to accrue
              from the day a transaction occurs. However, we will not charge you interest on any new transactions
              if you pay the total Shifl Capital Card Balance you owe on your Shifl Capital Account in full by the
              Due Date on your Statement each month. If you miss a required payment, make a late payment, or a
              payment is returned and you fail to successfully make the required payment by the Due Date, the
              Penalty APR may apply to your entire Shifl Capital Card Balance (including amounts that are not past
              due), except for late fees, until you pay all overdue amounts, except as prohibited by law.
            </li>
            <li>
              <strong>Balance Computation Method.</strong> For each Shifl Capital Card associated with your Shifl
              Capital Account, we figure the interest charge each Cycle by applying the periodic rate to the
              average daily balance (including new purchases) on the Shifl Capital Card. To get the average daily
              balance, we take the beginning balance on the Shifl Capital Card each day of the Cycle, add any new
              purchases, cash advances, and/or fees, and subtract any unpaid interest or other finance charges and
              any payments or credits. This gives us the daily balance. Then, we add up all the daily balances for
              the Cycle and divide the total by the number of days in the Cycle. This gives us the average daily
              balance. The interest charge calculated for each Card will be added to the balance on that Shifl
              Capital Card, and to the total Shifl Capital Card Balance for your Shifl Capital Account, at the end
              of each Cycle.
            </li>
            <li>
              <strong> Late Payment Fee.</strong> We may charge you a late payment fee if we do not receive your
              payment as instructed on your Statement by the Due Date. The amount of the late payment fee is set
              forth in the Fee Schedule.
            </li>
            <li>
              <strong> Returned Payment Fee.</strong> We may charge you a returned payment fee each time your
              payment to us is returned unpaid for any reason. The amount of the returned payment fee is set forth
              in the Fee Schedule.
            </li>
            <li>
              <strong> Transactions Made in Foreign Currencies.</strong> If you make a transaction in a foreign
              currency, the payment network will convert it into a U.S. dollar amount. The payment network will
              use its own currency conversion procedures. The conversion rate in effect on the processing date may
              differ from the rate in effect on the transaction date that appears on your Statement. We do not
              adjust the currency exchange rate. There will be a charge for any currency conversion and will be
              disclosed in Fee Schedule.
            </li>
          </ol>
        </li>
        <li>
          <strong> Payments</strong>
          <ol style="">
            <li>
              <strong> Your Promise to Pay.</strong> You promise to pay us all amounts due on your Shifl Capital
              Account by each Due Date set forth on your Statement. This includes amounts where you did not sign a
              purchase slip or other documents for the transaction. We will treat transactions made without
              presenting your physical card (such as for mail, telephone, Internet, or mobile device purchases)
              the same as if you used the Shifl Capital Card in person. If you let someone else use your FinTech
              Account, you are responsible for all transactions that person makes.
            </li>
            <li>
              <strong> Minimum Payment.</strong> You must pay us the payment amount listed on your Statement by
              the Due Date. In addition to the payment, you may pay all or part of the total balance on your Shifl
              Capital Card. But, you must still pay at least the minimum payment amount each month, even if you
              paid more than the minimum payment due on the previous Statement.
            </li>
            <li>
              <strong>Payment Methods.</strong> Your payment must be made in U.S. dollars from a U.S. deposit
              account. You must use one of the following payment methods:
              <ol style="list-style-type: lower-alpha;" data-mce-style="list-style-type: lower-alpha;">
                <li>
                  <strong>Preauthorized Automatic Payments (AutoPay</strong>). When you sign up, or by using
                  the App, you may elect to make payments on your Card(s) using AutoPay. If you select
                  AutoPay, you authorize us to make recurring debits from your Deposit Account or ACH
                  transfers from a Linked External Account you select, on each Due Date, in the amount of the
                  payment due (or another amount you select), as set forth on each Statement. You also
                  authorize us to debit or credit your selected payment method as needed to correct any
                  errors, process returned and reversed payments, and similar issues, to the extent permitted
                  by law.
                </li>
              </ol>
            </li>
            <li>
              <strong> One-Time Transfer.</strong> You may use the App to authorize us to make a one-time transfer
              to us from your Deposit Account or ACH transfers from a Linked External Account on or before each
              Due Date. You also authorize us to debit or credit your selected payment method to correct any
              errors, process returned and reversed payments, and similar issues, to the extent permitted by
              law.<br>We may permit additional payment methods from time to time. We may reject any payment not
              made in accordance with this section.
            </li>
            <li>
              <strong> Effect of Payment on Spending Limit.</strong> When you make a payment towards your Shifl
              Capital Card Balance from a payment method other than a transfer from your Shifl Capital Account,
              your Spending Limit on your Shifl Capital Account will increase in an amount equivalent to your
              payment. We may delay the change in your Spending Limit until we confirm that your payment has
              cleared. This may happen even if we credit your payment. If you have elected to make payments from
              the Shifl Capital Account associated with your Card, your Shifl Capital Account balance and your
              Spent Money will be reduced by the amount your payment, and your Spending Limit on the Shifl Capital
              Card will not increase.
            </li>
            <li>
              <strong> Payment Processing.</strong> We may accept and process payments without losing any of our
              rights. If your payment is returned unpaid for any reason, you authorize us to re-initiate the
              payment you authorized up to two additional times. We also reserve the right to debit the returned
              payment amount back to the balance on your Shifl Capital Card.
            </li>
            <li>
              <strong> How We Apply Your Payments.</strong> Payments are first applied to any past-due amount
              (except late fees), then to your current minimum payment due. Payments in excess of your minimum
              payment due will be applied to any outstanding interest and fees, then the remainder your Shifl
              Capital Card Balance. If you do have any past-due Shifl Capital Card Balance (other than late fees),
              any payment you make will first be applied to such past-due amounts, and any remaining amount will
              be applied to the remaining Shifl Capital Card Balance you selected for payment. We may adjust your
              Shifl Capital Card Balance, Shifl Capital Account balance, or Spending Limit as reasonably necessary
              to correct errors, process returned and reversed payments, and similar issues.
            </li>
            <li>
              <strong> Credit Balances.</strong> We may reject and return any payment that creates or adds to a
              credit balance on your Shifl Capital Card. Any credit balance we allow will not be available until
              we confirm that your payment has cleared. We may reduce the amount of any credit balance by any new
              charges. You may write to the address provided on your Statement or email us at
              <strong>hello@shifl.com</strong>
              to request a refund of any available credit balance greater than $1.00.
            </li>
          </ol>
        </li>
        <li>
          <strong> Your FinTech Card Billing Rights<br></strong>This section applies to billing errors with your Shifl
          Capital Card. If you believe there is an error related to a transaction to or from your Shifl Capital
          Account, please see the Electronic Fund Transfer Agreement.&nbsp; This notice tells you about your rights
          and our responsibilities under the Fair Credit Billing Act.<br>What To Do If You Find A Mistake On Your
          Statement. If you think there is an error on your Statement, write to us at:<br>You may also contact us at
          <strong><a href="mailto:hello@shifl.com">hello@shifl.com</a><br></strong>In your letter, give us the
          following information:
          <ul>
            <li>Account information: Your name and last 4 digits of your Shifl Capital Card Number.</li>
            <li>Dollar amount: The dollar amount of the suspected error.</li>
            <li>
              Description of Problem: If you think there is an error on your Statement, describe what you believe
              is wrong and why you believe it is a mistake.<br data-mce-bogus="1">
            </li>
          </ul>
        </li>
      </ol>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        You must contact us within 60 days after the
        error appeared on your Statement.&nbsp;
      </p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        To stop a scheduled payment on an amount you
        think is wrong, you must contact us at least three Business Days before the scheduled payment date.
      </p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        You must notify us of any potential errors in
        writing or electronically. You may call us, but if you do we are not required to investigate any potential
        errors and you may have to pay the amount in question.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        What Will Happen After We Receive Your
        Notice
      </p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        When we receive your notice, we must do two
        things:
      </p>
      <ol>
        <li style="list-style-type: none;">
          <ol>
            <li>
              Within 30 days of receiving your notice, we must tell you that we received your notice. We will also
              tell you if we have already corrected the error.
            </li>
            <li>
              Within 90 days of receiving your notice, we must either correct the error or explain to you why we
              believe the bill is correct.
            </li>
          </ol>
        </li>
      </ol>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        While we investigate whether or not there has
        been an error, the following are true:
      </p>
      <ul>
        <li style="list-style-type: none;">
          <ul>
            <li>We cannot try to collect the amount in question, or report you as delinquent on that amount.</li>
            <li>
              The charge in question may remain on your Statement, and we may continue to charge you interest on
              that amount. But, if we determine that we made a mistake, you will not have to pay the amount in
              question, or any interest or other fees related to that amount.
            </li>
            <li>
              While you do not have to pay the amount in question, you are responsible for the remainder of your
              Shifl Capital Card Balance.
            </li>
            <li>We can apply any unpaid amount against your Spending Limit.</li>
          </ul>
        </li>
      </ul>
      <p><br></p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        After we finish our investigation, one of two
        things will happen:
      </p>
      <ul>
        <li style="list-style-type: none;">
          <ul>
            <li>
              If we made a mistake: You will not have to pay the amount in question, or any interest or other fees
              related to that amount.
            </li>
            <li>
              If we do not believe there was a mistake: You will have to pay the amount in question, along with
              applicable interest and fees. We will send you a statement of the amount you owe, and the date
              payment is due. We may then report you as delinquent if you do not pay the amount, we think you owe.
            </li>
          </ul>
        </li>
      </ul>
      <p><br></p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        If you receive our explanation but still believe
        your bill is wrong, you must write to us within 10 days telling us that you still refuse to pay. If you do so,
        we cannot report you as delinquent without also reporting that you are questioning your bill. We must tell you
        the name of anyone to whom we reported you as delinquent, and we must let those organizations know when the
        matter has been settled between us.
      </p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        <br>
      </p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        If we do not follow all of the rules above, you
        do not have to pay the first $50 of the amount you question even if your bill is correct.
      </p>
      <p><br></p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        <strong>Your Rights If You Are Dissatisfied With
          Your Card Purchases.</strong> If you are dissatisfied with the goods or services that you have purchased with
        your Shifl Capital Card, and you have tried in good faith to correct the problem with the merchant, you may have
        the right not to pay the remaining amount due on the purchase.
      </p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        To use this right, all of the following must be
        true:
      </p>
      <ul>
        <li style="list-style-type: none;">
          <ul>
            <li>
              The purchase must have been made in your home state or within 100 miles of your current mailing
              address, and the purchase price must have been more than $50. (Note: Neither of these is necessary
              if your purchase was based on an advertisement we mailed to you, or if we own the company that sold
              you the goods or services.)
            </li>
            <li>
              You must have used your Shifl Capital Card for the purchase. Purchases made with cash advances from
              an ATM do not qualify.
            </li>
            <li>You must not yet have fully paid for the purchase.</li>
          </ul>
        </li>
      </ul>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        If all of the criteria above are met and you are
        still dissatisfied with the purchase, contact us in writing or electronically at the address listed above.
      </p>
      <p><br></p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        While we investigate, the same rules apply to
        the disputed amount as discussed above. After we finish our investigation, we will tell you our decision. At
        that point, if we think you owe an amount and you do not pay we may report you as delinquent.
      </p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        <br>
      </p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        If we provide you a credit for all or part of a
        disputed transaction, you give us all of your rights against others regarding that transaction. You will also:
        (a) give us any information about the disputed transaction, if we ask; (b) not pursue any claim or reimbursement
        of the transaction amount from the merchant or any other person; and (c) help us get reimbursement from others.
        If we reimburse you for unauthorized transactions, you will help us investigate, pursue and get reimbursement
        from the wrongdoer. Your help includes giving us documents in a form that we request.
      </p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        <br>
      </p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        <strong>No Warranties.</strong> We are not
        responsible for any claim you may have regarding the purchase of goods or services made with your Shifl Capital
        Card beyond your rights described in this section and on your Statement.
      </p>
      <p style="">
        <strong>&nbsp; &nbsp; &nbsp; &nbsp;</strong>F.<strong> Account Default</strong><br>
      </p>
      <ol>
        <li style="list-style-type: none;">
          <ol>
            <li>
              <strong>Events of Default.</strong> Your Shifl Capital Card will be in default if:
              <ul>
                <li>You do not make a minimum payment when it is due;</li>
                <li>Any required payment you make is rejected, not paid or cannot be processed;</li>
                <li>You exceed your Credit Limit;</li>
                <li>You file or become the subject of a bankruptcy or insolvency proceeding;</li>
                <li>
                  You are unable or unwilling to repay your obligations, including upon death or legally
                  declared incapacity;
                </li>
                <li>
                  We determine that you made a false, incomplete or misleading statement to us, or you
                  otherwise tried to defraud us;
                </li>
                <li>
                  You revoke your consent to receive records, disclosures, and other communications
                  electronically;
                </li>
                <li>You do not comply with any term of this Agreement or any other agreement with us;</li>
                <li>You relocate outside the United States; or</li>
                <li>
                  We receive a garnishment, attachment or other levy upon your Shifl Capital Account, or the
                  Shifl Capital Account is subject to any other legal proceeding.
                </li>
              </ul>
            </li>
            <li>
              <strong>Consequences of Default.</strong> If you are in default, we may take certain actions with
              respect to your Shifl Capital Account. For example, we may take the following actions, without
              notifying you, unless the law says that we must give you notice:
              <ul>
                <li>
                  Close or suspend one or more of your Shifl Capital Card(s), or your entire Shifl Capital
                  Account;
                </li>
                <li>Reduce your Credit Limit or Spending Limit;</li>
                <li>Demand that you immediately pay the Shifl Capital Card Balance;</li>
                <li>
                  Continue to charge you interest and fees (as set forth in the Truth in Lending Disclosures)
                  as long as your Shifl Capital Card Balance remains outstanding; and/or
                </li>
                <li>
                  File a lawsuit against you, or pursue another action that is not prohibited by law. If we
                  file a lawsuit, you agree to pay our court costs, expenses and attorney fees, unless the law
                  does not allow us to collect these amounts.
                </li>
              </ul>
            </li>
          </ol>
        </li>
      </ol>
      <p>&nbsp; &nbsp; &nbsp; G. <strong>Digital Wallet</strong></p>
      <ol>
        <li style="list-style-type: none;">
          <ol>
            <li>
              <strong>Adding a Card</strong>. You can add your Shifl Capital Card to a digital wallet by following
              the instructions of the digital wallet. The digital wallet may not be accepted in all places where
              your Shifl Capital Card is accepted. We may terminate the ability for you to add the Shifl Capital
              Card to a digital wallet at any moment.
            </li>
            <li>
              <strong> Remove Your Card from the Digital Wallet</strong>. You should contact the digital wallet
              provider to remove a Shifl Capital Card from the digital wallet. We can end or suspend your ability
              to use a Shifl Capital Card with the Digital Wallet at any time.
            </li>
            <li>
              <strong> Card Responsibility</strong>. You are solely responsible for maintaining the security of
              your digital wallet credentials (including user identification, password or other access
              credentials). If you share these credentials with any other person, you expressly authorize them to
              access your personal information, access your accounts and initiate charges to your Shifl Capital
              Card(s) using the digital wallet service.
            </li>
            <li>
              <strong>Fees</strong>. We currently do not impose a fee for using your Shifl Capital Card at a
              Digital Wallet, but we reserve the right to impose a fee in the future. Please note that a Digital
              Wallet provider or another third party enabling or associated with your use of a Digital Wallet may
              charge a fee for using your Shifl Capital Card(s) in a Digital Wallet.
            </li>
            <li>
              <strong> No Liability for the Digital Wallet</strong>. We are not provider of the digital wallet and
              we are not responsible for providing the digital wallet service to you. We are only responsible for
              supplying information securely to the digital wallet provider to enable usage of the Shifl Capital
              Card in such digital wallet. We are not responsible for any failure of the digital wallet or the
              inability to use the digital wallet for any transaction. We are not responsible for the performance
              or non-performance of the digital wallet provider or any other third parties regarding any agreement
              you enter into with the digital wallet provider or associated third‐ party relationships that may
              impact your use of the digital wallet. YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE DIGITAL
              WALLET SERVICE IS AT YOUR SOLE RISK, AND WE ARE NOT RESPONSIBLE FOR THE RISK AS TO SATISFACTORY
              QUALITY, PERFORMANCE, OR ACCURACY.
            </li>
            <li>
              <strong> Privacy &amp; Security</strong>. You agree that we may exchange your information with the
              Digital Wallet provider, a payment network (e.g., Visa and Mastercard), and others in order to
              enable your use of the Digital Wallet, make information available to you about your Shifl Capital
              Card transactions, and improve our ability to offer the Digital Wallet services. We do not control
              how the Digital Wallet provider or third parties use the information received in connection with the
              Digital Wallet and the use of such information is governed by such party’s privacy policy. We are
              not responsible for the security of the information provided to the Digital Wallet provider or
              stored in the Digital Wallet. We are not responsible if a security breach occurs that affects any
              information stored in the Digital Wallet or sent from a wallet.
            </li>
          </ol>
        </li>
      </ol>
      <p style="">
        &nbsp; &nbsp; &nbsp; H.<strong> Shifl Capital Card Cancellation, Suspension and Limits and Card
          Replacement</strong>
      </p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        We reserve the right, at any time and in our
        sole discretion, to limit your use of the Shifl Capital Card, including limiting or prohibiting specific types
        of transactions. The Bank may refuse to issue a Shifl Capital Card, revoke Shifl Capital Card privileges or
        cancel your Shifl Capital Card with or without cause or notice, other than as required by applicable law. If you
        would like to cancel the use of your Shifl Capital Card, you may do so by emailing us at <a
          href="mailto:hello@shifl.com" data-mce-href="mailto:hello@shifl.com">hello@shifl.com</a> or call the
        number on the back of your Shifl Capital Card. You agree not to use or allow others to use an expired, revoked,
        cancelled, suspended or otherwise invalid Shifl Capital Card. The cancellation of Shifl Capital Card privileges
        will not otherwise affect your rights and obligations under this Agreement. Not all services described in this
        Agreement are available to all persons or at all locations. We reserve the right to limit, at our sole
        discretion, the provision of any such services to any person or in any location. Any offer of a service in this
        Agreement shall be deemed void where prohibited. We can waive or delay enforcement of any of their rights under
        this Agreement without losing them.
      </p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        If you need to replace the Shifl Capital Card
        for any reason, send an email to hello@shifl.com to request a replacement. You will be required to provide
        personal information which may include the last four digits of your Shifl Capital Card number, your full name
        and knowledge of the Shifl Capital Card transaction history. If you believe the Shifl Capital Card has been lost
        or stolen, you may immediately disable it by emailing us at hello@shifl.com. We may cancel any Shifl Capital
        Card that has been disabled for sixty (60) calendar days or longer.
      </p>
      <ol>
        <li style="">
          <strong>Disclosure of Information to Third Parties<br></strong>By requesting, obtaining or using a
          Shifl Capital Card from us you agree that we may release information in our records regarding you and your
          Shifl Capital Card: (a) to comply with government agency or court orders; (b) to share your credit
          performance with credit reporting agencies and other creditors who we reasonably believe are or may be doing
          business with you on your Shifl Capital Card; (c) to provide information on your Shifl Capital Card to any
          third party who we believe is conducting an inquiry in accordance with the Federal Fair Credit Reporting
          Act; (d) to share information with our employees, agents or representatives performing work for us in
          connection with your Shifl Capital Card; or (e) as otherwise permitted by the Bank’s privacy policy. We
          provide a copy of the Bank’s privacy notice at the time your Shifl Capital Card associated Shifl Capital
          Account is established and annually thereafter. Our privacy policy is also available on our website at
          <a href="https://shifl.capital/legal">https://shifl.capital/legal</a>
        </li>
      </ol>
      <p><strong>IV. Arbitration</strong></p>
      <p>
        This Section sets forth the circumstances and procedures under which Claims (as defined below) shall be
        arbitrated instead of litigated in court upon the election of either party.
      </p>
      <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
        <li>
          <strong> Definitions:</strong> As used in this Arbitration Section, the term “Claim” means any claim,
          dispute or controversy between you and Bank, Program Manager or any of their agents or retailers, arising
          from or relating to the Shifl Capital Card or this Agreement as well as any related or prior agreement that
          you may have had with us or the relationships resulting from this Agreement, including the validity,
          enforceability or scope of this Arbitration Section or the Agreement. “Claim” includes claims of every kind
          and nature, including but not limited to initial claims, counterclaims, cross-claims and third-party claims
          and claims based upon contract, tort, fraud and other intentional torts, statutes, regulations, common law
          and equity. The term “Claim” is to be given the broadest possible meaning that will be enforced and
          includes, by way of example and without limitation, any claim, dispute or controversy that arises from or
          relates to (i) your Shifl Capital Card, or the Shifl Capital Cards of any additional cardholders designated
          by you; (ii) the amount of available funds on the Shifl Capital Cards; (iii) advertisements, promotions or
          oral or written statements related to the Shifl Capital Cards, goods or services purchased with the Shifl
          Capital Cards; (iv) the benefits and services related to the Shifl Capital Cards; and/or (v) your enrollment
          for any Shifl Capital Card. As used in this Arbitration Section, the terms “we” and “us” shall for all
          purposes mean the Bank, Program Manager, their respective wholly or majority owned subsidiaries, affiliates,
          licensees, predecessors, successors, and assigns, and all of their agents, employees, directors and
          representatives. In addition, “we” or “us” shall include any third party using or providing any product,
          service or benefit in connection with any Shifl Capital Cards (including, but not limited to merchants who
          accept the Shifl Capital Card, third parties who use or provide services, debt collectors and all of their
          agents, employees, directors and representatives) if, and only if, such third party is named as a co-party
          with us (or files a Claim with or against us) in connection with a Claim asserted by you. As solely used in
          this Arbitration Section, the terms “you” or “yours” shall mean all persons or entities approved by us to
          have and/or use a Shifl Capital Card, including but not limited to all persons or entities contractually
          obligated under any of the Agreements and all additional cardholders.
        </li>
        <li>
          <strong> Initiation of Arbitration Proceeding/Selection of Administrator:</strong> Any Claim shall be
          resolved, upon the election by you or us, by arbitration pursuant to this Arbitration Provision and the code
          of procedures of the national arbitration organization to which the Claim is referred in effect at the time
          the Claim is filed. Claims shall be referred to either Judicial Arbitration and Mediation Services (“JAMS”)
          or the American Arbitration Association (“AAA”), as selected by the party electing to use arbitration. If a
          selection by us of one of these organizations is unacceptable to you, you shall have the right within thirty
          (30) days after you receive notice of our election to select the other organization listed to serve as
          arbitrator administrator. For a copy of the procedures, to file a Claim or for other information about these
          organizations, contact them as follows: (i) JAMS at 1920 Main Street, Suite 300, Los Angeles, CA 92614;
          website at www.jamsadr.com; and (ii) AAA at 335 Madison Avenue, New York, NY 10017; website at www.adr.org.
        </li>
        <li>
          <strong> Significance of Arbitration:</strong> IF ARBITRATION IS CHOSEN BY ANY PARTY WITH RESPECT TO A
          CLAIM, NEITHER YOU NOR WE WILL HAVE THE RIGHT TO LITIGATE THAT CLAIM IN COURT OR HAVE A JURY TRIAL ON THAT
          CLAIM, OR TO ENGAGE IN DISCOVERY EXCEPT AS PROVIDED FOR IN THE CODE OF PROCEDURES OF JAMS OR AAA, AS
          APPLICABLE (THE “CODE”). FURTHER, YOU WILL NOT HAVE THE RIGHT TO PARTICIPATE IN A REPRESENTATIVE CAPACITY OR
          AS A MEMBER OF ANY CLASS OF CLAIMANTS PERTAINING TO ANY CLAIM SUBJECT TO ARBITRATION. THE ARBITRATOR SHALL
          NOT CONDUCT A CLASS ARBITRATION OR A JOINT ARBITRATION, EXCEPT AS SET FORTH BELOW. THE ARBITRATOR’S DECISION
          WILL BE FINAL AND BINDING. NOTE THAT OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT ALSO MAY NOT BE
          AVAILABLE IN ARBITRATION.
        </li>
        <li>
          <strong> Restrictions on Arbitration:</strong> If either party elects to resolve a Claim by arbitration,
          that Claim shall be arbitrated on an individual basis. There shall be no right or authority for any Claims
          to be arbitrated on a class action basis or on bases involving Claims brought in a purported representative
          capacity on behalf of the general public, other cardholders or other persons similarly situated. The
          arbitrator’s authority to resolve Claims is limited to Claims between you and us alone, and the arbitrator’s
          authority to make awards is limited to you and us alone. Furthermore, Claims brought by you against us or by
          us against you may not be joined or consolidated in arbitration with Claims brought by or against someone
          other than you, unless otherwise agreed to in writing by all parties.
        </li>
        <li>
          <strong> Location of Arbitration/Payment of Fees:</strong> Any arbitration shall take place in Memphis,
          Tennessee, unless otherwise agreed in writing by the parties. At your written request, we will consider in
          good faith making a temporary advance of all or part of the filing administrative and/or hearing fees for
          any Claim you initiate as to which you or we seek arbitration. At the conclusion of the arbitration (or any
          appeal thereof), the arbitrator (or panel) will decide who will ultimately be responsible for paying the
          filing, administrative and/or hearing fees in connection with the arbitration (or appeal). If and to the
          extent you incur filing, administrative and/or hearing fees in arbitration, including for any appeal,
          exceeding the amount they would have been if the Claim had been brought in the state or federal court which
          is closest to your billing address and would have had jurisdiction over the Claim, we will reimburse you to
          that extent unless the arbitrator (or panel) determines that the fees were incurred without any substantial
          justification.
        </li>
        <li>
          <strong> Arbitration Procedures:</strong> This Arbitration Section is made pursuant to a transaction
          involving interstate commerce, and shall be governed by the Federal Arbitration Act, 9 U.S.C. Sections 1-16,
          as it may be amended (the “FAA”). The arbitration shall be governed by the applicable Code, except that (to
          the extent enforceable under the FAA) this Arbitration Section shall control if it is inconsistent with the
          applicable Code. The arbitrator shall apply applicable substantive law consistent with the FAA and
          applicable statutes of limitations and shall honor claims of privilege recognized at law and, at the timely
          request of either party, shall provide a brief written explanation of the basis for the decision. In
          conducting the arbitration proceeding, the arbitrator shall not apply the Federal or any state rules of
          civil procedure or rules of evidence. Either party may submit a request to the arbitrator to expand the
          scope of discovery allowable under the applicable Code. The party submitting such a request must provide a
          copy to the other party, who may submit objections to the arbitrator with a copy of the objections provided
          to the request party, within fifteen (15) days of receiving the requesting party’s notice. The granting or
          denial of such request will be in the sole discretion of the arbitrator who shall notify the parties of
          his/her decision within twenty (20) days of the objecting party’s submission. The arbitrator shall take
          reasonable steps to preserve the privacy of individuals, and of business matters. Judgment upon the award
          rendered by the arbitrator may be entered in any court having jurisdiction. The arbitrator’s decision will
          be final and binding, except for any right of appeal provided by the FAA. However, any party can appeal that
          award to a three-arbitrator panel administered by the same arbitration organization, which shall consider
          anew any aspect of the initial award objected to by the appealing party. The appealing party shall have
          thirty (30) days from the date of entry of the written arbitration award to notify the arbitration
          organization that it is exercising the right of appeal. The appeal shall be filed with the arbitration
          organization in the form of a dated writing. The arbitration organization will then notify the other party
          that the award has been appealed. The arbitration organization will appoint a three-arbitrator panel which
          will conduct arbitration pursuant to its Code and issue its decision within one hundred twenty (120) days of
          the date of the appellant’s written notice. The decision of the panel shall be by majority vote and shall be
          final and binding.
        </li>
        <li>
          <strong> Continuation:</strong> This Arbitration Section shall survive termination of your Shifl Capital
          Card as well as voluntary payment of the debt in full by you, any legal proceeding by us to collect a debt
          owed by you, and any bankruptcy by you or us. If any portion of this Arbitration Section is deemed invalid
          or unenforceable under any principle or provision of law or equity, consistent with the FAA, it shall not
          invalidate the remaining portions of this Arbitration Section, the Agreement or any prior agreement you may
          have had with us, each of which shall be enforceable regardless of such invalidity.
        </li>
      </ol>
      <p><strong>V. Other Terms</strong></p>
      <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
        <li>
          <strong> The Card and your obligations under this Agreement may not be assigned.</strong> The Bank may
          transfer its rights under this Agreement. Use of the Shifl Capital Card is subject to all applicable rules
          and customs of any clearinghouse or other association involved in transactions. The Bank does not waive its
          rights by delaying or failing to exercise them at any time. If any provision of this Agreement shall be
          determined to be invalid or unenforceable under any rule, law, or regulation of any governmental agency,
          local, state, or federal, the validity or enforceability of any other provision of this Agreement shall not
          be affected. This Agreement will be governed by the law of the State of Tennessee except to the extent
          governed by federal law.
        </li>
        <li>
          By activating the Shifl Capital Card or by retaining, using or authorizing the use of it, you represent and
          warrant to us that: (i) you are at least 18 years of age (or older if you reside in a jurisdiction where the
          majority age is older); (ii) you are a U.S. citizen or legal alien residing in the fifty (50) states of the
          United States (“U.S.”) or the District of Columbia; (iii) you have provided us with a verifiable U.S. street
          address (not a P.O. Box); (iv) the personal information you provide to us in connection with the Shifl
          Capital Card is true, correct and complete; (v) you received a copy of this Agreement and agree to be bound
          by and to comply with its terms; and (vi) you accept the Shifl Capital Card.
        </li>
        <li>
          The terms and conditions of this Agreement may be revised by posting a revised version here
          <a href="https://shifl.capital/legal">https://shifl.capital/legal</a>. You will be notified of any change in
          the manner provided by applicable law prior to
          the effective date of the change. However, if the change is made for security purposes, the Bank or Program
          Manager can implement such change without prior notice. We may cancel or suspend the Shifl Capital Card or
          this Agreement at any time. You may cancel this Agreement by returning the Shifl Capital Card to the Bank.
          Your termination of this Agreement will not affect any of the Bank’s or Program Manager’s rights or your
          obligations arising under this Agreement prior to termination. In the event your Shifl Capital Card is
          cancelled, closed or terminated for any reason, any remaining available funds associated with the Shifl
          Capital Account will be returned to you, subject to any contrary provision in this Agreement and applicable
          law.
        </li>
      </ol>
      <p><br></p>
      <!--  END OF VISA CREDIT CARD AGREEMENT BUSINESS  -->
      <!--  VISA CREDIT CARD AGREEMENT CONSUMER  -->
      <h1><strong>Shifl Capital Visa Credit Card Agreement</strong></h1>
      <p>
        Before you execute this agreement, please read through these Agreement Disclosures for eligibility, fee, and
        other important information. Please keep this Shifl Capital Secured Charge Card Agreement for your records. The
        Shifl Capital Visa® Credit Card (“Card”) is offered by Evolve Bank &amp; Trust, Member FDIC (“Bank”), and is
        distributed and serviced by Shifl Capital service provider Solid Financial Technologies, Inc. (“Shifl Capital”)
        on behalf of the Bank. “We”, “us” and our” means Bank and its successors and assigns.
      </p>
      <ol style="list-style-type: upper-roman;" data-mce-style="list-style-type: upper-roman;">
        <li>
          <strong>Truth In Lending Disclosures<br></strong><em><em>IMPORTANT PRICING INFORMATION ABOUT YOUR SHIFL
              CAPITAL CARD<br></em></em>
          <table style="width: 486px;" width="636" class="mce-item-table">
            <tbody>
              <tr>
                <td colspan="2" width="636" style="width: 457.812px;">
                  <p>
                    <strong>Shifl Capital Truth in Lending
                      Disclosures</strong>
                  </p>
                  <p><em>Shifl Capital will apply the following fees or interest: </em></p>
                </td>
              </tr>
              <tr>
                <td width="288" style="width: 119.109px;">
                  <p><strong>Type</strong></p>
                </td>
                <td width="348" style="width: 338.703px;">
                  <p><strong>Interest Rate or Fee</strong></p>
                </td>
              </tr>
              <tr>
                <td width="288" style="width: 119.109px;">
                  <p>
                    Annual Percentage Rate (APR) for Purchases and Cash
                    Advances
                  </p>
                </td>
                <td width="348" style="width: 338.703px;">
                  <p>0.0%</p>
                </td>
              </tr>
              <tr>
                <td width="288" style="width: 119.109px;">
                  <p>Penalty APR and When it Applies</p>
                </td>
                <td width="348" style="width: 338.703px;">
                  <p>0.0%</p>
                  <p>
                    This APR (the Penalty APR) may be applied to your account if you do not pay the minimum
                    payment due, make a late payment, or your payment is returned and you fail to make a
                    successful payment by the Due Date. Your entire balance will be subject to the Penalty
                    APR.
                  </p>
                  <p>
                    How Long Will the Penalty APR Apply?: If your APR is increased for any of these reasons, the
                    Penalty APR may apply until you make all your required payments and you have no amount past
                    due.
                  </p>
                </td>
              </tr>
              <tr>
                <td width="288" style="width: 119.109px;">
                  <p>How to Avoid Paying Interest</p>
                  <p><strong>&nbsp;</strong></p>
                </td>
                <td width="348" style="width: 338.703px;">
                  <p>
                    Your Due Date is at least 21 days after the close of
                    each Cycle. We will not charge you interest on purchases or cash advances if you pay your entire
                    balance by the Due Date each month.
                  </p>
                </td>
              </tr>
              <tr>
                <td width="288" style="width: 119.109px;">
                  <p>Minimum Interest Charge</p>
                  <p><strong>&nbsp;</strong></p>
                </td>
                <td width="348" style="width: 338.703px;">
                  <p>None</p>
                </td>
              </tr>
              <tr>
                <td width="288" style="width: 119.109px;">
                  <p>
                    For Credit Card Tips from the Consumer Financial
                    Protection Bureau
                  </p>
                </td>
                <td width="348" style="width: 338.703px;">
                  <p>
                    To learn more about the factors to consider when
                    applying for or using a credit card, visit the website of the Consumer Financial Protection
                    Bureau at <a href="http://www.consumerfinance.gov/learnmore"
                      data-mce-href="http://www.consumerfinance.gov/learnmore"><strong>http://www.consumerfinance.gov/learnmore</strong></a>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <br>
          <table style="width: 488px;" width="636" class="mce-item-table">
            <tbody>
              <tr>
                <td colspan="2" width="636" style="width: 459.969px;">
                  <p><strong>Card Services Fees</strong></p>
                  <p>
                    <em>Shifl Capital will assess the Card procurement fees listed below at any time after Client
                      submits its order request to Shifl Capital.</em>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><strong>Type</strong></p>
                </td>
                <td width="348" style="width: 222.016px;">
                  <p><strong>Fee</strong></p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><strong>Initial Card Procurement Fee</strong></p>
                  <p><br></p>
                </td>
                <td width="348" style="width: 222.016px;">
                  <p>
                    $0.00 per physical card with a magnetic stripe and EMV
                    chip.
                  </p>
                  <p>Additional service fees may apply for the type of postage.</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><strong>Replacement Card Procurement Fee</strong></p>
                  <p><br></p>
                </td>
                <td width="348" style="width: 222.016px;">
                  <p>
                    $5.00 per physical card with a magnetic stripe and EMV
                    chip. Additional service fees may apply for the type of postage.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><strong>Cross-border Fee</strong></p>
                  <p><br></p>
                </td>
                <td width="348" style="width: 222.016px;">
                  <p>
                    1.00% of the Transaction Amount + $0.30, per
                    cross-border Transaction.
                  </p>
                  <p><br></p>
                  <p>
                    Applied where an Authorized Client makes a purchase in a country other than the United
                    States.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><strong>Foreign exchange Fee</strong></p>
                  <p><br></p>
                </td>
                <td width="348" style="width: 222.016px;">
                  <p>1.00% of the Transaction Amount</p>
                  <p><br></p>
                  <p>
                    Applied in each instance where Shifl Capital converts funds from one currency to another.
                    This calculation is made on the converted amount.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Disputes Fee or Late Payment or Returned
                      Payment </strong>
                  </p>
                </td>
                <td width="348" style="width: 222.016px;">
                  <p>$15 per occurrence</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Cash Advance Fee (Out of Network ATM
                      Withdrawals)*</strong>
                  </p>
                </td>
                <td width="348" style="width: 222.016px;">
                  <p>$2.50</p>
                </td>
              </tr>
            </tbody>
          </table>
          <br><strong>*</strong>If you use an ATM that is not a MoneyPass ATM for any transaction, including a Cash
          Advance or balance inquiry, you may be charged a fee by the ATM operator even if you do not complete a
          transaction. This ATM fee is a third-party fee amount assessed by the individual ATM operator only and is
          not assessed by us. This ATM fee amount will be charged to your Credit Account.
          <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
            <li>
              <strong>Billing Rights<br></strong>Information on your rights to dispute transactions and how to
              exercise those rights is provided in this Cardholder Agreement.
            </li>
            <li>
              <strong> How We Will Calculate Your Balance<br></strong>We figure the interest charge each Cycle by
              applying the periodic rate to the average daily balance (including new purchases) on the Card. To
              get the average daily balance, we take the beginning balance on the Card each day of the Cycle, add
              any new purchases, cash advances, and/or fees, and subtract any unpaid interest or other finance
              charges and any payments or credits. This gives us the daily balance. Then, we add up all the daily
              balances for the Cycle and divide the total by the number of days in the Cycle. This gives us the
              average daily balance. The interest charge calculated for each Card will be added to the balance on
              that Card, and to the total Shifl Capital Card Balance for your Shifl Capital Account, at the end of
              each Cycle.
            </li>
            <li>
              <strong> Secured Credit (Charge) Card Account<br></strong>Your Shifl Capital Card is secured by a
              deposit account (“Shifl Capital Account”) that you are required to establish and maintain in order
              to have the Shifl Capital Card. Funds from your Shifl Capital Account will be used to pay down your
              balance on your Shifl Capital Card. <br>You may never spend more than the amount you have in your
              Shifl Capital Account with your Shifl Capital Card.&nbsp; We refer to this limit as the Spending
              Limit.&nbsp; Your Shifl Capital Card is also subject to a maximum Credit Limit.&nbsp; See Section
              III.A.2 for more information.&nbsp; Your obligation to pay us back for all Card transactions is
              secured by your Shifl Capital Account. If you do not pay us back for any amount you owe us when your
              payments are due, we may exercise our interest and debit your Shifl Capital Account and you can lose
              the money in your Shifl Capital Account.&nbsp; See the Security Agreement section for more
              information.&nbsp; You are responsible for all activity conducted on your Shifl Capital Card.
            </li>
            <li>
              <strong>The Shifl Capital Card and associated Shifl Capital Account Are Only Available
                Electronically<br></strong>This Shifl Capital Card and the Shifl Capital Account are only available
              electronically. By applying for the Shifl Capital Card and Shifl Capital Account you agreed to
              receive all disclosures electronically. See the Shifl Capital Electronic Communications Consent
              agreement.&nbsp; If you do not have the systems needed to receive disclosures electronically, we
              cannot provide this Shifl Capital Card or the Shifl Capital Account to you.
            </li>
            <li>
              <strong> Negative Credit Reports<br></strong>You are hereby notified that a negative credit report
              reflecting on your credit record may be submitted to a credit reporting agency if you fail to
              fulfill the terms of your credit obligations.
            </li>
            <li>
              <strong> State Disclosures</strong>
              <ul>
                <li>
                  <strong>California Residents:</strong> After credit approval, each applicant shall have the
                  right to use the Shifl Capital Card up to the limit of the account. Each applicant may be
                  liable for amounts extended under the plan to any joint applicant.
                </li>
                <li>
                  <strong>Kentucky Residents:</strong> You may pay the unpaid balance of your Shifl Capital
                  Card in whole or in part at any time.
                </li>
                <li>
                  <strong>New Jersey Residents:</strong> Because certain provisions of this Agreement are
                  subject to applicable law, they may be void, unenforceable or inapplicable in some
                  jurisdictions. None of these provisions, however, is void, unenforceable or inapplicable in
                  New Jersey.
                </li>
                <li>
                  <strong>New York, Rhode Island and Vermont Residents:</strong> We may obtain a consumer
                  credit report for any legitimate purpose in connection with your Shifl Capital Card or your
                  application, including but not limited to reviewing, modifying, renewing and collecting on
                  your Shifl Capital Card. Upon your request, we will inform you of the names and addresses of
                  any credit reporting agencies that have furnished the reports.
                </li>
                <li>
                  <strong>Ohio Residents:</strong> The Ohio laws against discrimination require that all
                  creditors make credit equally available to all creditworthy customers, and that credit
                  reporting agencies maintain separate credit histories on each individual upon request. The
                  Ohio Civil Rights Commission administers compliance with this law.
                </li>
                <li>
                  <strong>Married Wisconsin Residents:</strong> If you are married, by submitting your Shifl
                  Capital Card application you are confirming that this Shifl Capital Card obligation is being
                  incurred in the interest of your marriage and your family. No provision of a marital
                  property agreement, unilateral statement under Section 766.59 of the Wisconsin Statutes, or
                  court order under Section 766.70 of the Wisconsin Statutes adversely affects the interest of
                  the creditor unless the creditor, prior to the time the credit is granted, is furnished a
                  copy of the agreement, statement or decree or has actual knowledge of the adverse provision
                  when the obligation to the creditor is incurred. If the Shifl Capital Card for which you are
                  applying is granted, you will notify the Bank if you have a spouse who needs to receive
                  notification that credit has been extended to you.
                </li>
              </ul>
            </li>
            <li>
              <strong> Military Annual Percentage Rate Disclosures<br></strong>Federal law provides important
              protections to active-duty members of the Armed Forces and their dependents relating to extensions
              of consumer credit. In general, the cost of consumer credit to a member of the Armed Forces and his
              or her dependent may not exceed an annual percentage rate of 36 percent. This rate must include, as
              applicable to the credit transaction or account: The costs associated with credit insurance
              premiums; fees for ancillary products sold in connection with the credit transaction; any
              application fee charged (other than certain application fees for specified credit transactions or
              accounts); and any participation fee charged (other than certain participation fees for a Shifl
              Capital Card account).&nbsp; For more information contact <a
                href="mailto:hello@shifl.com">hello@shifl.com</a>
            </li>
          </ol>
        </li>
        <li>
          <strong> Security Agreement</strong>
          <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
            <li>
              <strong /><strong> Our Security Interest in Your Shifl Capital Account</strong><strong>&nbsp;
                &nbsp;<br></strong>You hereby grant to us, as of the date you enter into this Agreement, a security
              interest in all right, title and interest in any Shifl Capital Account you have with us, and all
              funds deposited therein, now owned or hereinafter acquired (collectively, the Collateral), to secure
              your performance under this Agreement, including your duty to pay us for all obligations you owe us
              under this Agreement, present or hereinafter occurring, when payments are due, for every transaction
              made with your Shifl Capital Account by you.
            </li>
            <li>
              <strong> Our Right to Exercise the Security Interest<br></strong>You irrevocably and unconditionally
              relinquish possession and control over the Collateral, and you pledge and assign as security to us
              all of your right, title, and interest in it. You must take any action we request to perfect or
              protect our first lien position Security Interest in the Collateral. You waive the benefit of any
              homestead or other exemptions in the Collateral. The Security Interest will be governed by Uniform
              Commercial Code - Article 9 (as adopted by the applicable state law) whether Article 9 applies by
              its terms or not. We do not have to give you any prior notice to apply the funds in your Deposit
              Account or other Collateral or its proceeds to satisfy your obligations. You expressly agree that
              our rights under this Security Agreement extend to any electronically deposited federal or state
              benefit payments (including Social Security benefits) to the extent permitted by law. If you do not
              want your benefits applied in this way, you may change your direct deposit instructions at any time
              with the person or organization paying the benefits. In addition, you grant us a right of setoff to
              your Shifl Capital Account to secure all amounts you owe us under this Agreement. This right of
              setoff does not apply to your Shifl Capital Account if prohibited by law. You agree to hold us
              harmless from any claim arising as a result of our exercise of our right of setoff or Security
              Interest.
            </li>
            <li>
              <strong> What Happens When You Spend Money<br></strong>When you make a transaction on a Shifl
              Capital Card, an equivalent amount of funds in the Shifl Capital Account will be classified as Spent
              Money. The Spent Money will remain in your Shifl Capital Account, but you will not earn interest on
              it. When you make a payment on your Shifl Capital Card, an amount of the Spent Money equal to the
              amount of the payment will no longer be classified as Spent Money. You authorize us to limit your
              ability to withdraw or transfer all or part of the funds in your Shifl Capital Account, up to the
              amount of funds classified as Spent Money, as reasonably necessary to ensure that your Shifl Capital
              Card Balance (defined below in Section III.A.2) and total of other transactions on your Shifl
              Capital Account do not exceed the Available Balance (defined below in Section III.A.2) in your Shifl
              Capital Account at any given time, and to preserve our rights under this Security Agreement.
            </li>
            <li>
              <strong> YOU MAY LOSE FUNDS IN YOUR Shifl Capital ACCOUNT<br></strong>If you are in default of any
              obligation under this Agreement, or your Shifl Capital Account is closed for any reason, you
              authorize us to withdraw funds from your Shifl Capital Account and apply such amounts to the balance
              you owe us without sending you notice or demand for payment. We may do this in addition to the other
              rights we have under law or this Agreement. The application of your funds to the balance you owe
              under this Agreement will not affect your obligation to pay us in full. You are responsible for the
              repayment of all amounts you owe us under this Agreement.
            </li>
            <li>
              <strong> Legal Proceedings<br></strong>You represent that (a) there are no current lawsuits or
              bankruptcy proceedings that might affect our interest in your Shifl Capital Account; and (b) you
              have not and will not attempt to transfer any interest in your Deposit Account to any other person
              or offer your Shifl Capital Account as collateral or security for any other obligation. If any
              person seeks to attach your Shifl Capital Account, for example by legal garnishment, you agree that
              we may deem all amounts you owe us, including any unpaid Shifl Capital Card Balance (defined below
              in Section III.A.2), immediately payable and apply the funds in your Shifl Capital Account as
              payment of those amounts. If we must hire an attorney to defend or enforce our rights under the
              Security Agreement, you will pay our reasonable attorneys' fees and court costs, unless prohibited
              by law.
            </li>
          </ol>
        </li>
        <li>
          <strong>Shifl Capital CARD TERMS</strong>
          <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
            <li>
              <strong /><strong>A Shifl Capital Card is a credit card. It is not a debit card, and you do
                not access the money in your Shifl Capital Account when you use a Shifl Capital Card. All credit we
                extend to you is secured by your Shifl Capital Account. If you do not pay us back for transactions
                you make with your Shifl Capital Card(s) when your payments are due, we may exercise our Security
                Interest and withdraw the amount you owe us from your Shifl Capital Account. You can lose the money
                in your Shifl Capital Account. Refer to the Security Agreement section (II) for more
                information.</strong>
              <ol>
                <li>
                  <strong> Your Shifl Capital Card<br></strong>The following rules apply to your use of a
                  Shifl Capital Card
                  <ul>
                    <li>You must sign the Shifl Capital Card when you receive it</li>
                    <li>You must return the Shifl Capital Card to us or destroy it if we ask you to.</li>
                    <li>
                      You must take reasonable action to prevent the unauthorized use of your Shifl
                      Capital
                    </li>
                    <li>
                      We are not responsible for any losses you incur if anyone refuses to accept your
                      Shifl Capital Card for any reason.
                    </li>
                    <li>We may decline to authorize a transaction for any reason.</li>
                    <li>
                      We are not responsible for any losses you incur if we do not authorize a
                      transaction.
                    </li>
                    <li>
                      You must not use, or try to use, the Shifl Capital Card for any illegal activity.
                    </li>
                    <li>
                      You will only use your Shifl Capital Card for personal use, not business purposes.
                    </li>
                    <li>
                      We are not liable for any losses that may result when our services are unavailable
                      due to reasons beyond our control.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Credit Limit and Available Credit</strong>
                  <ul>
                    <li>
                      <strong><em>Credit Limit</em></strong><strong>.</strong> We will assign a Credit
                      Limit to your Shifl Capital Card Account. A Credit Limit is the maximum amount of
                      credit we may extend to you when you use your Shifl Capital Card(s). The total
                      outstanding unpaid balance you owe on your Shifl Capital Card(s) (Shifl Capital Card
                      Balance) may never be greater than your Credit Limit. You are responsible for
                      keeping track of your Shifl Capital Card Balance.
                    </li>
                    <li>
                      <strong><em>Spending Limit</em></strong><strong>.</strong> All transactions on your
                      Shifl Capital Card(s) are subject to the applicable Spending Limit.
                    </li>
                    <li>
                      <strong><em>Available Credit</em></strong><strong>.</strong> Your Available Credit
                      is the amount of credit that is available for you to make transactions with your
                      Shifl Capital Card(s) at any given time. Your Available Credit is equal to the
                      Available Balance in your Shifl Capital Account, minus the Spent Money, up to your
                      Credit Limit.
                    </li>
                    <li>
                      <strong><em>Rules</em></strong><strong>.</strong> You can use the App or email us at
                      <strong>hello@shifl.com</strong> to view your Shifl Capital Card Balance, Spending
                      Limit, and Available Credit at any time. While we do our best to provide the most
                      up-to-date information about your Shifl Capital Account, there may be some delay in
                      reflecting new transactions, and we cannot guarantee that the accuracy of this
                      information in the App and by phone. You are responsible for keeping track of your
                      Shifl Capital Card Balance, Spending Limit, and Available Credit. Our general
                      practice is to decline transactions that will cause you to exceed your Credit Limit,
                      Spending Limit or Available Credit. If we do honor these transactions, you are
                      responsible for paying us back in full immediately, and these limits will not
                      increase. We may increase or decrease your Credit Limit, Spending Limit, or
                      Available Credit at any time, in our sole discretion. We are not required to extend
                      credit up to your maximum Credit Limit, if it would cause you to exceed your
                      Available Credit or your Spending Limit.
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <strong> Using Your Shifl Capital Card</strong>
              <ol style="">
                <li>
                  <strong> Personal Use Only. </strong>You agree that the Shifl Capital Card will be used
                  primarily for personal, family and household purposes only and not for business use. You may
                  not use the Shifl Capital Card for illegal gambling or any other illegal transaction. We
                  reserve the right to cancel, close or restrict use of the Shifl Capital Card and Shifl
                  Capital Account, including by refusing the processing of any transaction, if we believe you
                  have violated this Agreement or any applicable law.
                </li>
                <li>
                  <strong> Point of Sale Purchases. </strong>You may use your Shifl Capital Card to make
                  purchases at points-of-sale.
                </li>
                <li>
                  <strong> Using a PIN. </strong>We may give you a personal identification number (PIN). For
                  security reasons, you may have to provide the PIN before you are able to use your Shifl
                  Capital Card. Keep your PIN secure. Do not write it down, give it to anyone, or keep it with
                  your Shifl Capital Card. If you lose your Shifl Capital Card or believe the confidentiality
                  of your PIN has been compromised for any reason, you must contact us immediately.
                </li>
                <li>
                  <strong> Foreign Transactions. </strong>The Shifl Capital Card may only be used to conduct
                  transactions within the 50 United States, District of Columbia and U.S. Territories (Puerto
                  Rico, Guam, Northern Marianas, the U.S. Virgin Islands, and American Samoa). The Shifl
                  Capital Card may be used to conduct international transactions, including, without
                  limitation, transactions conducted on international merchant websites or mobile applications
                  accessible within the United States; provided the merchant accepts the Visa or any other
                  logo on the Shifl Capital Card. All debits to your Shifl Capital Account will be posted in
                  U.S. dollars. Transactions made in a foreign currency are converted into U.S. dollar amounts
                  by Visa, using its then current currency conversion procedure and rate. The currency
                  conversion rate used on the processing date may differ from the rate in effect on the
                  transaction date or periodic statement posting date. Foreign transactions are subject to
                  foreign transaction fees, as set forth in Exhibit 1, and will be included in the amount
                  charged to your Shifl Capital Card.
                </li>
                <li>
                  <strong> Card Not Present Transactions.</strong> If you initiate a transaction without
                  presenting your Shifl Capital Card (such as for a mail order, internet or telephone purchase
                  or an ACH debit), the legal effect will be the same as if you used the Shifl Capital Card
                  itself. For security reasons, the amount or number of such transactions you may make may be
                  limited.
                </li>
                <li>
                  <strong> Rewards.</strong> Your Shifl Capital Card may provide you with the opportunity to
                  earn rewards. If it does, we will separately provide you with information and terms about
                  the rewards.
                </li>
                <li>
                  <strong> Lost or Stolen Card.</strong> If your Card is lost or stolen or if you think
                  someone else may be using your Shifl Capital Account, Card, or Access Credentials without
                  your permission, you must contact us immediately at <a href="mailto:hello@shifl.com"
                    data-mce-href="mailto:hello@shifl.com"><strong>hello@shifl.com</strong></a>
                </li>
                <li>
                  <strong>Returns and Refunds.</strong> If you are entitled to a refund for any reason for
                  goods or services obtained with the Shifl Capital Card, you agree to accept credits to your
                  Shifl Capital Account for refunds and agree to the refund policy of the merchant. Neither
                  the Bank nor Program Manager is responsible for the delivery, quality, safety, legality or
                  any other aspects of the goods or services you purchase from others with the Shifl Capital
                  Card. If you have a problem with a purchase you made with the Shifl Capital Card, or if you
                  have a dispute with the merchant, you must handle it directly with the merchant. The amounts
                  credited to your Shifl Capital Card for refunds may not be available for up to five (5) days
                  from the date the refund transaction occurs.
                </li>
                <li>
                  <strong> Authorized Users. </strong>You are liable for all transactions made with your Shifl
                  Capital Card or Shifl Capital Account, and all related fees or expenses incurred, by you,
                  all Authorized Users, and any other person that you or an Authorized User permits to use or
                  have access to your Shifl Capital Account, Shifl Capital Card, or any other Access
                  Credential.
                </li>
                <li>
                  <strong>Daily Transaction Limits. </strong>In addition to your Shifl Capital Card Credit
                  limit and Spending limit, your Shifl Capital Card has the following daily transaction
                  limits.<br><br>
                  <table class="mce-item-table">
                    <tbody>
                      <tr>
                        <td width="270">
                          <p><strong>Transaction Type</strong></p>
                        </td>
                        <td width="258">
                          <p><strong>Dollar Limit per Day</strong></p>
                        </td>
                      </tr>
                      <tr>
                        <td width="270">
                          <p>Points of Sales (Merchants) Transactions</p>
                        </td>
                        <td width="258">
                          <p>$1,000 daily</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="270">
                          <p>ATM Cash Advance (Withdrawal)</p>
                        </td>
                        <td width="258">
                          <p>$500 daily</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ol>
            </li>
            <li>
              <strong>Interest and Fees</strong>
              <ol style="">
                <li>
                  <strong>Interest and Fees.</strong> We will charge interest and fees to as disclosed in the
                  Truth in Lending Disclosures of this Agreement, subsequent disclosures, Statements, any
                  change in terms notices, and in the Fee Schedule provided with this Agreement. In general,
                  interest begins to accrue from the day a transaction occurs. However, we will not charge you
                  interest on any new transactions if you pay the total Shifl Capital Card Balance you owe on
                  your Shifl Capital Account in full by the Due Date on your Statement each month. If you miss
                  a required payment, make a late payment, or a payment is returned and you fail to
                  successfully make the required payment by the Due Date, the Penalty APR may apply to your
                  entire Shifl Capital Card Balance (including amounts that are not past due), except for late
                  fees, until you pay all overdue amounts, except as prohibited by law.
                </li>
                <li>
                  <strong> Balance Computation Method.</strong> For each Shifl Capital Card associated with
                  your Shifl Capital Account, we figure the interest charge each Cycle by applying the
                  periodic rate to the average daily balance (including new purchases) on the Shifl Capital
                  Card. To get the average daily balance, we take the beginning balance on the Shifl Capital
                  Card each day of the Cycle, add any new purchases, cash advances, and/or fees, and subtract
                  any unpaid interest or other finance charges and any payments or credits. This gives us the
                  daily balance. Then, we add up all the daily balances for the Cycle and divide the total by
                  the number of days in the Cycle. This gives us the average daily balance. The interest
                  charge calculated for each Card will be added to the balance on that Shifl Capital Card, and
                  to the total Shifl Capital Card Balance for your Shifl Capital Account, at the end of each
                  Cycle.
                </li>
                <li>
                  <strong> Late Payment Fee.</strong> We may charge you a late payment fee if we do not
                  receive your payment as instructed on your Statement by the Due Date. The amount of the late
                  payment fee is set forth in the Fee Schedule.
                </li>
                <li>
                  <strong> Returned Payment Fee.</strong> We may charge you a returned payment fee each time
                  your payment to us is returned unpaid for any reason. The amount of the returned payment fee
                  is set forth in the Fee Schedule.
                </li>
                <li>
                  <strong> Transactions Made in Foreign Currencies.</strong> If you make a transaction in a
                  foreign currency, the payment network will convert it into a U.S. dollar amount. The payment
                  network will use its own currency conversion procedures. The conversion rate in effect on
                  the processing date may differ from the rate in effect on the transaction date that appears
                  on your Statement. We do not adjust the currency exchange rate. There will be a charge for
                  any currency conversion and will be disclosed in Fee Schedule.
                </li>
              </ol>
            </li>
            <li>
              <strong> Payments</strong>
              <ol style="">
                <li>
                  <strong /><strong>Your Promise to Pay.</strong> You promise to pay us all amounts
                  due on your Shifl Capital Account by each Due Date set forth on your Statement. This
                  includes amounts where you did not sign a purchase slip or other documents for the
                  transaction. We will treat transactions made without presenting your physical card (such as
                  for mail, telephone, Internet, or mobile device purchases) the same as if you used the Shifl
                  Capital Card in person. If you let someone else use your Fintech Account, you are
                  responsible for all transactions that person makes.
                </li>
                <li>
                  <strong> Minimum Payment.</strong> You must pay us the payment amount listed on your
                  Statement by the Due Date. In addition to the payment, you may pay all or part of the total
                  balance on your Shifl Capital Card. But, you must still pay at least the minimum payment
                  amount each month, even if you paid more than the minimum payment due on the previous
                  Statement.
                </li>
                <li>
                  <strong> Payment Methods.</strong> Your payment must be made in U.S. dollars from a U.S.
                  deposit account. You must use one of the following payment methods:
                  <ol style="list-style-type: lower-alpha;" data-mce-style="list-style-type: lower-alpha;">
                    <li>
                      <strong> Preauthorized Automatic Payments (AutoPay</strong><em>). </em>When you sign
                      up, or by using the App, you may elect to make payments on your Card(s) using
                      AutoPay. If you select AutoPay, you authorize us to make recurring debits from your
                      Deposit Account or ACH transfers from a Linked External Account you select, on each
                      Due Date, in the amount of the payment due (or another amount you select), as set
                      forth on each Statement. You also authorize us to debit or credit your selected
                      payment method as needed to correct any errors, process returned and reversed
                      payments, and similar issues, to the extent permitted by law.
                    </li>
                    <li>
                      <strong> One-Time Transfer.</strong> You may use the App to authorize us to make a
                      one-time transfer to us from your Deposit Account or ACH transfers from a Linked
                      External Account on or before each Due Date. You also authorize us to debit or
                      credit your selected payment method to correct any errors, process returned and
                      reversed payments, and similar issues, to the extent permitted by law.
                    </li>
                  </ol>
                </li>
                <li>
                  We may permit additional payment methods from time to time. We may reject any payment not
                  made in accordance with this section.
                </li>
                <li>
                  <strong> Effect of Payment on Spending Limit.</strong> When you make a payment towards your
                  Shifl Capital Card Balance from a payment method other than a transfer from your Shifl
                  Capital Account, your Spending Limit on your Shifl Capital Account will increase in an
                  amount equivalent to your payment. We may delay the change in your Spending Limit until we
                  confirm that your payment has cleared. This may happen even if we credit your payment. If
                  you have elected to make payments from the Shifl Capital Account associated with your Card,
                  your Shifl Capital Account balance and your Spent Money will be reduced by the amount your
                  payment, and your Spending Limit on the Shifl Capital Card will not increase.
                </li>
                <li>
                  <strong> Payment Processing.</strong> We may accept and process payments without losing any
                  of our rights. If your payment is returned unpaid for any reason, you authorize us to
                  re-initiate the payment you authorized up to two additional times. We also reserve the right
                  to debit the returned payment amount back to the balance on your Shifl Capital Card.
                </li>
                <li>
                  <strong> How We Apply Your Payments.</strong> Payments are first applied to any past-due
                  amount (except late fees), then to your current minimum payment due. Payments in excess of
                  your minimum payment due will be applied to any outstanding interest and fees, then the
                  remainder your Shifl Capital Card Balance. If you do have any past-due Shifl Capital Card
                  Balance (other than late fees), any payment you make will first be applied to such past-due
                  amounts, and any remaining amount will be applied to the remaining Shifl Capital Card
                  Balance you selected for payment. We may adjust your Shifl Capital Card Balance, Shifl
                  Capital Account balance, or Spending Limit as reasonably necessary to correct errors,
                  process returned and reversed payments, and similar issues.
                </li>
                <li>
                  <strong> Credit Balances.</strong> We may reject and return any payment that creates or adds
                  to a credit balance on your Shifl Capital Card. Any credit balance we allow will not be
                  available until we confirm that your payment has cleared. We may reduce the amount of any
                  credit balance by any new charges. You may write to the address provided on your Statement
                  or email us at <strong>hello@shifl.com</strong> to request a refund of any available credit
                  balance greater than $1.00.
                </li>
              </ol>
            </li>
            <li>
              <strong> Your FinTech Card Billing Rights<br></strong><em>This section applies to billing errors
                with your Shifl Capital Card. If you believe there is an error related to a transaction to or from
                your Shifl Capital Account, please see the Electronic Fund Transfer Agreement.&nbsp; This notice
                tells you about your rights and our responsibilities under the Fair Credit Billing Act.<br></em>What
              To Do If You Find A Mistake On Your Statement. If you think there is an error on your Statement,
              write to us at:<br>343 spook rock rd suffern ny 10901<br>You may also contact us at <strong><a
                  href="mailto:hello@shifl.com">hello@shifl.com</a><br></strong>In your letter, give us the
              following information:
              <ul>
                <li>Account information: Your name and last 4 digits of your Shifl Capital Card Number.</li>
                <li>Dollar amount: The dollar amount of the suspected error.</li>
                <li>
                  Description of Problem: If you think there is an error on your Statement, describe what you
                  believe is wrong and why you believe it is a mistake.
                </li>
              </ul>
            </li>
          </ol>
        </li>
      </ol>
      <p>You must contact us within 60 days after the error appeared on your Statement.&nbsp;<br></p>
      <p>
        To stop a scheduled payment on an amount you think is wrong, you must contact us at least three Business Days
        before the scheduled payment date.
      </p>
      <p>
        You must notify us of any potential errors in writing or electronically. You may call us, but if you do we are
        not required to investigate any potential errors and you may have to pay the amount in
        question.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <p>What Will Happen After We Receive Your Notice</p>
      <p>When we receive your notice, we must do two things:</p>
      <ol>
        <li>
          Within 30 days of receiving your notice, we must tell you that we received your notice. We will also tell
          you if we have already corrected the error.
        </li>
        <li>
          Within 90 days of receiving your notice, we must either correct the error or explain to you why we believe
          the bill is correct.
        </li>
      </ol>
      <p><br></p>
      <p>While we investigate whether or not there has been an error, the following are true:</p>
      <ul>
        <li>We cannot try to collect the amount in question, or report you as delinquent on that amount.</li>
        <li>
          The charge in question may remain on your Statement, and we may continue to charge you interest on that
          amount. But, if we determine that we made a mistake, you will not have to pay the amount in question, or any
          interest or other fees related to that amount.
        </li>
        <li>
          While you do not have to pay the amount in question, you are responsible for the remainder of your Shifl
          Capital Card Balance.
        </li>
        <li>We can apply any unpaid amount against your Spending Limit.</li>
      </ul>
      <p><br></p>
      <p>After we finish our investigation, one of two things will happen:</p>
      <ul>
        <li>
          If we made a mistake: You will not have to pay the amount in question, or any interest or other fees related
          to that amount.
        </li>
        <li>
          If we do not believe there was a mistake: You will have to pay the amount in question, along with applicable
          interest and fees. We will send you a statement of the amount you owe, and the date payment is due. We may
          then report you as delinquent if you do not pay the amount, we think you owe.
        </li>
      </ul>
      <p><br></p>
      <p>
        If you receive our explanation but still believe your bill is wrong, you must write to us within 10 days telling
        us that you still refuse to pay. If you do so, we cannot report you as delinquent without also reporting that
        you are questioning your bill. We must tell you the name of anyone to whom we reported you as delinquent, and we
        must let those organizations know when the matter has been settled between us.
      </p>
      <p><br></p>
      <p>
        If we do not follow all of the rules above, you do not have to pay the first $50 of the amount you question even
        if your bill is correct.
      </p>
      <p><br></p>
      <p>
        <strong>Your Rights If You Are Dissatisfied With Your Card Purchases.</strong> If you are dissatisfied with the
        goods or services that you have purchased with your Shifl Capital Card, and you have tried in good faith to
        correct the problem with the merchant, you may have the right not to pay the remaining amount due on the
        purchase.
      </p>
      <p>To use this right, all of the following must be true:</p>
      <ul>
        <li>
          The purchase must have been made in your home state or within 100 miles of your current mailing address, and
          the purchase price must have been more than $50. (Note: Neither of these is necessary if your purchase was
          based on an advertisement we mailed to you, or if we own the company that sold you the goods or services.)
        </li>
        <li>
          You must have used your Shifl Capital Card for the purchase. Purchases made with cash advances from an ATM
          do not qualify.
        </li>
        <li>You must not yet have fully paid for the purchase.</li>
      </ul>
      <p><br></p>
      <p>
        If all of the criteria above are met and you are still dissatisfied with the purchase, contact us in writing or
        electronically at the address listed above.
      </p>
      <p><br></p>
      <p>
        While we investigate, the same rules apply to the disputed amount as discussed above. After we finish our
        investigation, we will tell you our decision. At that point, if we think you owe an amount and you do not pay we
        may report you as delinquent.
      </p>
      <p><br></p>
      <p>
        If we provide you a credit for all or part of a disputed transaction, you give us all of your rights against
        others regarding that transaction. You will also: (a) give us any information about the disputed transaction, if
        we ask; (b) not pursue any claim or reimbursement of the transaction amount from the merchant or any other
        person; and (c) help us get reimbursement from others. If we reimburse you for unauthorized transactions, you
        will help us investigate, pursue and get reimbursement from the wrongdoer. Your help includes giving us
        documents in a form that we request.
      </p>
      <p><br></p>
      <p>
        <strong>No Warranties.</strong> We are not responsible for any claim you may have regarding the purchase of
        goods
        or services made with your Shifl Capital Card beyond your rights described in this section and on your
        Statement.
      </p>
      <p><br><br data-mce-bogus="1"></p>
      <p><strong>&nbsp; &nbsp; </strong>F.<strong> Account Default</strong></p>
      <ol start="2">
        <li>
          <strong>Events of Default.</strong> Your Shifl Capital Card will be in default if:
          <ul>
            <li>You do not make a minimum payment when it is due;</li>
            <li>Any required payment you make is rejected, not paid or cannot be processed;</li>
            <li>You exceed your Credit Limit;</li>
            <li>You file or become the subject of a bankruptcy or insolvency proceeding;</li>
            <li>
              You are unable or unwilling to repay your obligations, including upon death or legally declared
              incapacity;
            </li>
            <li>
              We determine that you made a false, incomplete or misleading statement to us, or you otherwise tried
              to defraud us;
            </li>
            <li>
              You revoke your consent to receive records, disclosures, and other communications electronically;
            </li>
            <li>You do not comply with any term of this Agreement or any other agreement with us;</li>
            <li>You relocate outside the United States; or</li>
            <li>
              We receive a garnishment, attachment or other levy upon your Shifl Capital Account, or the Shifl
              Capital Account is subject to any other legal proceeding.<br data-mce-bogus="1">
            </li>
          </ul>
        </li>
        <li>
          <strong>Consequences of Default.</strong> If you are in default, we may take certain actions with respect to
          your Shifl Capital Account. For example, we may take the following actions, without notifying you, unless
          the law says that we must give you notice:
          <ul>
            <li>
              Close or suspend one or more of your Shifl Capital Card(s), or your entire Shifl Capital Account;
            </li>
            <li>Reduce your Credit Limit or Spending Limit;</li>
            <li>Demand that you immediately pay the Shifl Capital Card Balance;</li>
            <li>
              Continue to charge you interest and fees (as set forth in the Truth in Lending Disclosures) as long
              as your Shifl Capital Card Balance remains outstanding; and/or
            </li>
            <li>
              File a lawsuit against you, or pursue another action that is not prohibited by law. If we file a
              lawsuit, you agree to pay our court costs, expenses and attorney fees, unless the law does not allow
              us to collect these amounts.<br data-mce-bogus="1">
            </li>
          </ul>
        </li>
      </ol>
      <ul />
      <p><strong>&nbsp; &nbsp; </strong>G. <strong>Digital Wallet </strong></p>
      <ol>
        <li>
          <strong>Adding a Card</strong>. You can add your Shifl Capital Card to a digital wallet by following the
          instructions of the digital wallet. The digital wallet may not be accepted in all places where your Shifl
          Capital Card is accepted. We may terminate the ability for you to add the Shifl Capital Card to a digital
          wallet at any moment.
        </li>
        <li>
          <strong> Remove Your Card from the Digital Wallet</strong>. You should contact the digital wallet provider
          to remove a Shifl Capital Card from the digital wallet. We can end or suspend your ability to use a Shifl
          Capital Card with the Digital Wallet at any time.
        </li>
        <li>
          <strong> Card Responsibility</strong>. You are solely responsible for maintaining the security of your
          digital wallet credentials (including user identification, password or other access credentials). If you
          share these credentials with any other person, you expressly authorize them to access your personal
          information, access your accounts and initiate charges to your Shifl Capital Card(s) using the digital
          wallet service.
        </li>
        <li>
          <strong>Fees</strong>. We currently do not impose a fee for using your Shifl Capital Card at a Digital
          Wallet, but we reserve the right to impose a fee in the future. Please note that a Digital Wallet provider
          or another third party enabling or associated with your use of a Digital Wallet may charge a fee for using
          your Shifl Capital Card(s) in a Digital Wallet.
        </li>
        <li>
          <strong> No Liability for the Digital Wallet</strong>. We are not provider of the digital wallet and we are
          not responsible for providing the digital wallet service to you. We are only responsible for supplying
          information securely to the digital wallet provider to enable usage of the Shifl Capital Card in such
          digital wallet. We are not responsible for any failure of the digital wallet or the inability to use the
          digital wallet for any transaction. We are not responsible for the performance or non-performance of the
          digital wallet provider or any other third parties regarding any agreement you enter into with the digital
          wallet provider or associated third‐ party relationships that may impact your use of the digital wallet. YOU
          EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE DIGITAL WALLET SERVICE IS AT YOUR SOLE RISK, AND WE ARE NOT
          RESPONSIBLE FOR THE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, OR ACCURACY.
        </li>
        <li>
          <strong> Privacy &amp; Security</strong>. You agree that we may exchange your information with the Digital
          Wallet provider, a payment network (e.g., Visa and Mastercard), and others in order to enable your use of
          the Digital Wallet, make information available to you about your Shifl Capital Card transactions, and
          improve our ability to offer the Digital Wallet services. We do not control how the Digital Wallet provider
          or third parties use the information received in connection with the Digital Wallet and the use of such
          information is governed by such party’s privacy policy. We are not responsible for the security of the
          information provided to the Digital Wallet provider or stored in the Digital Wallet. We are not responsible
          if a security breach occurs that affects any information stored in the Digital Wallet or sent from a wallet.
        </li>
      </ol>
      <p><br></p>
      <p>
        &nbsp; &nbsp; H.<strong>Shifl Capital Card Cancellation, Suspension and Limits and Card Replacement<br></strong>
      </p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        We reserve the right, at any time and in our
        sole discretion, to limit your use of the Shifl Capital Card, including limiting or prohibiting specific types
        of transactions. The Bank may refuse to issue a Shifl Capital Card, revoke Shifl Capital Card privileges or
        cancel your Shifl Capital Card with or without cause or notice, other than as required by applicable law. If you
        would like to cancel the use of your Shifl Capital Card, you may do so by calling hello@shifl.com or the number
        on the back of your Shifl Capital Card. You agree not to use or allow others to use an expired, revoked,
        cancelled, suspended or otherwise invalid Shifl Capital Card. The cancellation of Shifl Capital Card privileges
        will not otherwise affect your rights and obligations under this Agreement. Not all services described in this
        Agreement are available to all persons or at all locations. We reserve the right to limit, at our sole
        discretion, the provision of any such services to any person or in any location. Any offer of a service in this
        Agreement shall be deemed void where prohibited. We can waive or delay enforcement of any of their rights under
        this Agreement without losing them.
      </p>
      <p style="padding-left: 40px;" data-mce-style="padding-left: 40px;">
        If you need to replace the Shifl Capital Card
        for any reason, send an email to <a href="mailto:hello@shifl.com"
          data-mce-href="mailto:hello@shifl.com">hello@shifl.com</a>
        to request a replacement. You will be required to provide personal information which may include the last four
        digits of your Shifl Capital Card number, your full name and knowledge of the Shifl Capital Card transaction
        history. If you believe the Shifl Capital Card has been lost or stolen, you may immediately disable it by
        emailing us at hello@shifl.com We may cancel any Shifl Capital Card that has been disabled for sixty (60)
        calendar days or longer.
      </p>
      <ol style="list-style-type: upper-roman;" data-mce-style="list-style-type: upper-roman;">
        <li>
          <strong> Disclosure of Information to Third Parties<br></strong>By requesting, obtaining or using a Shifl
          Capital Card from us you agree that we may release information in our records regarding you and your Shifl
          Capital Card: (a) to comply with government agency or court orders; (b) to share your credit performance
          with credit reporting agencies and other creditors who we reasonably believe are or may be doing business
          with you on your Shifl Capital Card; (c) to provide information on your Shifl Capital Card to any third
          party who we believe is conducting an inquiry in accordance with the Federal Fair Credit Reporting Act; (d)
          to share information with our employees, agents or representatives performing work for us in connection with
          your Shifl Capital Card; or (e) as otherwise permitted by the Bank’s privacy policy. We provide a copy of
          the Bank’s privacy notice at the time your Shifl Capital Card associated Shifl Capital Account is
          established and annually thereafter. Our privacy policy is also available on our website at&nbsp;<a
            href="https://shifl.capital/legal">https://shifl.capital/legal</a>
        </li>
      </ol>
      <p>
        <strong>IV. Arbitration<br></strong>This Section sets forth the circumstances and procedures under which Claims
        (as defined below) shall be arbitrated instead of litigated in court upon the election of either party.
      </p>
      <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
        <li>
          <strong> Definitions:</strong> As used in this Arbitration Section, the term “Claim” means any claim,
          dispute or controversy between you and Bank, Program Manager or any of their agents or retailers, arising
          from or relating to the Shifl Capital Card or this Agreement as well as any related or prior agreement that
          you may have had with us or the relationships resulting from this Agreement, including the validity,
          enforceability or scope of this Arbitration Section or the Agreement. “Claim” includes claims of every kind
          and nature, including but not limited to initial claims, counterclaims, cross-claims and third-party claims
          and claims based upon contract, tort, fraud and other intentional torts, statutes, regulations, common law
          and equity. The term “Claim” is to be given the broadest possible meaning that will be enforced and
          includes, by way of example and without limitation, any claim, dispute or controversy that arises from or
          relates to (i) your Shifl Capital Card, or the Shifl Capital Cards of any additional cardholders designated
          by you; (ii) the amount of available funds on the Shifl Capital Cards; (iii) advertisements, promotions or
          oral or written statements related to the Shifl Capital Cards, goods or services purchased with the Shifl
          Capital Cards; (iv) the benefits and services related to the Shifl Capital Cards; and/or (v) your enrollment
          for any Shifl Capital Card. As used in this Arbitration Section, the terms “we” and “us” shall for all
          purposes mean the Bank, Program Manager, their respective wholly or majority owned subsidiaries, affiliates,
          licensees, predecessors, successors, and assigns, and all of their agents, employees, directors and
          representatives. In addition, “we” or “us” shall include any third party using or providing any product,
          service or benefit in connection with any Shifl Capital Cards (including, but not limited to merchants who
          accept the Shifl Capital Card, third parties who use or provide services, debt collectors and all of their
          agents, employees, directors and representatives) if, and only if, such third party is named as a co-party
          with us (or files a Claim with or against us) in connection with a Claim asserted by you. As solely used in
          this Arbitration Section, the terms “you” or “yours” shall mean all persons or entities approved by us to
          have and/or use a Shifl Capital Card, including but not limited to all persons or entities contractually
          obligated under any of the Agreements and all additional cardholders.
        </li>
        <li>
          <strong>Initiation of Arbitration Proceeding/Selection of Administrator:</strong> Any Claim shall be
          resolved, upon the election by you or us, by arbitration pursuant to this Arbitration Provision and the code
          of procedures of the national arbitration organization to which the Claim is referred in effect at the time
          the Claim is filed. Claims shall be referred to either Judicial Arbitration and Mediation Services (“JAMS”)
          or the American Arbitration Association (“AAA”), as selected by the party electing to use arbitration. If a
          selection by us of one of these organizations is unacceptable to you, you shall have the right within thirty
          (30) days after you receive notice of our election to select the other organization listed to serve as
          arbitrator administrator. For a copy of the procedures, to file a Claim or for other information about these
          organizations, contact them as follows: (i) JAMS at 1920 Main Street, Suite 300, Los Angeles, CA 92614;
          website at www.jamsadr.com; and (ii) AAA at 335 Madison Avenue, New York, NY 10017; website at <a
            href="http://www.adr.org">www.adr.org</a>.
        </li>
        <li>
          <strong> Significance of Arbitration:</strong> IF ARBITRATION IS CHOSEN BY ANY PARTY WITH RESPECT TO A
          CLAIM, NEITHER YOU NOR WE WILL HAVE THE RIGHT TO LITIGATE THAT CLAIM IN COURT OR HAVE A JURY TRIAL ON THAT
          CLAIM, OR TO ENGAGE IN DISCOVERY EXCEPT AS PROVIDED FOR IN THE CODE OF PROCEDURES OF JAMS OR AAA, AS
          APPLICABLE (THE “CODE”). FURTHER, YOU WILL NOT HAVE THE RIGHT TO PARTICIPATE IN A REPRESENTATIVE CAPACITY OR
          AS A MEMBER OF ANY CLASS OF CLAIMANTS PERTAINING TO ANY CLAIM SUBJECT TO ARBITRATION. THE ARBITRATOR SHALL
          NOT CONDUCT A CLASS ARBITRATION OR A JOINT ARBITRATION, EXCEPT AS SET FORTH BELOW. THE ARBITRATOR’S DECISION
          WILL BE FINAL AND BINDING. NOTE THAT OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT ALSO MAY NOT BE
          AVAILABLE IN ARBITRATION.
        </li>
        <li>
          <strong> Restrictions on Arbitration:</strong> If either party elects to resolve a Claim by arbitration,
          that Claim shall be arbitrated on an individual basis. There shall be no right or authority for any Claims
          to be arbitrated on a class action basis or on bases involving Claims brought in a purported representative
          capacity on behalf of the general public, other cardholders or other persons similarly situated. The
          arbitrator’s authority to resolve Claims is limited to Claims between you and us alone, and the arbitrator’s
          authority to make awards is limited to you and us alone. Furthermore, Claims brought by you against us or by
          us against you may not be joined or consolidated in arbitration with Claims brought by or against someone
          other than you, unless otherwise agreed to in writing by all parties.
        </li>
        <li>
          <strong> Location of Arbitration/Payment of Fees:</strong> Any arbitration shall take place in Memphis,
          Tennessee, unless otherwise agreed in writing by the parties. At your written request, we will consider in
          good faith making a temporary advance of all or part of the filing administrative and/or hearing fees for
          any Claim you initiate as to which you or we seek arbitration. At the conclusion of the arbitration (or any
          appeal thereof), the arbitrator (or panel) will decide who will ultimately be responsible for paying the
          filing, administrative and/or hearing fees in connection with the arbitration (or appeal). If and to the
          extent you incur filing, administrative and/or hearing fees in arbitration, including for any appeal,
          exceeding the amount they would have been if the Claim had been brought in the state or federal court which
          is closest to your billing address and would have had jurisdiction over the Claim, we will reimburse you to
          that extent unless the arbitrator (or panel) determines that the fees were incurred without any substantial
          justification.
        </li>
        <li>
          <strong> Arbitration Procedures:</strong> This Arbitration Section is made pursuant to a transaction
          involving interstate commerce, and shall be governed by the Federal Arbitration Act, 9 U.S.C. Sections 1-16,
          as it may be amended (the “FAA”). The arbitration shall be governed by the applicable Code, except that (to
          the extent enforceable under the FAA) this Arbitration Section shall control if it is inconsistent with the
          applicable Code. The arbitrator shall apply applicable substantive law consistent with the FAA and
          applicable statutes of limitations and shall honor claims of privilege recognized at law and, at the timely
          request of either party, shall provide a brief written explanation of the basis for the decision. In
          conducting the arbitration proceeding, the arbitrator shall not apply the Federal or any state rules of
          civil procedure or rules of evidence. Either party may submit a request to the arbitrator to expand the
          scope of discovery allowable under the applicable Code. The party submitting such a request must provide a
          copy to the other party, who may submit objections to the arbitrator with a copy of the objections provided
          to the request party, within fifteen (15) days of receiving the requesting party’s notice. The granting or
          denial of such request will be in the sole discretion of the arbitrator who shall notify the parties of
          his/her decision within twenty (20) days of the objecting party’s submission. The arbitrator shall take
          reasonable steps to preserve the privacy of individuals, and of business matters. Judgment upon the award
          rendered by the arbitrator may be entered in any court having jurisdiction. The arbitrator’s decision will
          be final and binding, except for any right of appeal provided by the FAA. However, any party can appeal that
          award to a three-arbitrator panel administered by the same arbitration organization, which shall consider
          anew any aspect of the initial award objected to by the appealing party. The appealing party shall have
          thirty (30) days from the date of entry of the written arbitration award to notify the arbitration
          organization that it is exercising the right of appeal. The appeal shall be filed with the arbitration
          organization in the form of a dated writing. The arbitration organization will then notify the other party
          that the award has been appealed. The arbitration organization will appoint a three-arbitrator panel which
          will conduct arbitration pursuant to its Code and issue its decision within one hundred twenty (120) days of
          the date of the appellant’s written notice. The decision of the panel shall be by majority vote and shall be
          final and binding.
        </li>
        <li>
          <strong> Continuation:</strong> This Arbitration Section shall survive termination of your Shifl Capital
          Card as well as voluntary payment of the debt in full by you, any legal proceeding by us to collect a debt
          owed by you, and any bankruptcy by you or us. If any portion of this Arbitration Section is deemed invalid
          or unenforceable under any principle or provision of law or equity, consistent with the FAA, it shall not
          invalidate the remaining portions of this Arbitration Section, the Agreement or any prior agreement you may
          have had with us, each of which shall be enforceable regardless of such invalidity.
        </li>
      </ol>
      <p>V. <strong>Other Terms</strong></p>
      <ol style="list-style-type: upper-alpha;" data-mce-style="list-style-type: upper-alpha;">
        <li>
          <strong> The Card and your obligations under this Agreement may not be assigned.</strong> The Bank may
          transfer its rights under this Agreement. Use of the Shifl Capital Card is subject to all applicable rules
          and customs of any clearinghouse or other association involved in transactions. The Bank does not waive its
          rights by delaying or failing to exercise them at any time. If any provision of this Agreement shall be
          determined to be invalid or unenforceable under any rule, law, or regulation of any governmental agency,
          local, state, or federal, the validity or enforceability of any other provision of this Agreement shall not
          be affected. This Agreement will be governed by the law of the State of Tennessee except to the extent
          governed by federal law.
        </li>
        <li>
          By activating the Shifl Capital Card or by retaining, using or authorizing the use of it, you represent and
          warrant to us that: (i) you are at least 18 years of age (or older if you reside in a jurisdiction where the
          majority age is older); (ii) you are a U.S. citizen or legal alien residing in the fifty (50) states of the
          United States (“U.S.”) or the District of Columbia; (iii) you have provided us with a verifiable U.S. street
          address (not a P.O. Box); (iv) the personal information you provide to us in connection with the Shifl
          Capital Card is true, correct and complete; (v) you received a copy of this Agreement and agree to be bound
          by and to comply with its terms; and (vi) you accept the Shifl Capital Card.
        </li>
        <li>
          The terms and conditions of this Agreement may be revised by posting a revised version here
          <a href="https://shifl.capital/legal">https://shifl.capital/legal</a>. You will be notified of any change in
          the manner provided by applicable law
          prior to the effective date of the change. However, if the change is made for security purposes, the Bank or
          Program Manager can implement such change without prior notice. We may cancel or suspend the Shifl Capital
          Card or this Agreement at any time. You may cancel this Agreement by returning the Shifl Capital Card to the
          Bank. Your termination of this Agreement will not affect any of the Bank’s or Program Manager’s rights or
          your obligations arising under this Agreement prior to termination. In the event your Shifl Capital Card is
          cancelled, closed or terminated for any reason, any remaining available funds associated with the Shifl
          Capital Account will be returned to you, subject to any contrary provision in this Agreement and applicable
          law.
        </li>
      </ol>
      <!--  END OF VISA CREDIT CARD AGREEMENT CONSUMER  -->
      <!--  PRIVACY AND DATA SECURITY POLICY      -->

      <h1 style="text-align: left;" data-mce-style="text-align: left;">Privacy and Data Security Policy<br>Shifl
        capital inc.<br>Version: 1.0&nbsp;</h1>
      <p style="text-align: center;" data-mce-style="text-align: center;"><br data-mce-bogus="1"></p>
      <h3 style="text-align: left;" data-mce-style="text-align: left;">Policy Number: 0001<br>Category: Information
        Security<br>Effective: January 16, 2022<br>Revision History:</h3>
      <p><br data-mce-bogus="1"></p>
      <ol>
        <li data-mce-style="text-align: left;" style="text-align: left;"><strong>PURPOSE</strong><br>The purpose of this
          Policy is to establish the common and general principles and guidelines for conduct that are to govern
          Shifl capital Inc. as regards personal data protection, ensuring compliance with applicable law under all
          circumstances. In particular, this Policy guarantees the right to the protection of personal data for all
          natural persons who establish relations with the companies belonging to the Shifl capital Inc. ensuring
          respect for the rights to reputation and to privacy in the processing of the various categories of personal
          data from different sources and for various purposes based on their business activities, all in compliance
          with the Company's Policy on Respect for Human Rights.&nbsp;<br><br data-mce-bogus="1"></li>
        <li data-mce-style="text-align: left;" style="text-align: left;"><strong>SCOPE</strong>
          <ol style="list-style-type: upper-roman;" data-mce-style="list-style-type: upper-roman;">
            <li data-mce-style="text-align: left;" style="text-align: left;"><strong>All staff (employed and contracted)
                officers trainees members Company representatives</strong> and suppliers who handle and use our
              information (where we’re the 'Controller' for the personal data being processed), whether we hold it on
              our systems (manual and automated) or if others hold it on their systems for us.<br data-mce-bogus="1">
            </li>
            <li data-mce-style="text-align: left;" style="text-align: left;"><strong>All personal data
                processing</strong> we carry out for others (where we’re the Processor' for the personal data being
              processed)
            </li>
            <li data-mce-style="text-align: left;" style="text-align: left;"><strong>All formats,</strong> e.g. printed
              and digital information, text and images, documents and records, data and audio recordings. <br><br></li>
          </ol>
        </li>
        <li data-mce-style="text-align: left;" style="text-align: left;"><strong>Policy Statement</strong><br>This
          policy (“Policy”) sets out how Shifl capital Inc. and its subsidiaries (collectively, “SI”, “we”, “our” or
          “us”) collect, use, store and handle personal data.<br><br data-mce-bogus="1">
          <ol>
            <li data-mce-style="text-align: left;" style="text-align: left;"><strong>Commitment to Personal Data
                Protection<br></strong>We are committed to protecting the privacy of the personal data
              <strong>(“Personal
                Data”)</strong> we hold. To ensure that you can make informed decisions and feel confident about
              providing
              your Personal Data to us, we outline in this Policy our practices and the choices you have concerning
              the collection and use of your Personal Data.&nbsp;<br><strong><br></strong>
            </li>
            <li data-mce-style="text-align: left;" style="text-align: left;">
              <strong>The Personal Data We Collect</strong> The types of Personal Data we collect from you will depend
              on the specific type of services
              and products as requested by, or provided to, you. The types of Personal Data may include (without
              limitation) the following:&nbsp;<ol style="list-style-type: lower-roman;"
                data-mce-style="list-style-type: lower-roman;">
                <li data-mce-style="text-align: left;" style="text-align: left;">your contact information (e.g.
                  telephone numbers, mailing addresses, email addresses and fax numbers);
                </li>
                <li style="text-align: left;" data-mce-style="text-align: left;">your business information (e.g. company
                  name, business title and associated contact information);
                </li>
                <li style="text-align: left;" data-mce-style="text-align: left;">your credit or debit or other charge
                  cards information (e.g. name of cardholder, card number, billing address, security code and expiry
                  date);
                </li>
                <li style="text-align: left;" data-mce-style="text-align: left;">your travel details (e.g. flight
                  information, travel companions’ personal information and information related to traveler’s special
                  needs or preferences); </li>
                <li style="text-align: left;" data-mce-style="text-align: left;">your travel details (e.g. flight
                  information, travel companions’ personal information and information related to traveler’s special
                  needs or preferences);&nbsp;
                </li>
                <li style="text-align: left;" data-mce-style="text-align: left;">your interests, personal preferences or
                  comments; ➢ your comments and responses to market surveys, contests and promotional offers
                  conducted by us or on our behalf; and/or
                </li>
                <li style="text-align: left;" data-mce-style="text-align: left;">Information when you use our websites
                  or other services or products (e.g. behavioral information, purchasing history, location
                  information, browser details, IP addresses).&nbsp;
                </li>
              </ol>
              <p>Certain Personal Data (e.g. relating to your personal information and contact information) are
                required for specific services and if you fail to supply such Personal Data as requested from each
                specific service, we may not be able to deliver you the services or products. By providing your
                Personal Data to us, you acknowledge that such provision is fair and reasonable in the
                circumstances.&nbsp;
              </p>
            </li>
            <li data-mce-style="text-align: left;" style="text-align: left;"><strong>Purposes for which your Personal
                Data are Collected and Used <br></strong>The purposes for which your Personal Data are collected and
              used
              may include (without limitation) one or more of the following purposes:<br>
              <ol style="list-style-type: lower-roman;" data-mce-style="list-style-type: lower-roman;">
                <li style="text-align: left;" data-mce-style="text-align: left;">for the supply of any services,
                  products, facilities and/or other subjects (together, the “Services”) which we or any of our
                  subsidiaries, our third parties, joint ventures and associated companies (together, “Shifl capital
                  Inc.”) may offer to you, or you may require from us, from time to time;
                </li>
                <li style="text-align: left;" data-mce-style="text-align: left;">for payment purposes for any of the
                  Services of Shifl capital Inc., including verification of credit card details with third parties;
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">for use of the online services
                  available at any of the websites of Shifl capital Inc. and/or through other telecommunication
                  channels;
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">with your consent, for marketing or
                  promoting any of the Services of Shifl capital Inc. (e.g. sending you updates on our latest offers
                  and promotions in relation to any of our Services from time to time);
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">with your consent, for promoting and
                  soliciting donations or contributions for charitable or nonprofit making causes;
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">for leasing, development, sale or
                  purchase of any of the Services of Shifl capital Inc. and/or for management or other related
                  services;
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">for identification and verification
                  purposes in connection with any of the Services that may be supplied to you; </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">for reservations with or through us or
                  any member of Shifl capital Inc.; </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">for communicating with you by email,
                  mail, fax, phone or other means, including contacting you regarding your enquiries; </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">for conducting research and/or analysis
                  from time to time to better understand your shopping, dining, spending and/or other consumption needs,
                  preferences, interests, experiences and/or habits;&nbsp;<strong> </strong></li>
                <li data-mce-style="text-align: left;" style="text-align: left;">for designing new and/or enhancing
                  existing Services, activities, contests, lucky draws and/or other events (together, the
                  “Activities”) relating to Shifl capital Inc.’s portfolio of properties or tenants in such
                  portfolios of properties (“Merchants”), and/or administering any of the Activities conducted by
                  Shifl capital Inc. (or on its/their behalf) or by the Merchants; </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">for managing customer relationship and
                  relationship with Shifl capital Inc.’s Merchants; </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">for operating and administering any
                  membership clubs or loyalty or reward programs; </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">for making disclosures when required by
                  law, regulation, or court order of any jurisdiction and/or as requested by any government,
                  regulatory or law enforcement authority or administrative organization, which may be within or outside
                  The United States. </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">for establishing legal claims or
                  defenses, obtaining legal advice, and/or exercising, defending and/or protecting the rights or
                  properties of any member of Shifl capital Inc., including identifying, contacting or bringing legal
                  action against any person who may be causing interference with such rights or properties
                  (whether intentionally or otherwise) or where any other person could be harmed or property of any
                  other person could be damaged by such interfering activities; </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">for the use by any member of Shifl
                  capital Inc. in connection with any of the above purposes and/or any of the Services that any
                  member of Shifl capital Inc. may offer or supply to you from time to time; and/or </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">for any other incidental or associated
                  purposes relating to the above, or any other purposes as specified in a personal information
                  collection statement when your Personal Data is being collected, or any other purposes which
                  you may from time to time agree
                </li>
              </ol>
              <p>We will not knowingly or intentionally use, share or sell your Personal Data in ways which are
                unrelated to the above purposes (or purposes as specified in a personal information collection
                statement when we collect your Personal Data) without your prior consent.&nbsp;</p>
            </li>
            <li data-mce-style="text-align: left;" style="text-align: left;"><strong>Disclosure and Transfer of Personal
                Data<br></strong>To facilitate the purposes mentioned under “C. Purposes for which your Personal Data
              are
              Collected and Used”, we may transfer, disclose, grant access to or share your Personal Data with third
              parties located within or outside United States, and your Personal Data may be transferred within or
              outside United States. These third parties may include the following:<strong><br></strong>
              <ul>
                <li data-mce-style="text-align: left;" style="text-align: left;">any member of Shifl capital Inc.; any
                  agent, contractor or third-party service provider who provides administrative, marketing, research,
                  distribution, data processing, telemarketing, telecommunications, computer, payment or other
                  services to or support the operation of Shifl capital Inc.’s business (including its/their
                  direct marketing activities);
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">other business associates such as
                  loyalty program operators and other companies involved in providing customer service or fulfilling
                  customer requests;
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">credit reference agencies, credit,
                  debit and/or charge card companies and/or banks;
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">medical professionals, clinics,
                  hospitals, insurers and/or loss adjustors; any person, government or law enforcement authority or
                  administrative organization;
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">any person under a duty of
                  confidentiality to any member of Shifl capital Inc. (including accountants, legal advisers or other
                  advisers); </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">any actual or proposed assignee or
                  purchaser of all or any part of the business and/or asset of Shifl capital Inc.; and/or </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">Charitable or non-profit marking
                  organizations
                </li>
              </ul>
              <p>The Personal Data you provide to us may also be sourced from or transferred to other jurisdictions
                outside United States for the purposes mentioned above.</p>
            </li>
            <li data-mce-style="text-align: left;" style="text-align: left;"><strong>Direct Marketing</strong><br>If we
              intend to use your Personal Data (including your name and contact details) collected from you for
              direct marketing purposes (e.g. to send you marketing communications about news, offers or promotions
              in relation to the Services or Activities of Shifl capital Inc. or its Merchants), we will first obtain
              your consent (or an indication of no objection) before doing so. If we intend to provide your
              Personal Data (including your name and contact details) collected from you to third parties for their
              use in direct marketing, we will first obtain your consent (or an indication of no objection) before
              doing so. If you agree to receive marketing communications but do not wish to receive them in the
              future, you may opt out of receiving them at any time, free of charge, by the following applicable
              means:&nbsp;<br>
              <ol style="list-style-type: upper-roman;" data-mce-style="list-style-type: upper-roman;">
                <li data-mce-style="text-align: left;" style="text-align: left;">unsubscribing by following the “Reply
                  To” instructions contained in the marketing text message;
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">following the unsubscribe instructions
                  or hyperlink in the email; </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">following the unsubscribe instructions
                  in the mobile application; </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">notifying us that you no longer wish to
                  receive marketing communications when receiving our marketing calls; or&nbsp;
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">contacting us at the address stated in
                  paragraph K below to tell us that you no longer wish to receive marketing communication through any
                  channel.&nbsp;
                </li>
              </ol>
              <p>We may issue service-related announcements to you when necessary (e.g. when we suspend a service due
                to system maintenance). You may not be able to opt out of these announcements which are
                service-related and not promotional in nature.&nbsp;</p>
            </li>
            <li data-mce-style="text-align: left;" style="text-align: left;"><strong>Our Commitment to Children's
                Privacy</strong> Protecting the privacy of children is our primary concern. Hence, generally, we will
              not knowingly collect or maintain Personal Data from persons who are under 16 years of age without
              prior consent from a parent or guardian. We will not knowingly collect or maintain Personal Data from
              persons who are under 14 years of age without prior consent from a parent or guardian; and will only
              collect or maintain Personal Data from persons between 14 and 18 years of age if explicit consent has
              been obtained from such person or his/her parent or guardian.<br></li>
            <li data-mce-style="text-align: left;" style="text-align: left;"><strong>Use of Cookies and Log
                Files</strong>
              <ol>
                <li data-mce-style="text-align: left;" style="text-align: left;">If you visit any of our websites, we
                  may use cookie files to store and track information about you and your preferences. A cookie is a
                  small text file that our website transfers to your computer's or device’s hard disk. We use cookies to
                  better serve you and/or maintain your information across multiple pages within or across one or
                  more sessions. Most web browsers automatically accept cookies, but if you prefer, you can edit your
                  browser options to block them in future. However, if you disable cookies or refuse to accept a
                  request to place a cookie, certain functionalities on our websites may not be available.&nbsp;
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">Cookies contain information about you
                  and your preferences. For example, if you inquire about rates and availability, the site might
                  create a cookie that contains the details you entered. Or it might only contain a record of
                  which pages within the site you visited, to help the site customize the view for you the next
                  time you visit.
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">Only the information that you provide,
                  or the choices you make while visiting a website, can be stored in a cookie. For example, the site
                  cannot determine your email name unless you choose to type it. Allowing a website to create a
                  cookie does not give that or any other site access to the rest of your computer, and only the
                  site that created the cookie can read it.
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">We may also collect information
                  regarding your IP address, browser type, domain name and access time. This information is used for
                  our own research purposes. As it is not linked to any personal information, it is separate from
                  your Personal Data. In rare instances, IP addresses may be used to assist in deterring and/or
                  preventing abusive or criminal activity on the website.&nbsp;
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">If you use the Wi-Fi available at our
                  properties, we may collect your location data (if you have consented to this on your device). We
                  may use your location data to keep track of your activity patterns and preferences in order to
                  improve the level of service you receive. Your location data may be combined with other information
                  we receive from third parties to provide you with better service and, where you have consented to
                  receive direct marketing, we may provide you with targeted advertisements, content, features, deals
                  and offers in relation to the classes of marketing subject as set out in the relevant personal
                  information
                  collection statement when your Personal Data is being collected.&nbsp;
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">If you interact with us on social media
                  platforms (for example if you "Like" our Facebook Page or post on our timeline), we can interact
                  with you and send you messages via these platforms. We will interact with you in accordance with
                  the social media platform's rules but we are not responsible for how the platform operators
                  collect and handle your Personal Data. We are not responsible for what third parties post on our
                  social media accounts.&nbsp;
                </li>
              </ol>
            </li>
            <li data-mce-style="text-align: left;" style="text-align: left;"><strong>Data Retention</strong>
              <ol>
                <li data-mce-style="text-align: left;" style="text-align: left;">Subject to any legal, statutory,
                  regulatory or accounting requirements, the Personal Data you provide to us will be kept by us in the
                  appropriate form only for as long as is necessary to fulfil the purposes mentioned above after which
                  it will be destroyed.
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">Personal data (and sensitive personal
                  information) will be kept securely in accordance with our Information Security Policy.&nbsp;
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">The periods for which we hold personal
                  data are contained in our privacy notices.
                </li>
              </ol>
            </li>
            <li data-mce-style="text-align: left;" style="text-align: left;"><strong>Data Security</strong><br>We will
              use appropriate technical and organizational measures to keep personal data secure, and in particular
              to protect against unauthorized or unlawful processing and against accidental loss, destruction or
              damage.&nbsp;<br>
              <ol>
                <li data-mce-style="text-align: left;" style="text-align: left;">In order to ensure the correct use and
                  to maintain the accuracy of the Personal Data collected from you, as well as preventing
                  unauthorized or accidental access, processing, erasure or other use of the Personal Data, we
                  have implemented various internal policies (including physical, electronic and management
                  measures) to safeguard and secure the Personal Data we collect.&nbsp; For example, where we
                  collect Personal Data online, we use an industry standard for encryption over the Internet known as
                  Secure Socket Layer (SSL) protocol, to protect the Personal Data.&nbsp;<br data-mce-bogus="1"></li>
                <li data-mce-style="text-align: left;" style="text-align: left;">Our websites have firewalls in place,
                  which should protect the Personal Data collected from you against unauthorized or accidental
                  access. However,&nbsp; complete confidentiality and security is not yet possible over the internet,
                  and privacy cannot be assured in your communications to us. You are encouraged to protect
                  against unauthorized access to your password and credit card details. Make sure you sign out from
                  your account when finished particularly when using a shared computer.&nbsp; </li>
              </ol>
            </li>
            <li data-mce-style="text-align: left;" style="text-align: left;"><strong>Other Websites</strong><br>In order
              to anticipate your needs, our websites may contain links to third party sites that are operated under
              different privacy practices.<br><br>All such websites are independent from our websites. We have no
              control over the contents of such other websites or their privacy policies or compliance with the law.
              You should therefore be fully aware the provision of such links does not constitute an endorsement,
              approval, or any form of association by or with Shifl capital Inc.. We have no control over Personal
              Data that is submitted to these other websites. You should remain alert when you leave our websites,
              and read the privacy statements of other websites.&nbsp;<br></li>
            <li data-mce-style="text-align: left;" style="text-align: left;"><strong>Individual Rights and Contacting
                Us</strong><br>You are entitled to access or correct your Personal Data held by us. You may also:-<br>
              <ol style="list-style-type: upper-roman;" data-mce-style="list-style-type: upper-roman;">
                <li data-mce-style="text-align: left;" style="text-align: left;">request deletion or erasure of your
                  Personal Data;
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">object to the sharing of your Personal
                  Data; and/or
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">object to the profiling of your
                  Personal Data. <br><br> Additional rights may be available to you depending on the location of your
                  residence or of our operations. If you would like to exercise any of your rights or obtain a copy
                  of any of your Personal Data, or if you believe that any of your Personal Data which we&nbsp; collect
                  and maintain is inaccurate or would like to request for information regarding our policies and
                  practices and the kinds of Personal Data held by us, please contact us at the following
                  address:&nbsp;<br><strong><a href="https://shifl.capital">https://shifl.capital</a> - <a
                      href="mailto:hello@shifl.com">hello@shifl.com<br></a><br></strong>We
                  will endeavor to use appropriate technical means to ensure that you can access, update and correct
                  your Personal Data. In accessing, updating, correcting and/or deleting your Personal Data, we may
                  ask you to authenticate your identity in order to protect the safety of your Personal Data. To
                  the extent permitted by relevant laws and regulations, we reserve the right to refuse unreasonable
                  requests (for example, requests which infringe the privacy of others). To the extent permitted by
                  relevant laws and regulations, we reserve the right to charge a reasonable fee for the cost of
                  processing any request set out above.&nbsp; You have the following rights in relation to your
                  personal data.&nbsp;<br><br><strong>Subject access requests:</strong> You have the right to make a
                  subject access request. If you make a subject access request, we will tell you:<br><br>
                  <ol style="list-style-type: upper-roman;" data-mce-style="list-style-type: upper-roman;">
                    <li data-mce-style="text-align: left;" style="text-align: left;">whether or not your personal data
                      is processed and if so why, the categories of personal data concerned and the source of the
                      data if it is not collected from you;
                    </li>
                    <li data-mce-style="text-align: left;" style="text-align: left;">to whom your personal data is or
                      may be disclosed, including to recipients outside of the country and the safeguards that apply
                      to such transfers;&nbsp;
                    </li>
                    <li data-mce-style="text-align: left;" style="text-align: left;">for how long your personal data is
                      stored (or how that period is decided);&nbsp;
                    </li>
                    <li data-mce-style="text-align: left;" style="text-align: left;">your rights of rectification or
                      erasure of data, or to restrict or object to processing;&nbsp;
                    </li>
                    <li data-mce-style="text-align: left;" style="text-align: left;">your right to right to complain to
                      the Information Commissioner if you think we have failed to comply with your data protection
                      rights; and
                    </li>
                    <li data-mce-style="text-align: left;" style="text-align: left;">Whether or not we carry out
                      automated decision-making and the logic involved in any such decision making.&nbsp;
                      <ul>
                        <li data-mce-style="text-align: left;" style="text-align: left;">We will provide you with a copy
                          of the personal data undergoing processing. This will normally be in electronic form if you
                          have made a request electronically, unless you agree otherwise.
                        </li>
                        <li data-mce-style="text-align: left;" style="text-align: left;">To make a subject access
                          request, contact us at <br> https://shifl.com/#hello - hello@shifl.com </li>
                        <li data-mce-style="text-align: left;" style="text-align: left;">We may need to ask for proof of
                          identification before your request can be processed. We will let you know if we need to
                          verify your identity and the documents we require. </li>
                        <li data-mce-style="text-align: left;" style="text-align: left;">We will normally respond to
                          your request within 28 days from the date your request is received. In some cases, e.g.
                          where there is a large amount of personal data being processed, we may respond within 3
                          months of the date your request is received. We will write to you within 28 days of
                          receiving your original request if this is the case. </li>
                        <li data-mce-style="text-align: left;" style="text-align: left;">If your request is manifestly
                          unfounded or excessive, we are not obliged to comply with it.&nbsp;
                        </li>
                      </ul>
                      <p><strong>Other rights: </strong>You have a number of other rights in relation to your
                        personal data. You can require us to:&nbsp;<strong> </strong></p>
                    </li>
                    <li data-mce-style="text-align: left;" style="text-align: left;">rectify inaccurate data;</li>
                    <li data-mce-style="text-align: left;" style="text-align: left;">stop processing or erase data that
                      is no longer necessary for the purposes of processing;&nbsp;
                    </li>
                    <li data-mce-style="text-align: left;" style="text-align: left;">stop processing or erase data if
                      your interests override our legitimate grounds for processing the data (where we rely on our
                      legitimate interests as a reason for processing data);&nbsp;
                    </li>
                    <li data-mce-style="text-align: left;" style="text-align: left;">stop processing data for a period
                      if data is inaccurate or if there is a dispute about whether or not your interests override the
                      Shifl capital Inc. r’s legitimate grounds for processing the data.<br data-mce-bogus="1"></li>
                  </ol>
                </li>
              </ol>
            </li>
            <li data-mce-style="text-align: left;" style="text-align: left;"><strong>Authorized
                Agent&nbsp;</strong><br>You
              may choose a person registered with the State that you authorize to act on your behalf to submit your
              requests (“Authorized Agent”). If you choose to use an Authorized Agent, Shifl capital Inc. requires
              that you provide the Authorized Agent with written permission to allow them to submit your request and
              that you verify your identity directly with Shifl capital Inc. Failure to do so may result in Shifl
              capital Inc. denying your request.&nbsp;<br></li>
            <li data-mce-style="text-align: left;" style="text-align: left;"><strong>Accuracy and relevance</strong><br>
              We
              will:
              <ol style="list-style-type: upper-roman;" data-mce-style="list-style-type: upper-roman;">
                <li data-mce-style="text-align: left;" style="text-align: left;">Ensure that any personal data processed
                  is up to date, accurate, adequate, relevant and not excessive given the purpose for which it was
                  collected relevant and not excessive, given the purpose for which it was collected. </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">Not process personal data obtained for
                  one purpose for any other purpose, unless you agree to this or reasonably expect this. </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">If you consider that any information
                  held about you is inaccurate or out of date, then you should tell the CEO. If they agree that the
                  information is inaccurate or out of date, then they will correct it promptly. If they do not agree
                  with the correction, then they will note your comments.
                </li>
              </ol>
            </li>
            <li data-mce-style="text-align: left;" style="text-align: left;"><strong>Data breaches</strong>
              <ol style="list-style-type: upper-roman;" data-mce-style="list-style-type: upper-roman;">
                <li data-mce-style="text-align: left;" style="text-align: left;">If we discover that there has been a
                  breach of personal data that poses a risk to the rights and freedoms of individuals, we will report
                  it to the Information Commissioner within 72 hours of discovery.<br></li>
                <li data-mce-style="text-align: left;" style="text-align: left;">We will record all data breaches
                  regardless of their effect in accordance with our Breach response policy.<br></li>
                <li data-mce-style="text-align: left;" style="text-align: left;">If the breach is likely to result in a
                  high risk to your rights and freedoms, we will tell affected individuals that there has been a
                  breach and provide them with more information about its likely consequences and the mitigation
                  measures it has taken.&nbsp;
                </li>
              </ol>
            </li>
            <li data-mce-style="text-align: left;" style="text-align: left;">Data impact assessments
              <ol style="list-style-type: upper-roman;" data-mce-style="list-style-type: upper-roman;">
                <li data-mce-style="text-align: left;" style="text-align: left;">Some of the processing that the Shifl
                  capital Inc. carries out may result in risks to privacy.&nbsp;
                </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">Where processing would result in a high
                  risk to individual rights and freedoms, Shifl capital Inc. will carry out a data protection impact
                  assessment to determine the necessity and proportionality of processing. This will include considering
                  the purposes for which the activity is carried out, the risks for individuals and the measures that
                  can be put in place to mitigate those risks.
                </li>
              </ol>
            </li>
            <li data-mce-style="text-align: left;" style="text-align: left;">Training
              <ol style="list-style-type: upper-roman;" data-mce-style="list-style-type: upper-roman;">
                <li data-mce-style="text-align: left;" style="text-align: left;">We provide training to all our staffs
                  and sub-contractors about their data protection responsibilities as part of the induction process
                  and at regular intervals thereafter. </li>
                <li data-mce-style="text-align: left;" style="text-align: left;">Individuals whose roles require regular
                  access to personal data, or who are responsible for implementing this policy or responding to
                  subject access requests under this policy will receive additional training to help them understand
                  their duties and how to comply with them.&nbsp;
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li data-mce-style="text-align: left;" style="text-align: left;"><strong>Changes to Our Privacy
            Policy<br></strong>This Policy may be amended from time to time. You may access and obtain a copy of this
          Policy, as amended from time to time, at <a href="https://shifl.capital/legal">https://shifl.capital/legal</a>
          so that you are always informed of the
          way we collect and use Personal Data. This Policy is written in the English language and may be translated
          into other languages. In the event of any inconsistency between the English version and the translated
          version of this Policy, the English version shall prevail.&nbsp;<strong><br></strong></li>
      </ol>

      <!--  END OF PRIVACY AND DATA SECURITY POLICY      -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'LegalContent'
}
</script>

<style lang="scss" scoped>
.mce-item-table,
td,
th {
  border: 1px solid black;

  p {
    margin: .2rem;
  }
}

ul {
  list-style: disc;
}
</style>