<template>
  <LegalContent class="pa-md-4 pa-sm-2"/>
</template>

<script>
import LegalContent from "./components/LegalContent";
export default {
  name: 'legal',
  components: {LegalContent}
}
</script>